import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {store,persistor} from './folder/redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter } from 'react-router-dom';

// const theme = createTheme({
//   components: {
//     MuiTooltip: {
//       styleOverrides: {
//         tooltip: {
//           fontSize: "2em",
//           color: "yellow",
//           backgroundColor: "red"
//         }
//       }
//     },
//     MuiMenuItem: {
//       styleOverrides: {
//         root: {
//           minHeight: "0.2rem"
//         }
//       }
//     },
    
//   },
//   palette: {
//     action: {
//         selected: '#5c9abd',
//         hover: '#5c9abd',
//         disabled: '#9B9B9B'
//     }
// }
// });


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
    {/* <ThemeProvider theme={theme} > */}
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <App />
      </PersistGate>
    </Provider>
    {/* </ThemeProvider> */}
    </BrowserRouter>
  </React.StrictMode>

);


reportWebVitals();
