import React, { useState,useEffect } from 'react'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import {ButtonFilled} from '../../components/button'
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { GetAllGameService } from '../../services/GameServices';
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import moment from 'moment';

function GamesList() {
  
  const [datas,setdatas] = useState([])  
  const [selectedData,setselectedData] = useState({})  
  const [modal, setModal] = useState(false);
  const [page,setpage] = useState(1)  
  const [search,setsearch] = useState('') 
  const [step,setstep] = useState(1) 

  const navigate = useNavigate()

  useEffect(()=>{
    getData()
  },[])

  async function getData(){
    const response = await GetAllGameService()
    if(response?.status == 200){
      setdatas(response?.data?.data)
    }
  }

  // console.log("datas",datas)



  return (
    <div className='w-[calc(100%-200px)] p-5'>
         <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Games ({datas?.length})</h6>
            <div className='flex items-center'>
            
          
            <div className='ml-2 mr-0'>
            <ButtonFilled btnName="Add Data" onClick={()=>navigate('create')} /> 
            </div>
          
            </div>

        </div>

        <div className='grid grid-cols-5 gap-2 mt-2'>
        {datas?.map((d)=>(
            <div className='border relative p-1 px-2'>
                 <div className='absolute border-l border-b  right-0 top-0 bg-white '>
                    <div className='flex'>
                    <AiOutlineEdit size={17} onClick={()=>navigate('edit',{state:d})} className='p-[2px] cursor-pointer' />
                   </div>
                 </div>  
                <h1 className='text-[12px] text-slate-700 mt-2 z-10'>Game Name : <span className='font-[600] text-black'>{d?.name}</span></h1>

                <h1 className='text-[10px] bg-slate-100 p-1'>Game Active Days</h1> 
                <div className='flex my-1'>
                {['s','m','t','w','t','f','s']?.map((a,i)=>(
                    <h6 className={`min-w-[14.28%] text-[10px] ${d?.gamedays[i]?.isActive !== undefined ? d?.gamedays[i]?.isActive === true ? 'p-1 bg-slate-700 text-white' : 'p-1' : 'p-1'} uppercase border-r text-center`}>{a}</h6>
                ))}
                </div>

                <h6 className='text-[10px] bg-slate-100 p-1 mt-1'>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>

            </div>  
          ))}
        </div>
       
    </div>
  )
}

export default GamesList