import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const GetAllWithDrawService = async (type,page,search,from_date,to_date)=>{
    try {
      const result = await get(`api/withdraw/get?type=${type}&search_text=${search}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }


  export const GetDetailWithDrawService = async (id)=>{
    try {
      const result = await get(`api/withdraw/get/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const UpdateWithDrawService = async (id,data)=>{
    try {
      const result = await put(`api/withdraw/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const UpdateWithDrawPaymentStatusService = async (id)=>{
    try {
      const result = await put(`api/withdraw/mark_as_complete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }


  export const UpdateWithDrawRollBackService = async (id,data)=>{
    try {
      const result = await put(`api/withdraw/roll_back_withdraw/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const GetWithdrawTransactionByUserIdService = async (id,page)=>{
    try {
      const result = await get(`api/withdraw/get_withdraw_transaction_by_user_id/${id}?page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }


  export const FilterWithdrawTransactionByUserIdService = async (id,page,text,date,type)=>{
    try {
      const result = await get(`api/withdraw/filter_withdraw_transaction_by_user_id/${id}?search=${text}&date=${date}&type=${type}&page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const GetAllWithdrawTransactionService = async (page)=>{
    try {
      const result = await get(`api/withdraw/get_withdraw_all_transaction?page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }


  export const FilterAllWithdrawTransactionService = async (page,text,date,type)=>{
    try {
      const result = await get(`api/withdraw/filter_withdraw_all_transaction?search=${text}&date=${date}&type=${type}&page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }