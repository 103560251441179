import React,{useEffect, useState} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import GoBack from '../../components/back/GoBack';
import { useNavigate, useLocation } from 'react-router-dom'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import { CreatePromotionalTextService, UpdatePromotionalTextService } from '../../services/PromotionalTextServices';
import { GetDetailWithDrawService, UpdateWithDrawService } from '../../services/WithdrawServices';
import { Select } from 'antd';

function WithDrawCE() {
   
   const [data,setdata] = useState({userId:'',wallet:'',description:'',amount:'',deduction:'',payableAmount:'',isApprove:''});  
   const [error,seterror] = useState({userId:'',wallet:'',description:'',amount:'',deduction:'',payableAmount:'',isApprove:''}); 

   const {state,pathname} = useLocation();
  
   const path = pathname.split('/')[pathname.split('/').length - 1]
   const navigate = useNavigate();

    
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }


  useEffect(()=>{
        getData()
  },[state])

  async function getData(){
    const response = await GetDetailWithDrawService(state?._id)
    let d = response?.data?.data[0]
    setdata(d)
  }

  async function submitform(){
    if(!data?.payableAmount){
        seterror({...error,payableAmount:'This Field is required*'})
    }else if(!data?.amount){
        seterror({...error,amount:'This Field is required*'})
    }else if(!data?.deduction){
        seterror({...error,deduction:'This Field is required*'})
    }else if(data?.isApprove === 2 && !data?.description){
        seterror({...error,description:'This Field is required*'})
    }else{
       let sendData = {
            payableAmount:data?.payableAmount,
            amount:data?.amount,
            deduction:data?.deduction,
            isApprove:data?.isApprove,
            description:data?.description
       }
        const response  = await UpdateWithDrawService(state._id,sendData)
        if(response?.status === 200){
            setdata({userId:'',wallet:'',description:'',amount:'',deduction:'',payableAmount:'',isApprove:''})
            seterror({userId:'',wallet:'',description:'',amount:'',deduction:'',payableAmount:'',isApprove:''})
            toast.success("Wallet Updated")
            navigate(-1)
           }
       }

  }

  const stage = [
    {label:'Pending',value:0},
    {label:'Approved',value:1},
    {label:'Rejected',value:2},
  ]

  return (
    <div className='w-[300px] px-5 mt-5'>

        <GoBack />

            <h6 className='font-bold text-[14px]'>Add / Update WithDraw Request</h6>
        <h6 className='text-[10px] mt-2 bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to update the <b> WithDraw Request</b> for the employee.</h6>
     

        <TextInput 
            label={'Payable Amount'}  
            variant="standard"
            name="payableAmount"
            type="text"
            readOnly={true}
            error={error.payableAmount}
            value={data.payableAmount}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


<TextInput 
            label={'Amount'}  
            variant="standard"
            name="amount"
            readOnly={true}
            type="text"
            error={error.amount}
            value={data.amount}
            
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


<TextInput 
            label={'Deduction'}  
            variant="standard"
            name="deduction"
            type="text"
            readOnly={true}
            error={error.deduction}
            value={data.deduction}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Status</h6>
            <Select value={stage?.find((f)=>f?.value === data?.isApprove)} onChange={(e)=>setdata({...data,isApprove:e})} className='border h-[30px] w-full' bordered={false} options={stage} />



           { data?.isApprove === 2 &&
            <TextAreaInput1
            label={'Reject Summary'}  
            variant="standard"
            name="description"
            type="text"
            error={error.description}
            value={data.description}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}
            />}
<div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"}  onClick={submitform} />  
        </div>

    </div>
  )
}

export default WithDrawCE