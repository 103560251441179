"use client"
import React,{useState,useEffect} from 'react'
import { TextInput } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { useLocation, useNavigate } from 'react-router-dom'
import GoBack from '../../components/back/GoBack'
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import { CreateGameService, UpdateGameService } from '../../services/GameServices'
import toast from 'react-hot-toast'

function GamesCE() {

  const navigate = useNavigate()
  const {state,pathname} = useLocation();

  const [data,setdata] = useState({name:'',openBiddingTime:'',closeBiddingTime:'',disabledDate:'',isDeleted:'',results:''})
  const [error,seterror] = useState({name:'',openBiddingTime:'',closeBiddingTime:'',disabledDate:'',isDeleted:'',results:''})
  const [specialDays,setspecialDays] = useState(false)
  const path = pathname.split('/')[pathname.split('/').length - 1]

  const days = [
    {label:'Sunday',value:0},
    {label:'Monday',value:1},
    {label:'Tuesday',value:2},
    {label:'Wednesday',value:3},
    {label:'Thursday',value:4},
    {label:'Friday',value:5},
    {label:'Saturday',value:6}
  ]

  const [dayss,setdayss] = useState([
    {
      id:0,
      day:days[0],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:1,
      day:days[1],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:2,
      day:days[2],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:3,
      day:days[3],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:4,
      day:days[4],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:5,
      day:days[5],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:6,
      day:days[6],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    }
  ])


  useEffect(()=>{
    if(path === 'edit'){
      
      if(state?.specialDays === false){
        let setData  = {
          name:state?.name,
          openBiddingTime:state?.gamedays[0]?.openBiddingTime,
          closeBiddingTime:state?.gamedays[0]?.closeBiddingTime,
          specialDays:false
        }
        let daysData = []

        new Array(7).fill('_')?.forEach((d,i)=>{
          daysData.push(
            {
              id:i,
              day:days[i],
              openBiddingTime:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.openBiddingTime : '',
              closeBiddingTime:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.closeBiddingTime : '',
              isActive:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.isActive : false
            },
          )
        })

        setdata({...data,...setData})
        setdayss(daysData)
     }else{
      let setData  = {
        name:state?.name,
        openBiddingTime:state?.gamedays[0]?.openBiddingTime,
        closeBiddingTime:state?.gamedays[0]?.closeBiddingTime,
        specialDays:true
      }
      setdata({...data,...setData})

      let daysData = []

        new Array(7).fill('_')?.forEach((d,i)=>{
          daysData.push(
            {
              id:i,
              day:days[i],
              openBiddingTime:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.openBiddingTime : '',
              closeBiddingTime:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.closeBiddingTime : '',
              isActive:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.isActive : false
            },
          )
        })
        setspecialDays(true)
        setdayss(daysData)

     }
    }
  },[state])
  
  

  
  function handlechange(e){
    setdata({...data,[e.target.name]:e.target.value})
  }

  async function submitData(){
    if(!data.name){
      seterror({...error,name:'The Name field is required*'})
    }else if(!data.openBiddingTime){
      seterror({...error,openBiddingTime:'The Open Bidding Time field is required*'})
    }else if(!data.closeBiddingTime){
      seterror({...error,openBiddingTime:'The Close Bidding Time field is required*'})
    }else{
      if(path === 'create'){
       if(specialDays === false){
        let gamedays = []

        days?.forEach((d,i)=>{
          gamedays.push({
            day:i,
            openBiddingTime:data?.openBiddingTime,
            closeBiddingTime:data?.closeBiddingTime,
            isActive:true
          })
        })
        let sendData = {
          name:data?.name,
          gamedays:gamedays,
          specialDays:false
        }
        
        const response = await CreateGameService(sendData)
        if(response?.status === 200){
          toast.success("Game Created")
          setspecialDays(false)
          setdata({name:'',openBiddingTime:'',closeBiddingTime:'',disabledDate:'',isDeleted:'',results:''})
          seterror({name:'',openBiddingTime:'',closeBiddingTime:'',disabledDate:'',isDeleted:'',results:''})
        }


       }else{
        let gamedays = []
        days?.forEach((d,i)=>{
          gamedays.push({
            day:i,
            openBiddingTime:dayss?.find((f)=>f?.day?.value == i) !== undefined ?  dayss?.find((f)=>f?.day?.value == i)?.openBiddingTime : '',
              closeBiddingTime:dayss?.find((f)=>f?.day?.value == i) !== undefined ?  dayss?.find((f)=>f?.day?.value == i)?.closeBiddingTime : '',
              isActive:dayss?.find((f)=>f?.day?.value == i) !== undefined ?  dayss?.find((f)=>f?.day?.value == i)?.isActive : false
          })
        })

        let sendData = {
          name:data?.name,
          gamedays:gamedays,
          specialDays:true
        }

        const response = await CreateGameService(sendData)
        if(response?.status === 200){
          toast.success("Game Created")
          setspecialDays(false)
          setdata({name:'',openBiddingTime:'',closeBiddingTime:'',disabledDate:'',isDeleted:'',results:''})
          seterror({name:'',openBiddingTime:'',closeBiddingTime:'',disabledDate:'',isDeleted:'',results:''})
        }

       }
      }else{
        if(specialDays === false){
          let gamedays = []
  
          days?.forEach((d,i)=>{
            gamedays.push({
              day:i,
              openBiddingTime:data?.openBiddingTime,
              closeBiddingTime:data?.closeBiddingTime,
              isActive:true
            })
          })
          let sendData = {
            name:data?.name,
            gamedays:gamedays,
            specialDays:true
          }
          
          const response = await UpdateGameService(state?._id,sendData)
          if(response?.status === 200){
            toast.success("Game Updated")
            navigate(-1)
            setspecialDays(false)
            setdata({name:'',openBiddingTime:'',closeBiddingTime:'',disabledDate:'',isDeleted:'',results:''})
            seterror({name:'',openBiddingTime:'',closeBiddingTime:'',disabledDate:'',isDeleted:'',results:''})
          }
        }else{
          let gamedays = []
          days?.forEach((d,i)=>{
            gamedays.push({
              day:i,
              openBiddingTime:dayss?.find((f)=>f?.day?.value == i) !== undefined ?  dayss?.find((f)=>f?.day?.value == i)?.openBiddingTime : '',
              closeBiddingTime:dayss?.find((f)=>f?.day?.value == i) !== undefined ?  dayss?.find((f)=>f?.day?.value == i)?.closeBiddingTime : '',
              isActive:dayss?.find((f)=>f?.day?.value == i) !== undefined ?  dayss?.find((f)=>f?.day?.value == i)?.isActive : false
            })
          })
  
          let sendData = {
            name:data?.name,
            gamedays:gamedays,
            specialDays:true
          }
  
          const response = await UpdateGameService(state?._id,sendData)
          if(response?.status === 200){
            toast.success("Game Updated")
            setdata({name:'',openBiddingTime:'',closeBiddingTime:'',disabledDate:'',isDeleted:'',results:''})
            setspecialDays(false)
            navigate(-1)
            seterror({name:'',openBiddingTime:'',closeBiddingTime:'',disabledDate:'',isDeleted:'',results:''})
          }
  
         }
      } 
    }
  }

  // console.log("dayss : ",dayss)

  async function setdayssactivefun(i,type,v){
     if(type === 'active'){
        let datas = [...dayss]
        datas[i]['isActive'] =  !datas[i]['isActive'] 
        setdayss(datas)
     }else if(type === 'openBiddingTime'){
      let datas = [...dayss]
      datas[i]['openBiddingTime'] =  v 
      setdayss(datas)
     }else if(type === 'closeBiddingTime'){
      let datas = [...dayss]
      datas[i]['closeBiddingTime'] =  v 
      setdayss(datas)
     }

  }

  async function setspecialdaysfun(){
    if(specialDays === false){
      setspecialDays(!specialDays)
      let days_set = []
      days?.forEach((d,i)=>{
        days_set.push(
          {
            id:i,
            day:days[i],
            openBiddingTime:data.openBiddingTime,
            closeBiddingTime:data.closeBiddingTime,
            isActive:true
          },
        )
      })
      setdayss(days_set)
    }else{
      setspecialDays(!specialDays)
    }

  }
  
  return (
    <div className='px-5 max-h-screen h-screen no-scrollbar overflow-y-scroll mt-5 w-[300px]'>


      <GoBack />

      <h6 className='font-[700] text-[13px]'>Create / Update the Games</h6>
      <h6 className='font-[400] text-[12px]'>Use the below form to create or update the games for the users</h6>
       



      <TextInput 
        name="name"
        label="Name"
        value={data?.name}
        error={error?.name}
        handlechange={handlechange}
      />

      

      <TextInput 
        name="openBiddingTime"
        label="Open Bidding Time"
        type="time" 
        value={data?.openBiddingTime}
        error={error?.openBiddingTime}
        handlechange={handlechange}

      />

      <TextInput 
        name="closeBiddingTime"
        label="Close Bidding Time"
        type="time" 
        value={data?.closeBiddingTime}
        error={error?.closeBiddingTime}
        handlechange={handlechange}

      />

      
      <div onClick={()=>setspecialdaysfun()} className='flex items-center text-[11px] mt-2'>
        {specialDays ? <BiCheckboxSquare className='text-slate-700' size={20} />  : <BiCheckbox className='text-slate-300' size={20} /> }
        <h6 className='ml-1'>Does This Game have any special day</h6>
      </div>


      {specialDays && 
      <div>
      {dayss?.map((d,i)=>(
        <div className='mt-4'>
          <div className='flex items-center' onClick={()=>setdayssactivefun(i,'active')}>
         {d.isActive ? <BiCheckboxSquare className='text-slate-700' size={20} />  : <BiCheckbox className='text-slate-300' size={20} /> }
          <h6 className='text-[11px] ml-2 uppercase'>{d?.day?.label}</h6>
          </div>

          <div className='flex justify-between'>
          <div className='mr-2 w-[100%]'>  
          <TextInput 
            name="openBiddingTime"
            label="Open Bidding Time"
            type="time" 
            value={d?.openBiddingTime}
            handlechange={(e)=>setdayssactivefun(i,'openBiddingTime',e?.target?.value)}
          />
          </div>

          <div className='mr-2 w-[100%]'>  
          <TextInput 
            name="closeBiddingTime"
            label="Close Bidding Time"
            type="time" 
            value={d?.closeBiddingTime}
            handlechange={(e)=>setdayssactivefun(i,'closeBiddingTime',e?.target?.value)}

          />
          </div>
          </div>

        </div>   
      ))}
      </div>}


       
      <div>

      </div>

      {/* <h6 className='text-[11px] font-[600] mb-1 mt-2' >{'Select Day'}</h6>
      <div>
      <Select value={data?.openDate} bordered={false} className='w-full border border-slate-300' onChange={(e)=>setdata({...data,openDate:e})}  options={days} />
      </div> */}

      <div className='flex mt-10 border-t pt-2 mb-10'>
      <ButtonFilledAutoWidth  btnName="Save" onClick={submitData}/>
      <h6 className='w-2'></h6>
      <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>navigate(-1)}/>
      </div>
    </div>
  )
}

export default GamesCE