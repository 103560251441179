import React, { useEffect, useState } from 'react'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { GetAllUserService } from '../../services/UserServices';
import { IconButton } from '@mui/material';
import { AiOutlineEdit,AiOutlineDelete,AiOutlineWallet } from 'react-icons/ai';
import moment from 'moment';
import {DatePicker, Modal} from 'antd';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { GetAllWithDrawService, UpdateWithDrawPaymentStatusService, UpdateWithDrawRollBackService, UpdateWithDrawService } from '../../services/WithdrawServices';
import Priceconstants from '../../constants/imageConstants';
import toast from 'react-hot-toast';
import { TextAreaInput1 } from '../../components/input';

function WithDrawList() {
  
  const [datas,setdatas] = useState({pagination:{limit:0}})  
  const [selected_data,setselected_data] = useState({})
  const [page,setpage] = useState(1)  
  const [search,setsearch] = useState({search_text:'',from_date:'',to_date:'',from_date1:'',to_date1:''}) 
  const [step,setstep] = useState(0) 
  const [modal,setmodal] = useState(false) 

 
  useEffect(()=>{
    getData()
  },[page,step])

  async function getData(){
    const response = await GetAllWithDrawService(step,page,search?.search_text,search?.from_date1,search?.to_date1)
    if(response?.status == 200){
      setdatas(response?.data?.data)
    }
  }

  async function applyfilterfunction(){
    setpage(1)
    const response = await GetAllUserService(step,search?.search_text,search?.from_date1,search?.to_date1)
    if(response?.status == 200){
      setdatas(response?.data?.data?.data)
    }
  }

  async function resetfilterfunction(){
    setpage(1)
    setsearch({search_text:'',from_date:'',to_date:'',from_date1:'',to_date1:''})
    const response = await GetAllUserService(step === 1 ? 1 : 0,1,'','','')
    if(response?.status == 200){
      setdatas(response?.data?.data?.data)
    }
  }


  async function submitform(selected_data,v){
    let sendData = {
      payableAmount:selected_data?.payableAmount,
      amount:selected_data?.amount,
      deduction:selected_data?.deduction,
      isApprove:v,
      description:selected_data?.description
    }
  const response  = await UpdateWithDrawService(selected_data._id,sendData)
  if(response?.status === 200){
   setselected_data({})
   getData()
   setmodal(false)
   toast.success(v == '1'? "Withdraw Approved" : "Withdraw Rejected")
  }
  if(response?.status === 400){
    toast.error(response?.data?.message)
  }
  console.log("response?.status",response?.status)
  console.log("response?.data",response?.data)
}

//   if(response?.status === 200){
//       setdata({userId:'',wallet:'',description:'',amount:'',deduction:'',payableAmount:'',isApprove:''})
//       seterror({userId:'',wallet:'',description:'',amount:'',deduction:'',payableAmount:'',isApprove:''})
//       toast.success("Wallet Updated")
//       navigate(-1)
//      }
//  }
//   }


// async function submitform(v,data){
//   const response  = await UpdateWithDrawService(data._id,{isApprove:v})
//   if(response?.status === 200){
//       toast.success("Wallet Updated")
//      }
//  }
    
async function markascompletefunc(data){
  const response  = await UpdateWithDrawPaymentStatusService(data._id)
  if(response?.status === 200){
      getData()
      toast.success("Payment Status Updated")
     }
}

async function rollbackfunc(data){
  const response  = await UpdateWithDrawRollBackService(data._id)
  if(response?.status === 200){
      getData()
      toast.success("RollBack Completed")
     }
}


  return (
    <div className='w-[calc(100%-200px)] p-5'>

       <Modal width={300} footer={false} className='w-[300px]' open={modal} closable={false}>
           <h6 className='font-[700] mb-1'>Delete WithDraw Request</h6>
           <h6 className='leading-tight text-[12px] bg-slate-100 p-2'>Are You sure want to delete the withdraw request from the user</h6>

           <TextAreaInput1 value={selected_data?.description} name={"description"} onChange={(e)=>setselected_data({...selected_data,description:e.target.value})} />

           
           <div className='flex mt-2 justify-end border-t pt-2'>
           <ButtonOutlinedAutoWidth style={'mr-2'} btnName="Cancel" onClick={()=>setmodal(!modal)} />
           <ButtonFilledAutoWidth btnName="Save" onClick={()=>submitform(selected_data,2)} />
           </div>
       </Modal>

       <Modal width={300} footer={false} className='w-[300px]' open={modal} closable={false}>
           <h6 className='font-[700] mb-1'>Delete WithDraw Request</h6>
           <h6 className='leading-tight text-[12px] bg-slate-100 p-2'>Are You sure want to delete the withdraw request from the user</h6>

           <TextAreaInput1 value={selected_data?.description} name={"description"} onChange={(e)=>setselected_data({...selected_data,description:e.target.value})} />

           
           <div className='flex mt-2 justify-end border-t pt-2'>
           <ButtonOutlinedAutoWidth style={'mr-2'} btnName="Cancel" onClick={()=>setmodal(!modal)} />
           <ButtonFilledAutoWidth btnName="Save" onClick={()=>submitform(selected_data,2)} />
           </div>
       </Modal>

         <div className='flex items-center justify-between border-b pb-2'>
        <h6 className='font-[700] text-[14px] '>Total WithDraw ({datas?.pagination?.total})</h6>
        <div className='flex items-center'>
          <div className='flex items-center text-[12px] mr-2'>

            <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 : (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
            <IconButton onClick={resetfilterfunction}>
            <BsArrowRepeat size={16} />
            </IconButton>
            <div className='flex'>
               <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}>
                <FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>
                <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}>
                <FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>

            </div>

            <DatePicker size='small'  ampm={false} placeholder='From Date' className='text-[10px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

<DatePicker size='small'  ampm={false} placeholder='To Date' className='text-[10px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 


<div className='mr-2'>
  

<input  id="search_text" placeholder='Search text' type='text' value={search.search_text} onChange={(e)=>setsearch({...search,search_text:e.target.value})} className='border text-[11px] py-[5px] focus:ring-0 focus:outline-0   w-28 px-2 rounded-md border-slate-300' />

</div>      

<ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
          </div>


       
        <div className='mr-2'>
            

           
        </div>      

       
      
        </div>

        </div>
        <div className='flex border-b'>
          <h6 onClick={()=>{setstep(0);setpage(1)}} className={`${step == 0 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Pending</h6>
          <h6 onClick={()=>{setstep(1);setpage(1)}} className={`${step == 1 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Approved</h6>
          <h6 onClick={()=>{setstep(2);setpage(1)}} className={`${step == 2 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Rejected</h6>
        </div>


        <div className='max-h-[80vh] no-scrollbar mt-4 border-t   border-l border-r overflow-y-scroll'>
       <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
        <h6 className='top-0 bg-white z-50 text-[11px] min-w-[70px] max-w-[70px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
        <h6 className='sticky top-0 z-50  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Phone No</h6>
        {/* <h6 className='sticky top-0 z-50  text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Referral Id</h6> */}
        <h6 className='sticky top-0 z-50  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Deposit Wallet</h6>
        <h6 className='sticky top-0 z-50  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Winning Wallet</h6>
        <h6 className='sticky top-0 z-50  text-[11px] min-w-[130px] max-w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Amount Requested</h6>
        <h6 className='sticky top-0 z-50  text-[11px] min-w-[130px] max-w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Service Charge</h6>
        <h6 className='sticky top-0 z-50  text-[11px] min-w-[130px] max-w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Payable Amount</h6>
        <h6 className='sticky top-0 z-50  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created At</h6>
        <h6 className='sticky top-0 z-50  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Payment Status</h6>
        {/* <h6 className='sticky top-0 z-50  text-[11px] w-[100px] border-r px-2 py-1 font-[600] text-slate-600'>Status</h6> */}
        {(step == 0 || step == 1) && <h6 className='sticky top-0 z-50  text-[11px] min-w-[150px] max-w-[150px]  px-2 py-1 font-[600] text-slate-600'>Action</h6>}
       </div>

       {datas?.datas?.map((d,i)=>(
       <div className='z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
        <h6 className='bg-white z-50 font-[500] text-[11px] min-w-[70px] max-w-[70px]  px-2  border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* datas?.pagination?.limit)}</h6>
        <h6 className='z-50 font-[500]  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1  border-r'>+91 {d?.userId?.phoneNumber}</h6>
        {/* <h6 className='z-50 font-[500]  text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.userId?.referralId}</h6> */}
        <h6 className='z-50 font-[900]  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1  border-r'>{Priceconstants(d?.wallet?.amount)}</h6>
        <h6 className='z-50 font-[900]  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1  border-r'>{Priceconstants(d?.wallet?.earnedAmount)}</h6>
        <h6 className='z-50 font-[500]  text-[11px] min-w-[130px] max-w-[130px]  px-2 py-1  border-r'>{Priceconstants(d?.amount)}</h6>
        <h6 className='z-50 font-[00]  text-[11px] min-w-[130px] max-w-[130px]  px-2 py-1  border-r'>{Priceconstants(d?.deduction)}</h6>
        <h6 className='z-50 font-[900]  text-[11px] min-w-[130px] max-w-[130px]  px-2 py-1  border-r'>{Priceconstants(d?.payableAmount)}</h6>
        <h6 className='z-50 font-[400]  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1  border-r'>{moment(d?.createdAt)?.format('ll')}</h6>
        {/* <h6 className='z-50 font-[500]  text-[10px] w-[100px]  px-2 py-1  border-r'>{step == 0  && <span className='text-[7px]  z-0  bg-slate-600 font-[800] p-[4px] uppercase text-white'>Pending</span>} {step == 1 && <span className='text-[7px] z-0 bg-slate-600 font-[800] p-[4px] uppercase text-white'>Approved</span>} {step == 2 && <span className='text-[7px] z-0 bg-slate-600 font-[800] p-[4px] uppercase text-white'>Rejected</span>}</h6> */}
        <h6 className={`z-50 font-[400]  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600]  border-r ${d?.paymentMade === 'Pending' ? 'text-blue-400' : d?.paymentMade === 'Completed' ? 'text-green-600' : 'text-red-600'} `}>{d?.paymentMade}</h6>
        
        
        {(step == 0 || step == 1) && <h6 className='z-50 font-[500]  text-[11px] min-w-[150px] max-w-[150px]  px-2 py-1 '>
         <h6 className='z-50  text-[11px]  flex font-[600] border-r'>
          {step == 0 && <>
          <p onClick={()=>{submitform(d,1)}} className='text-[10px]  z-0 cursor-pointer mr-2  bg-slate-600 font-[500] p-[2px] pb-[4px] px-[10px] w-fit text-white h-fit rounded'>Approve</p>
          <p onClick={()=>{setselected_data({...d,isApprove:2});setmodal(true)}} className='text-[10px]  z-0 cursor-pointer h-fit mr-2  bg-slate-200 font-[500] p-[2px] px-[10px] w-max text-black border rounded'>Reject</p>
          </>}
          {step == 1 && <>
          {d?.paymentMade === 'Completed' ?
          <p onClick={()=>{rollbackfunc(d);}} className='text-[10px]  z-0 cursor-pointer h-fit mr-2  bg-slate-200 font-[500] p-[2px] px-[10px] w-max text-black border rounded'>Roll Back</p>
          :
          <p onClick={()=>{markascompletefunc(d)}} className='text-[10px]  z-0 cursor-pointer h-fit mr-2  bg-slate-200 font-[500] p-[2px] px-[10px] w-max text-black border rounded'>Mark as Complete</p>}
          </>}
          </h6>
                   {/* <AiOutlineEdit onClick={()=>navigate('pending',{state:d})}  size={20} className='text-slate-700 p-1' />   */}
                   {/* <AiOutlineDelete onClick={()=>{setselectedUser(d);setdeletemodal(true)}} size={20} className='text-slate-700 p-1 border-r' />  
                   <AiOutlineWallet onClick={()=>{setselectedUser(d);setmodal(true)}} size={20} className='text-slate-700 p-1 ' />   */}
        </h6>}
       </div>))}
       </div>

        {/* <div className='grid mt-2 grid-cols-5 gap-2'>
          {datas?.datas?.map((d)=>(
            <div className='border relative p-1 px-2'>
               {d?.isDeleted && <span className='text-[7px] absolute top-0 right-0 z-0 bg-red-600 font-[800] p-[4px] uppercase text-white'>Deleted</span>}
                <h1 className='text-[12px] text-slate-700 mt-4 z-10'>With Draw Amount : <span className='font-[600] text-black'>{d?.payableAmount}</span></h1>
                <h1 className='text-[12px] text-slate-700 z-10'>Deduction : <span className='font-[600] text-black'>{d?.deduction}</span></h1>
                <h1 className='text-[11px] mt-1 text-slate-700 border-t pt-1'>User Account  : </h1>
                <h1 className='text-[11px]'>Phone Number : <span className='font-[600] text-black p-1.5 uppercase text-[10px] rounded-[2px]'>{d?.userId?.phoneNumber}</span></h1>
                <h1 className='text-[11px] mt-1'>Refrall Id : <span className='font-[600] text-black bg-slate-100 p-1.5 uppercase text-[10px] rounded-[2px]'>{d?.userId?.referralId}</span></h1>
                
                <div className='absolute top-0 right-2'>
                  <div className='flex flex-wrap w-max border mt-2'>
                    <AiOutlineEdit size={20} onClick={()=>navigate('pending',{state:d})} className='text-slate-700 p-1 ' />  
                  </div> 
                </div> 

                <h6 className='text-[10px] bg-slate-100 p-1 mt-1'>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>

            </div>  
          ))}

        </div> */}
    </div>
  )
}

export default WithDrawList