
export default  function Priceconstants(v) {
    let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    });

    return v !== undefined  ? rupeeIndian.format(JSON.stringify(v))?.split('.')[0] : rupeeIndian.format(JSON.stringify(0))?.split('.')[0]
 
}
