import React,{useEffect, useState} from 'react'
import { TextInput } from '../../components/input'
import GoBack from '../../components/back/GoBack';
import { useNavigate, useLocation } from 'react-router-dom'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import { CreatePromotionalTextService, UpdatePromotionalTextService } from '../../services/PromotionalTextServices';
import { CreateRatioService, UpdateRatioService } from '../../services/RatioServices';

function RatioCE() {
   
   const [data,setdata] = useState({name:'',amount:'',sort:''});  
   const [error,seterror] = useState({name:'',amount:'',sort:''}); 

   const {state,pathname} = useLocation();
  
   const path = pathname.split('/')[pathname.split('/').length - 1]
   const navigate = useNavigate();

    
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }


  useEffect(()=>{
       if(path === 'edit'){
        setdata({...data,...state})
       }
  },[state])

  async function submitform(){
    if(!data?.name){
        seterror({...error,name:'This Field is required*'})
    }else if(!data?.amount){
      seterror({...error,amount:'This Field is required*'})
    }else if(!data?.sort){
      seterror({...error,sort:'This Field is required*'})
    }else{
       if(path === 'create'){
           const response  = await CreateRatioService(data)
           if(response?.status === 201){
            setdata({name:'',amount:'',sort:''})
            seterror({name:'',amount:'',sort:''})
            toast.success("Ratio Created")
           }
           if(response?.status === 400){
            toast.error("Check Name and sort unique")
           }
       }else{
        const response  = await UpdateRatioService(state._id,data)
           if(response?.status === 200){
            setdata({name:'',amount:'',sort:''})
            seterror({name:'',amount:'',sort:''})
            toast.success("Ratio Updated")
            navigate(-1)
           }
           if(response?.status === 400){
            toast.error("Check Name and sort unique")
           }
       }
    }

  }

  return (
    <div className='w-[300px] px-5 mt-5'>

        <GoBack />

            <h6 className='font-bold text-[14px]'>Add / Update Ratio</h6>
        <h6 className='text-[10px] mt-2 bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Ratio</b> for your game employees to access this feature.</h6>
     

        <TextInput 
            label={'Name'} 
            mandatory={true} 
            variant="standard"
            name="name"
            type="text"
            error={error.name}
            value={data.name}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<TextInput 
            label={'Amount'}  
            mandatory={true} 
            variant="standard"
            name="amount"
            type="number"
            error={error.amount}
            value={data.amount}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<TextInput 
            label={'Sort'}  
            mandatory={true} 
            variant="standard"
            name="sort"
            type="number"
            error={error.sort}
            value={data.sort}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>




<div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"}  onClick={submitform} />  
        </div>

    </div>
  )
}

export default RatioCE