import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Select  } from 'antd'
import GoBack from '../../components/back/GoBack'
import { TextAreaInput1, TextInput } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { UpdateWalletTransactionByIdService } from '../../services/WalletServices'
import toast from 'react-hot-toast'

function UserWalletTransactionCE() {
 
  const {state} = useLocation()

  console.log("state",state)

  const [data,setdata] = useState({})
  const [error,seterror] = useState({})

  const navigate = useNavigate()


  useEffect(()=>{
    setdata({amount:state?.amount,typeOfTransaction:state?.typeOfTransaction,description:state?.description,walletamount:state?.walletamount,walletearnedamount:state?.walletearnedamount,status:state?.status})
  },[])


  const status = [
    {
      label:'Completed',
      value:'Completed'
    },
    {
      label:'Rejected',
      value:'Rejected'
    },
    {
      label:'Pending',
      value:'Pending'
    },
  ]

  const stages = [
    {
      label:'Winning',
      value:'Winning'
    },
    {
      label:'Deposit',
      value:'Deposit'
    },
    {
      label:'Withdrawl',
      value:'Withdrawl'
    },
    {
      label:'Refer',
      value:'Refer'
    },
    {
      label:'Deposit',
      value:'Deposit'
    },
    {
      label:'Bonus',
      value:'Bonus'
    },
  ]

  async function handlechange(e){
    setdata({...data,[e.target.name]:e.target.value})
  }

  async function submiform(){
    if(!data.amount){
      seterror({...error,amount:'This Field is required*'})
    }else{
      const response = await UpdateWalletTransactionByIdService(state?._id,data)
      if(response.status === 200){
        toast.success("Updated Successfully")
        setTimeout(() => {
          navigate(-1)
        }, 2500);
      }
    }
  
  }

  return (
    <div className='ml-4 mt-10 w-60'>
        <GoBack />
        <h6 className='text-[12px] font-[700] mt-2 mb-1'>Update Wallet Transaction</h6>
        <h6 className='text-[10px] bg-slate-100 p-1'>Use the below form to update the wallet transaction of the user selected transaction record. </h6>

        <h6 className='text-[11px] mt-2 font-[700]'>Select Stage *</h6>
        <Select 
         bordered={false}
         value={stages?.find((f)=>f?.label === data?.typeOfTransaction)}
         options={stages}
         className='border w-[100%] mt-1'
         onChange={(e)=>setdata({...data,typeOfTransaction:e})}
         />

         <TextInput 
          label={'Amount'}
          name="amount"
          type={'number'}
          value={data?.amount}
          handlechange={handlechange}
         />

        <h6 className='text-[11px] mt-2 font-[700]'>Select Status *</h6>
        <Select 
         bordered={false}
         value={status?.find((f)=>f?.label === data?.status)}
         options={status}
         className='border w-[100%] mt-1'
         onChange={(e)=>setdata({...data,status:e})}
         />

        <TextInput 
          label={'Wallet Amount'}
          name="walletamount"
          readOnly={true}
          value={data?.walletamount}
          handlechange={handlechange}
         />

        <TextInput 
          label={'Wallet Earned Amount'}
          name="walletearnedamount"
          readOnly={true}
          value={data?.walletearnedamount}
          handlechange={handlechange}
         />

         <TextAreaInput1 
          label={'Description'}
          name="description"
          value={data?.description}
          handlechange={handlechange}
         />

         <div className='border-t flex justify-end mt-2 pt-2 '>
          <ButtonOutlinedAutoWidth onClick={()=>navigate(-1)} btnName='Cancel' style={'mr-2'} />
          <ButtonFilledAutoWidth onClick={submiform} btnName='Save' />

         </div>
    </div>
  )
}

export default UserWalletTransactionCE