import { DatePicker,Select } from 'antd'
import React, { useState } from 'react'
import { ButtonFilledAutoWidth } from '../../components/button'
import { GetGameRunForDateService } from '../../services/GameDefaultServices'
import { GetGameBiddingAmountBasedonIdService } from '../../services/GameServices'
import { AiOutlineFilter, AiOutlineReload } from 'react-icons/ai'
import { combination, jodiValuesList } from '../../constants/CombinationConstants'
import Priceconstants from '../../constants/imageConstants'

function GamePlayResult() {
  
  const [games,setgames] = useState([])  
  const [search,setsearch] = useState({date:'',date1:'',game:''})
  const [selectedGame,setslectedGame] = useState({}) 
  const [result,setresult] = useState({})
  const [step,setstep] = useState(1)

  async function getGames(e1){
    const response = await GetGameRunForDateService(e1)
    let arr = []
    let d = response?.data?.data
    d?.forEach((d1)=>{
      arr.push({label:d1?.game?.name,value:d1?._id})
    })
    setgames(arr)
  }

 
  console.log("selectedGame : ",selectedGame)

  async function getresult(id){
    const response = await GetGameBiddingAmountBasedonIdService(id)
    setresult(response?.data?.data)
  }
  return (
    <div className='px-4 pt-6 w-[calc(100%-200px)]'>
        <div className='border-b  flex  items-center  justify-between pb-2 '>
            <h6 className='text-[14px] font-[700]'>GamePlay Result</h6>
            <div className='flex items-center'>
            <DatePicker 
               
              onChange={(e,e1)=>{setsearch({...search,date:e,date1:e1});getGames(e1)}}
              placeholder=''
            />
            <Select 
             className='mx-2 w-[120px]'
             options={games}
             onChange={(e)=>{setsearch({...search,game:games?.find(f=>f?.value === e)});setslectedGame(games?.find(f=>f?.value === e));getresult(e)}}
             />
             <AiOutlineReload onClick={()=>{setresult({});setsearch({date:'',date1:'',game:''});setgames([])}}  size={26} className='bg-slate-100 rounded-[2px] cursor-pointer mr-2 text-slate-800 p-1' />
             <AiOutlineFilter onClick={()=>getresult(selectedGame?.value)} size={26} className='bg-slate-700 rounded-[2px] cursor-pointer text-white p-1' />
             </div>
        </div>
        
        {(selectedGame?.value !== undefined  && selectedGame?.value !== '')  &&
        <div className='mt-5'>
        <div className='flex border-b border-t mt-5'>
                <h6 onClick={()=>{setstep(1)}} className={`${step == 1 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Open</h6>
                <h6 onClick={()=>{setstep(2)}} className={`${step == 2 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Close</h6>
                <h6 onClick={()=>{setstep(3)}} className={`${step == 3 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Jodi</h6>
              </div>

            {step === 3 &&
            <>
            <div className='flex'>

            <div className='w-[18%] mr-2'>
            <h6 className='font-[700] text-[10px] font-sans mt-5'>Jodi Bidding On Number</h6>
            
            <div className='mt-2 '>
              <div className='flex bg-slate-100 mt-1 border w-[100%] items-center'>
                <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>Number</h6>
                <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>Amount</h6>
              </div>
              {result?.bidding_type_jodi?.map((f)=>(
              <div className='flex border-l border-r border-b w-[100%] items-center'>
                <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>{f?._id}</h6>
                <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>{Priceconstants(f?.amount)}</h6>
              </div>))}
            </div>
            </div> 

            <div className='w-[18%] mr-2'>
            <h6 className='font-[700] text-[10px] font-sans mt-5'>Half Sangam Bidding On Number</h6>

            <div className='mt-2'>
              <div className='flex bg-slate-100 mt-1 border w-[100% items-center'>
                <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>Number</h6>
                <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>Amount</h6>
              </div>
              {result?.bidding_type_jodi?.map((f)=>(
              <div className='flex border-l border-r border-b w-[100% items-center'>
                <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>{f?._id}</h6>
                <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>{Priceconstants(f?.amount)}</h6>
              </div>))}
            </div>
            </div>
            
            <div className='w-[18%] mr-2'>
            <h6 className='font-[700] text-[10px] font-sans mt-5'>Full Sangam Bidding On Number</h6>

              <div className='mt-2'>
              <div className='flex bg-slate-100 mt-1 border w-[100%] items-center'>
                <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>Number</h6>
                <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>Amount</h6>
              </div>
              {result?.bidding_type_jodi?.map((f)=>(
              <div className='flex border-l border-r border-b w-[100%] items-center'>
                <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>{f?._id}</h6>
                <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>{Priceconstants(f?.amount)}</h6>
              </div>))}
              </div>
            </div>
            </div>
            <h6 className='font-[700] text-[12px] font-sans mt-5'>Jodi Table</h6>

            <div className='grid w-[70%] border mt-2 grid-cols-10'>
            {jodiValuesList?.map((v)=>(
              <div className={`border ${result?.bidding_type_jodi?.find((f)=>f?._id.length == 1 ? `0${f?._id}` == v?.label : v?.label) !== undefined && 'bg-green-300' }`}>
                <h6 className='text-center p-1 text-[11px] font-[500] border-b'>{v?.label}</h6>
                <h6 className='text-[11px] py-1 text-center'>{result?.bidding_type_jodi?.find((f)=>f?._id.length == 1 ? `0${f?._id}` == v?.label : v?.label) !== undefined ? Priceconstants(result?.bidding_type_jodi?.find((f)=>f?._id.length == 1 ? `0${f?._id}` == v?.label : v?.label)?.amount) : '-' }</h6>
              </div>  
            ))}
            </div>
            </>}

            {step === 1 &&
            <>

            <div className='w-[18%] mr-2'>
            <h6 className='font-[700] text-[10px] font-sans mt-5'>Open Bidding On Number</h6>

            <div className='mt-2'>
              <div className='flex bg-slate-100 mt-1 border w-[100% items-center'>
                <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>Number</h6>
                <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>Amount</h6>
              </div>
              {result?.bidding_type_open?.map((f)=>(
              <div className='flex border-l border-r border-b w-[100% items-center'>
                <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>{f?._id}</h6>
                <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>{Priceconstants(f?.amount)}</h6>
              </div>))}
            </div>
            </div>

            <h6 className='font-[700] text-[12px] font-sans mt-5'>Open</h6>
            <div className='w-[100%] flex flex-row flex-wrap  mt-2'>
            {combination?.map((v)=>(
              <>
            {v?.value?.map((v)=>( <div className={`w-[4.3%] border ${result?.bidding_type_open?.find((f)=>f?._id.length == 1 ? `${f?._id}` == v : v) !== undefined && 'bg-green-300' }`}>
                <h6 className='text-center p-1 text-[11px] font-[500] border-b'>{v}</h6>
                <h6 className='text-[11px] py-1 text-center'>{result?.bidding_type_open?.find((f)=>f?._id.length == 1 ? `${f?._id}` == v : v) !== undefined ? Priceconstants(result?.bidding_type_open?.find((f)=>f?._id.length == 1 ? `00${f?._id}` == v : v)?.amount) : '-' }</h6>

              </div>))}
              </>
            ))}
            </div> 
            </>}

            {step === 2 &&
            <>
            <div className='w-[18%]'>
            <h6 className='font-[700] text-[10px] font-sans mt-5'>Close Bidding On Number</h6>

            <div className='mt-2'>
            <div className='flex bg-slate-100 mt-1 border w-[100% items-center'>
              <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>Number</h6>
              <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>Amount</h6>
            </div>
            {result?.bidding_type_close?.map((f)=>(
            <div className='flex border-l border-r border-b w-[100% items-center'>
              <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>{f?._id}</h6>
              <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>{Priceconstants(f?.amount)}</h6>
            </div>))}
            </div>
            </div>


            <h6 className='font-[700] text-[12px] font-sans mt-10'>Close</h6>
            <div className='w-[100%] flex flex-row flex-wrap  mt-2'>
            {combination?.map((v)=>(
              <>
            {v?.value?.map((v)=>( <div className={`w-[4.3%] border ${result?.bidding_type_close?.find((f)=>f?._id.length == 1 ? `${f?._id}` == v : v) !== undefined && 'bg-green-300' }`}>
                <h6 className='text-center p-1 text-[11px] font-[500] border-b border-slate-100'>{v}</h6>
                <h6 className='text-[9px] py-1 text-center'>{result?.bidding_type_close?.find((f)=>f?._id.length == 1 ? `${f?._id}` == v : v) !== undefined ? Priceconstants(result?.bidding_type_close?.find((f)=>f?._id.length == 1 ? `${f?._id}` == v : v)?.amount) : '-' }</h6>

              </div>))}
              </>
            ))}
            </div> 
            </>}
        </div>}
    </div>
  )
}

export default GamePlayResult