import axios from 'axios';
import {store} from '../redux/store'



// console.log("store.getState()",store.getState().Auth.accessToken)

const token = store.getState()?.Auth?.accessToken;

// console.log("token 11 ",store.getState())

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
    'Content-Type': 'application/json',
    'x-access-token': token
   },
});

const instance1 = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-access-token': token
      },
}); 

const publicInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json',
        },
});

instance.interceptors.request.use(function (config) {
    const token = store.getState()?.Auth?.accessToken;
    config.headers['Content-Type'] = 'application/json';
    config.headers['x-access-token'] = token;
    
    return config;
});

instance1.interceptors.request.use(function (config) {
    const token = store.getState()?.Auth?.accessToken;
    config.headers['Content-Type'] = 'multipart/form-data';
    config.headers['x-access-token'] = token;
    
    return config;
});

export const get = async (uri) => {
    const response = await instance.get(uri);
    return response;
}

export const post = async (uri, data) => {
    const response = await instance.post(uri, data);
    return response;
}

export const postfd = async (uri, data) => {
    const response = await instance1.post(uri, data);
    return response;
}

export const put = async (uri, data) => {
    const response = await instance.put(uri, data);
    return response;
}

export const deleteRequest = async (uri) => {
    const response = await instance.delete(uri);
    return response;
}

export const publicPost = (uri, data) => {
    return publicInstance.post(uri, data);
}


