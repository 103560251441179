"use client"
import React,{useState,useEffect} from 'react'
import { TextInput } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { useLocation, useNavigate } from 'react-router-dom'
import GoBack from '../../components/back/GoBack'
import {CreateGameTodayService, GetAllGameService, UpdateGameService, UpdateGameTodayTimingService } from '../../services/GameServices'
import toast from 'react-hot-toast'
import { Select } from 'antd'

function GamesPlayListCE() {

  const navigate = useNavigate()
  const {state,pathname} = useLocation();

  const [data,setdata] = useState({game:'',gameDay:'',date:'',day:'',openBiddingTime:'',closeBiddingTime:'',result:'',game_completed:false})
  const [error,seterror] = useState({game:'',gameDay:'',date:'',day:'',openBiddingTime:'',closeBiddingTime:'',result:'',game_completed:''})
  const [specialDays,setspecialDays] = useState(false)
  const [games,setgames] = useState([])
  const path = pathname.split('/')[pathname.split('/').length - 1]

  // console.log("path",path)

  const days = [
    {label:'Sunday',value:0},
    {label:'Monday',value:1},
    {label:'Tuesday',value:2},
    {label:'Wednesday',value:3},
    {label:'Thursday',value:4},
    {label:'Friday',value:5},
    {label:'Saturday',value:6}
  ]

  const [dayss,setdayss] = useState([
    {
      id:0,
      day:days[0],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:1,
      day:days[1],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:2,
      day:days[2],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:3,
      day:days[3],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:4,
      day:days[4],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:5,
      day:days[5],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    },
    {
      id:6,
      day:days[6],
      openBiddingTime:data.openBiddingTime,
      closeBiddingTime:data.closeBiddingTime,
      isActive:true
    }
  ])


  useEffect(()=>{
    if(path === 'edit'){
      setdata({
        ...data,
        openBiddingTime:state?.openBiddingTime,
        closeBiddingTime:state?.closeBiddingTime,
      })
      
    //   if(state?.specialDays === false){
    //     let setData  = {
    //       name:state?.name,
    //       openBiddingTime:state?.gamedays[0]?.openBiddingTime,
    //       closeBiddingTime:state?.gamedays[0]?.closeBiddingTime,
    //       specialDays:false
    //     }
    //     let daysData = []

    //     new Array(7).fill('_')?.forEach((d,i)=>{
    //       daysData.push(
    //         {
    //           id:i,
    //           day:days[i],
    //           openBiddingTime:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.openBiddingTime : '',
    //           closeBiddingTime:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.closeBiddingTime : '',
    //           isActive:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.isActive : false
    //         },
    //       )
    //     })

    //     setdata({...data,...setData})
    //     setdayss(daysData)
    //  }else{
    //   let setData  = {
    //     name:state?.name,
    //     openBiddingTime:state?.gamedays[0]?.openBiddingTime,
    //     closeBiddingTime:state?.gamedays[0]?.closeBiddingTime,
    //     specialDays:true
    //   }
    //   setdata({...data,...setData})

    //   let daysData = []

    //     new Array(7).fill('_')?.forEach((d,i)=>{
    //       daysData.push(
    //         {
    //           id:i,
    //           day:days[i],
    //           openBiddingTime:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.openBiddingTime : '',
    //           closeBiddingTime:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.closeBiddingTime : '',
    //           isActive:state?.gamedays?.find((f)=>f?.day == i) !== undefined ?  state?.gamedays?.find((f)=>f?.day == i)?.isActive : false
    //         },
    //       )
    //     })
    //     setspecialDays(true)
    //     setdayss(daysData)

    //  }
    }
  },[state])

  useEffect(()=>{
    getGames()
  },[])


  async function getGames(){
    const response = await GetAllGameService()
    let arr = []
    let d = response?.data?.data
    d?.forEach((d1)=>{
        arr.push({label:d1?.name,value:d1?._id,gamedays:d1?.gamedays})
    })
    setgames(arr)
  }
  
  function handlechange(e){
    setdata({...data,[e.target.name]:e.target.value})
  }

  async function submitData(){
    
        let sendData = {
         openBiddingTime:data.openBiddingTime,
         closeBiddingTime:data.closeBiddingTime,
        }
        // sendData['game'] = data?.game?.value
        
        const response = await UpdateGameTodayTimingService(state?._id,sendData)
        if(response?.status === 200){
          toast.success("Today Game Updated Timing")
          navigate(-1)
          setdata({game:'',gameDay:'',date:'',day:'',openBiddingTime:'',closeBiddingTime:'',result:'',game_completed:''})
          seterror({game:'',gameDay:'',date:'',day:'',openBiddingTime:'',closeBiddingTime:'',result:'',game_completed:''})
        }
    }


  async function setdayssactivefun(i,type,v){
     if(type === 'active'){
        let datas = [...dayss]
        datas[i]['isActive'] =  !datas[i]['isActive'] 
        setdayss(datas)
     }else if(type === 'openBiddingTime'){
      let datas = [...dayss]
      datas[i]['openBiddingTime'] =  v 
      setdayss(datas)
     }else if(type === 'closeBiddingTime'){
      let datas = [...dayss]
      datas[i]['closeBiddingTime'] =  v 
      setdayss(datas)
     }

  }

  async function setspecialdaysfun(){
    if(specialDays === false){
      setspecialDays(!specialDays)
      let days_set = []
      days?.forEach((d,i)=>{
        days_set.push(
          {
            id:i,
            day:days[i],
            openBiddingTime:data.openBiddingTime,
            closeBiddingTime:data.closeBiddingTime,
            isActive:true
          },
        )
      })
      setdayss(days_set)
    }else{
      setspecialDays(!specialDays)
    }

  }

  async function handlechange1(e,v){
    if(e === 'game'){
        const d = new Date().getDay();
        const game = games.find((f)=>f.value === v)
        const openBiddingTime = game.gamedays.find((f)=>f.day === d)?.openBiddingTime
        const closeBiddingTime = game.gamedays.find((f)=>f.day === d)?.closeBiddingTime
        const day = d
        const date =  new Date()?.toISOString()?.slice(0,10)
        const gameDay = days[d]?.label
        setdata({...data,game:game,openBiddingTime,closeBiddingTime,day,date,gameDay})
    }
  }
  
  return (
    <div className='px-5 max-h-screen h-screen no-scrollbar overflow-y-scroll mt-5 w-[300px]'>


      <GoBack />

      <h6 className='font-[700] text-[13px]'>{path === 'edit' ? 'Update' : 'Create'} the Play Games</h6>
      <h6 className='font-[400] text-[12px]'>Use the below form to create or update the play games for the users</h6>
       
     {path !== 'edit' &&
      <>
      <h6 className='text-[11px] font-[600] mb-1 mt-2' >Select Game</h6>
     <Select 
     bordered={false}
     value={data?.game}
     className='border w-full border-slate-300'
     options={games}
     onChange={(e)=>handlechange1('game',e)}/>


      <TextInput 
        name="date"
        label="Date"
        type="date"
        value={data?.date}
        error={error?.date}
        handlechange={handlechange}
      />

      
  </>}
      <TextInput 
        name="openBiddingTime"
        label="Open Bidding Time"
        type="time" 
        value={data?.openBiddingTime}
        error={error?.openBiddingTime}
        handlechange={handlechange}

      />

      <TextInput 
        name="closeBiddingTime"
        label="Close Bidding Time"
        type="time" 
        value={data?.closeBiddingTime}
        error={error?.closeBiddingTime}
        handlechange={handlechange}

      />

      
    


    


       
      <div>

      </div>

      {/* <h6 className='text-[11px] font-[600] mb-1 mt-2' >{'Select Day'}</h6>
      <div>
      <Select value={data?.openDate} bordered={false} className='w-full border border-slate-300' onChange={(e)=>setdata({...data,openDate:e})}  options={days} />
      </div> */}

      <div className='flex mt-4 border-t pt-2 mb-10'>
      <ButtonFilledAutoWidth  btnName="Save" onClick={submitData}/>
      <h6 className='w-2'></h6>
      <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>navigate(-1)}/>
      </div>
    </div>
  )
}

export default GamesPlayListCE