import React,{useEffect, useState} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import { Select } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import GoBack from '../../components/back/GoBack'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { CreateAdminWalletTransactionService } from '../../services/WalletServices'
import toast from 'react-hot-toast'
import { UpdateBiddingServices } from '../../services/BiddingServices'

function BiddingListCE() {

  const navigate = useNavigate()  

  const {state} = useLocation()

  const [data,setdata] = useState({amount:'',number:''})


  useEffect(()=>{
        setdata({...data,amount:state?.amount,number:state?.number})
  },[state])

  

  async function createtransactionfunction(){
     const response = await UpdateBiddingServices(state?._id,data)
     if(response?.status == 200){
      toast.success("Updated successfully")
      navigate(-1)
     }
  }

  async function handlechange(e){
    setdata({...data,[e.target.name]:e.target.value})
  }

  return (
    <div className='ml-4 mt-4 w-[280px]'>
        <div>
           <GoBack /> 
           <h6 className='font-[700]'>Update Bidding Transaction</h6>
           <h6 className='text-[10px] leading-[14px] p-2 bg-slate-100 mt-1'>Use the below form to update the bidding  transaction info  .</h6>

      

<TextInput
             label={'Game Name'} 
              readOnly={true}
             value={state?.gameName}
             handlechange={handlechange}
           />


<TextInput
             label={'Game Tyoe'} 
              readOnly={true}
             value={state?.gameType}
             handlechange={handlechange}
           />

<TextInput
             label={'Number'} 
             name="number"
             type="number"
             value={data?.number}
             handlechange={handlechange}
           />

           <TextInput
             label={'Amount'} 
             name="amount"
             type="number"
             min={1}
             value={data?.amount}
             handlechange={handlechange}
           />

          

           
           
           <div className='pt-2 mt-4 border-t flex justify-end'>
           <ButtonOutlinedAutoWidth onClick={()=>navigate(-1)} btnName="Cancel" style="mr-2" />
           <ButtonFilledAutoWidth onClick={createtransactionfunction} btnName="Save" />
           </div>
        </div>
    </div>
  )
}

export default BiddingListCE