import React,{useEffect, useState} from 'react'
import { TextInput } from '../../components/input'
import GoBack from '../../components/back/GoBack';
import { useNavigate, useLocation } from 'react-router-dom'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import { CreatePromotionalTextService, UpdatePromotionalTextService } from '../../services/PromotionalTextServices';

function PromotionalTextCE() {
   
   const [data,setdata] = useState({name:''});  
   const [error,seterror] = useState({name:''}); 

   const {state,pathname} = useLocation();
  
   const path = pathname.split('/')[pathname.split('/').length - 1]
   const navigate = useNavigate();

    
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }


  useEffect(()=>{
       if(path === 'edit'){
        setdata({...data,name:state?.name})
       }
  },[state])

  async function submitform(){
    if(!data?.name){
        seterror({...error,name:'This Field is required*'})
    }else{
       if(path === 'create'){
           const response  = await CreatePromotionalTextService(data)
           if(response?.status === 201){
            setdata({name:''})
            seterror({name:''})
            toast.success("Promotional Text Created")
           }
       }else{
        const response  = await UpdatePromotionalTextService(state._id,data)
        if(response?.status === 200){
            setdata({name:''})
            seterror({name:''})
            toast.success("Promotional Text Updated")
            navigate(-1)
           }
       }
    }

  }

  return (
    <div className='w-[300px] px-5 mt-5'>

        <GoBack />

            <h6 className='font-bold text-[14px]'>Add / Update Promotional Text</h6>
        <h6 className='text-[10px] mt-2 bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Promotional Text</b> for your game employees to access this message.</h6>
     

        <TextInput 
            label={'Promotional Text'}  
            variant="standard"
            name="name"
            type="text"
            error={error.name}
            value={data.name}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"}  onClick={submitform} />  
        </div>

    </div>
  )
}

export default PromotionalTextCE