import React,{useEffect, useState} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import GoBack from '../../components/back/GoBack';
import { useNavigate, useLocation } from 'react-router-dom'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import { CreatePromotionalTextService, UpdatePromotionalTextService } from '../../services/PromotionalTextServices';
import Uploader from '../../components/Uploader';
import {BiErrorCircle} from 'react-icons/bi'
import { CreateBannerService } from '../../services/BannerServices';
import { CreateAppOfferService, UpdateAppOfferService } from '../../services/AppofferServices';
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import { Select } from 'antd';
function AppOfferCE() {
   
   const [data,setdata] = useState({offerName:'',offerDescription:'',offerImage:'',amountsToAdd:'',targetPlace:'',type:{label:'Fixed Amount',value:'Fixed Amount'},profitPercentage:'',isDeleted:false});  
   const [error,seterror] = useState({offerName:'',offerDescription:'',offerImage:'',amountsToAdd:'',targetPlace:'',profitPercentage:'',isDeleted:false}); 
   const [loader,setloader] = useState(false);  

   const {state,pathname} = useLocation();
  
   const path = pathname.split('/')[pathname.split('/').length - 1]
   const navigate = useNavigate();

    
   function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

 

  useEffect(()=>{
       if(path === 'edit'){
        setdata({...data,...state})
       }
  },[state])

  async function submitform(){
    if(!data?.offerName){
        console.log("1")
        seterror({...error,offerName:'This Field is required*'})
    }else if(!data?.offerImage){
        console.log("2")
        seterror({...error,offerImage:'This Field is required*'})
    }else if(!data?.amountsToAdd){
        console.log("3")
        seterror({...error,amountsToAdd:'This Field is required*'})
    }else if(!data?.profitPercentage){
        console.log("4")
        seterror({...error,profitPercentage:'This Field is required*'})
    }else if(!data?.offerDescription){
        console.log("5")
        seterror({...error,offerDescription:'This Field is required*'})
    }else{
        console.log("11")
       if(path === 'create'){
           setloader(true)
           const fd = new FormData()
           fd.append('offerImage',data?.offerImage)
           fd.append('offerName',data?.offerName)
           fd.append('offerDescription',data?.offerDescription)
           fd.append('amountsToAdd',data?.amountsToAdd)
           fd.append('targetPlace',data?.targetPlace)
           fd.append('profitPercentage',data?.profitPercentage)
           fd.append('type',data?.type?.label)
          
           const response  = await CreateAppOfferService(fd)
           console.log("response?.data",response?.data)
           if(response?.status === 201){
            setloader(false)
            setdata({offerName:'',offerDescription:'',offerImage:'',amountsToAdd:'',targetPlace:'',profitPercentage:'',type:{label:'Fixed Amount',value:'Fixed Amount'},isDeleted:false})
            seterror({offerName:'',offerDescription:'',offerImage:'',amountsToAdd:'',targetPlace:'',profitPercentage:'',type:'',isDeleted:false})
            toast.success("Offer Created")
           }else{
            setloader(false)
           }
       }else{
        setloader(true)
        const fd = new FormData()
        fd.append('offerImage',data?.offerImage)
        fd.append('offerName',data?.offerName)
        fd.append('offerDescription',data?.offerDescription)
        fd.append('amountsToAdd',data?.amountsToAdd)
        fd.append('targetPlace',data?.targetPlace)
        fd.append('profitPercentage',data?.profitPercentage)
        fd.append('isDeleted',data?.isDeleted ? 1 : 0)
        fd.append('type',data?.type?.label)
        const response  = await UpdateAppOfferService(state._id,fd)
        if(response?.status === 200){
            setloader(false)
            setdata({offerName:'',offerDescription:'',offerImage:'',amountsToAdd:'',targetPlace:'',profitPercentage:'',type:{label:'Fixed Amount',value:'Fixed Amount'},isDeleted:false})
            seterror({offerName:'',offerDescription:'',offerImage:'',amountsToAdd:'',targetPlace:'',profitPercentage:'',type:'',isDeleted:false})
            toast.success("Offer Updated")
            navigate(-1)
           }else{
            setloader(false)
           }
       }
    }

  }

//   console.log("error",error)

  return (
    <div className='w-[300px] overflow-y-scroll  no-scrollbar mb-5 px-5 mt-5'>

        <GoBack />

            <h6 className='font-bold text-[14px]'>Add / Update App Offer</h6>
        <h6 className='text-[10px] mt-2 bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> App Offer</b> for your game playes to access this offer.</h6>
     

        <TextInput 
            label={'Offer Name'}  
            variant="standard"
            mandatory={true}
            name="offerName"
            type="text"
            error={error.offerName}
            value={data.offerName}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


        <h6 className='text-[11px] font-[600] mb-1 mt-1' >Select Poster</h6>
       
        <Uploader image={data?.offerImage}  setimagefunc={(e)=>{setdata({...data,offerImage:e});seterror({...error,offerImage:''})}}  removeimageuploadfunc = {()=>{setdata({...data,offerImage:''});seterror({...error,offerImage:''})}} />
        {error?.offerImage !== '' && error?.offerImage !== undefined &&
        <div className='flex items-center mt-1'>
        <BiErrorCircle className='text-red-500' size={14} />
        <span className='text-[10px] text-red-500 ml-1'>{error?.offerImage}</span>
        </div>}


        <TextInput 
            label={'Amount To Add'}  
            variant="standard"
            mandatory={true}
            name="amountsToAdd"
            type="number"
            error={error.amountsToAdd}
            value={data.amountsToAdd}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<TextInput 
            label={'Target Place'}  
            variant="standard"
            mandatory={true}
            name="targetPlace"
            type="text"
            error={error.targetPlace}
            value={data.targetPlace}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>   


            <TextInput 
            label={'Profit Percentage'}  
            mandatory={true}
            variant="standard"
            name="profitPercentage"
            type="number"
            error={error.profitPercentage}
            value={data.profitPercentage}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>  


            <h6 className='text-[11px] p-2 bg-sky-100 mt-2'><span className='font-[700]'>Note : Offer description should be in the format of mini : max </span>
                Ex: Falt 5% off : Instagamez offer  get a additional 5% off amount deposit by using this coupon!
            </h6>

<TextAreaInput1 
            label={'Offer Description'}  
            variant="standard"
            mandatory={true}
            name="offerDescription"
            type="text"
            error={error.offerDescription}
            value={data.offerDescription}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>  


            <h6 className='text-[11px] font-[600] mb-1' >{'Type'}</h6>

            <Select 
                bordered={false}
                className='border border-l-4 border-l-slate-700 w-[100%]'
                value={data?.type}
                options={[{label:'Fixed Amount',value:'Fixed Amount'},{label:'Variable Amount',value:'Variable Amount'}]}
                onChange={(e)=>setdata({...data,type:{label:e,value:e}})}
            />

           
            {path === 'edit' &&
            <div onClick={()=>setdata({...data,isDeleted:!data.isDeleted})} className='flex mt-2 items-center text-[11px]'>
                {data?.isDeleted ? <BiCheckboxSquare className='text-slate-700' size={20} />  : <BiCheckbox className='text-slate-300' size={20} /> }
                <h6 className='ml-1'>Deleted</h6>
            </div>}


        {!loader ? 
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} onClick={submitform} />  
        </div>
        :
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} />  
        </div>}

    </div>
  )
}

export default AppOfferCE