import { get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const UserService = async (data)=>{
    try {
      const result = await publicPost(`api/user/login`, data);
      return result;
    } catch (err) {
      return err.response;
    }
}


export const GetAllUserService = async (type,page,search_text,from_date,to_date)=>{
  try {
    const result = await get(`api/auth/user?type=${type}&search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
    return result;
  } catch (err) {
    return err.response;
  }
}