import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import moment from 'moment';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { DeletePromotionalTextService, GetAllPromotionalTextService } from '../../services/PromotionalTextServices';
import { Modal } from 'antd';
import toast from 'react-hot-toast';
import { DeleteNotificationService, GetAllNotificationService } from '../../services/NotificationServices';
import { Icon, IconButton } from '@mui/material';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';

function NotificationList() {
  
  const [datas,setdatas] = useState({})  
  const [selectedData,setselectedData] = useState({})  
  const [page,setpage] = useState(1)
  const [modal, setModal] = useState(false);
  

  const navigate = useNavigate()
 
  useEffect(()=>{
    getData()
  },[page])

  async function getData(){
    const response = await GetAllNotificationService(page)
    console.log("response?.data?.data",response?.data?.data)
    if(response?.status == 200){
      setdatas(response?.data?.data)
    }
  }


  async function addData(){
    navigate('create')
  }

  async function deleteData(){
   const response = await DeleteNotificationService(selectedData._id)
   if(response.status === 200){
    getData()
    setModal(false)
    toast.success("Notification Deleted")
   }
  }

  async function resetfilterfunction(){
    setpage(1)
    const response = await GetAllNotificationService(1)
    setdatas(response?.data?.data)
  }





    


  return (
    <div className='w-[calc(100%-200px)] p-5'>
         <div className='flex items-center justify-between border-b pb-2'>
         <Modal
       keepMounted
       open={modal}
       onClose={()=>setModal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-[11px] leading-normal p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteData()}  /> 
           </div>
         </div>
       </div>
     </Modal>
         
        <h6 className='font-[700] text-[14px] '>Notifications ({datas?.pagination?.total})</h6>
        <div className='flex items-center'>
          <div className='flex items-center text-[12px] mr-2'>

          <div className='flex items-center'>
            <h6 className='mr-2 text-[11px] font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 : (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
            <IconButton onClick={resetfilterfunction}>
            <BsArrowRepeat size={16} />
            </IconButton>
            <div className='flex'>
               <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}>
                <FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>
                <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}>
                <FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>
            </div>
           </div>   

            

          <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>addData()} /> 
          </div>


      

       
      
        </div>

        </div>
        

        
        <div className='h-[89vh] overflow-y-scroll'>
        <div className='grid mt-2  no-scrollbar overflow-y-scroll grid-cols-5 gap-2'>
          {datas?.datas?.map((d)=>(
            <div className='border min-h-[220px] max-h-[220px] relative p-1 px-2'>
                 <div className='absolute border-l border-b  right-0 top-0 bg-white '>
                    <div className='flex'>
                    <AiOutlineEdit size={17} onClick={()=>navigate('edit',{state:d})} className='p-[2px] cursor-pointer border-r' />
                    <AiOutlineDelete size={17}  onClick={()=>{setselectedData(d);setModal(!modal)}} className='p-[2px] cursor-pointer' />
                   </div>
                 </div>  

                <img alt={d?.image} src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.image}`} className='w-[100%] border h-[80px] object-contain' />

                <h1 className='text-[12px] text-slate-700 mt-2 z-10'>Title : <span className='font-[600] text-black'>{d?.title}</span></h1>
                <h1 className='text-[12px] text-slate-700 z-10'>Description : </h1>
                <h1 className='text-[12px] line-clamp-2 text-slate-700  z-10'>{d?.description} </h1>
                <h6 className='text-[10px] bg-slate-100 p-1 mt-1'>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>

            </div>  
          ))}

        </div>
        </div>
    </div>
  )
}

export default NotificationList