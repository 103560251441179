import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const GetAllAppOfferService = async (page,step)=>{
    try {
      const result = await get(`api/appoffer/get?step=${step}&page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

export const CreateAppOfferService = async (data)=>{
    try {
      const result = await postfd(`api/appoffer/create`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }  

export const UpdateAppOfferService = async (id,data)=>{
    try {
      const result = await postfd(`api/appoffer/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }    

export const DeleteAppOfferService = async (id,data)=>{
    try {
      const result = await deleteRequest(`api/appoffer/delete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }   