import {deleteRequest, get, post, put} from "../helpers/apihelpers";

export const GetWalletByIdService = async (id)=>{
    try {
      const result = await get(`api/wallet/get/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  } 

  export const UpdateWalletTransactionByIdService = async (id,data)=>{
    try {
      const result = await put(`api/wallet/update_wallet_transaction/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const DeleteWalletTransactionByIdService = async (id)=>{
    try {
      const result = await deleteRequest(`api/wallet/delete_wallet_transaction/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const CreateAdminWalletTransactionService = async (data)=>{
    try {
      const result = await post(`api/wallet/create_wallet_admin`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }
