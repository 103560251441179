import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const GetAllBannerService = async ()=>{
    try {
      const result = await get(`api/banner/get`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

export const CreateBannerService = async (data)=>{
    try {
      const result = await postfd(`api/banner/create`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }  

export const UpdateBannerService = async (id,data)=>{
    try {
      const result = await postfd(`api/banner/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }    

export const DeleteBannerService = async (id,data)=>{
    try {
      const result = await deleteRequest(`api/banner/delete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }   