import { get } from "../helpers/apihelpers";

export const GetDashboardService = async ()=>{
    try {
      const result = await get(`api/dashboard/get`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

export const GetDashboardAnalyticService = async ()=>{
    try {
      const result = await get(`api/dashboard/get_analytics`);
      return result;
    } catch (err) {
      return err.response;
    }
  } 
