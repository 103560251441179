import React,{useEffect, useState} from 'react'
import { TextInput } from '../../components/input'
import GoBack from '../../components/back/GoBack';
import { useNavigate, useLocation } from 'react-router-dom'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import { CreateGameDefaultService, UpdateGameDefaultService } from '../../services/GameDefaultServices';
import {BiSolidCheckboxChecked} from 'react-icons/bi'

function AppDefaultCE() {
   
   const [data,setdata] = useState({minimumDepositAmount:'',minmumWithDrawAmount:'',minimumBiddingAmount:'',maximumBiddingAmountSingle:'',maximumBiddingAmountDouble:'',maximumBiddingAmountSinglePanna:'',maximumBiddingAmountDoublePanna:'',maximumBiddingAmountTriplePanna:'',maximumBiddingAmountHalfSangam:'',maximumBiddingAmountFullSangam:'',percentageDeduct:'',withdraw_open:'',withdraw_close:'',withdraw_request_count:'',withdrawAvailable:true});  
   const [error,seterror] = useState({minimumDepositAmount:'',minmumWithDrawAmount:'',minimumBiddingAmount:'',maximumBiddingAmountSingle:'',maximumBiddingAmountDouble:'',maximumBiddingAmountSinglePanna:'',maximumBiddingAmountDoublePanna:'',maximumBiddingAmountTriplePanna:'',maximumBiddingAmountHalfSangam:'',maximumBiddingAmountFullSangam:'',percentageDeduct:'',withdraw_open:'',withdraw_close:'',withdraw_request_count:'',withdrawAvailable:true}); 

   const {state,pathname} = useLocation();
  
   const path = pathname.split('/')[pathname.split('/').length - 1]
   const navigate = useNavigate();

    
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }


  useEffect(()=>{
       if(path === 'edit'){
        setdata({...data,...state})
       }
  },[state])

  async function submitform(){
    if(!data?.minimumDepositAmount){
        seterror({...error,minimumDepositAmount:'This Field is required*'})
    }else if(!data?.minmumWithDrawAmount){
        seterror({...error,minmumWithDrawAmount:'This Field is required*'})
    }else if(!data?.minimumBiddingAmount){
        seterror({...error,minimumBiddingAmount:'This Field is required*'})
    }else if(!data?.maximumBiddingAmountSingle){
        seterror({...error,maximumBiddingAmountSingle:'This Field is required*'})
    }else if(!data?.maximumBiddingAmountDouble){
        seterror({...error,maximumBiddingAmountDouble:'This Field is required*'})
    }else if(!data?.maximumBiddingAmountSinglePanna){
        seterror({...error,maximumBiddingAmountSinglePanna:'This Field is required*'})
    }else if(!data?.maximumBiddingAmountDoublePanna){
        seterror({...error,maximumBiddingAmountDoublePanna:'This Field is required*'})
    }else if(!data?.maximumBiddingAmountTriplePanna){
        seterror({...error,maximumBiddingAmountTriplePanna:'This Field is required*'})
    }else if(!data?.maximumBiddingAmountTriplePanna){
        seterror({...error,maximumBiddingAmountTriplePanna:'This Field is required*'})
    }else if(!data?.maximumBiddingAmountHalfSangam){
        seterror({...error,maximumBiddingAmountHalfSangam:'This Field is required*'})
    }else if(!data?.maximumBiddingAmountFullSangam){
        seterror({...error,maximumBiddingAmountFullSangam:'This Field is required*'})
    }else if(!data?.withdraw_open){
        seterror({...error,withdraw_open:'This Field is required*'})
    }else if(!data?.withdraw_close){
        seterror({...error,withdraw_close:'This Field is required*'})
    }else if(!data?.withdraw_request_count){
        seterror({...error,withdraw_request_count:'This Field is required*'})
    }else{
       if(path === 'create'){
           const response  = await CreateGameDefaultService(data)
           if(response?.status === 201){
            setdata({minimumDepositAmount:'',minmumWithDrawAmount:'',minimumBiddingAmount:'',maximumBiddingAmountSingle:'',maximumBiddingAmountDouble:'',maximumBiddingAmountSinglePanna:'',maximumBiddingAmountDoublePanna:'',maximumBiddingAmountTriplePanna:'',maximumBiddingAmountHalfSangam:'',maximumBiddingAmountFullSangam:'',withdraw_open:'',withdraw_close:'',withdraw_request_count:'',withdrawAvailable:true})
            seterror({minimumDepositAmount:'',minmumWithDrawAmount:'',minimumBiddingAmount:'',maximumBiddingAmountSingle:'',maximumBiddingAmountDouble:'',maximumBiddingAmountSinglePanna:'',maximumBiddingAmountDoublePanna:'',maximumBiddingAmountTriplePanna:'',maximumBiddingAmountHalfSangam:'',maximumBiddingAmountFullSangam:'',withdraw_open:'',withdraw_close:'',withdraw_request_count:'',withdrawAvailable:true})
            toast.success("App Default Created")
           }
       }else{
        const response  = await UpdateGameDefaultService(state._id,data)
        if(response?.status === 200){
            setdata({minimumDepositAmount:'',minmumWithDrawAmount:'',minimumBiddingAmount:'',maximumBiddingAmountSingle:'',maximumBiddingAmountDouble:'',maximumBiddingAmountSinglePanna:'',maximumBiddingAmountDoublePanna:'',maximumBiddingAmountTriplePanna:'',maximumBiddingAmountHalfSangam:'',maximumBiddingAmountFullSangam:'',withdraw_open:'',withdraw_close:'',withdraw_request_count:'',withdrawAvailable:true})
            seterror({minimumDepositAmount:'',minmumWithDrawAmount:'',minimumBiddingAmount:'',maximumBiddingAmountSingle:'',maximumBiddingAmountDouble:'',maximumBiddingAmountSinglePanna:'',maximumBiddingAmountDoublePanna:'',maximumBiddingAmountTriplePanna:'',maximumBiddingAmountHalfSangam:'',maximumBiddingAmountFullSangam:'',withdraw_open:'',withdraw_close:'',withdraw_request_count:'',withdrawAvailable:true})
            toast.success("App Default Updated")
            navigate(-1)
           }
       }
    }

  }

  return (
    <div className='w-[480px] px-5 mt-2 overflow-y-scroll'>

        <GoBack />

            <h6 className='font-bold text-[14px]'>Add / Update App Default</h6>
        <h6 className='text-[10px] mt-2 bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> App Default</b> for your game employees to access this message.</h6>
     

        <div className='flex justify-between mt-2'>
            <div className='mr-2'>
        <TextInput 
            label={'Minimum Deposit Amount'}  
            variant="standard"
            name="minimumDepositAmount"
            type="number"
            error={error.minimumDepositAmount}
            value={data.minimumDepositAmount}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<TextInput 
            label={'Minimum WithDrawl Amount'}  
            variant="standard"
            name="minmumWithDrawAmount"
            type="number"
            error={error.minmumWithDrawAmount}
            value={data.minmumWithDrawAmount}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<TextInput 
            label={'Minimum Bidding Amount'}  
            variant="standard"
            name="minimumBiddingAmount"
            type="number"
            error={error.minimumBiddingAmount}
            value={data.minimumBiddingAmount}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
            <TextInput 
            label={'Maximum Bidding Amount Single'}  
            variant="standard"
            name="maximumBiddingAmountSingle"
            type="number"
            error={error.maximumBiddingAmountSingle}
            value={data.maximumBiddingAmountSingle}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
            <TextInput 
            label={'Maximum Bidding Amount Double'}  
            variant="standard"
            name="maximumBiddingAmountDouble"
            type="number"
            error={error.maximumBiddingAmountDouble}
            value={data.maximumBiddingAmountDouble}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<TextInput 
            label={'Maximum Bidding Amount Single Panna'}  
            variant="standard"
            name="maximumBiddingAmountSinglePanna"
            type="number"
            error={error.maximumBiddingAmountSinglePanna}
            value={data.maximumBiddingAmountSinglePanna}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
        
        <TextInput 
            label={'Maximum Bidding Amount Double Panna'}  
            variant="standard"
            name="maximumBiddingAmountDoublePanna"
            type="number"
            error={error.maximumBiddingAmountDoublePanna}
            value={data.maximumBiddingAmountDoublePanna}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

          <h6 onClick={()=>setdata({...data,withdrawAvailable:!data.withdrawAvailable})} className='flex cursor-pointer items-center mr-4 mt-1'>
            <BiSolidCheckboxChecked className={data?.withdrawAvailable ? 'text-slate-700' : `text-slate-200`} size={25} />
            <h6 className='ml-2 text-[12px] font-[700]'>WithDraw Avaialble</h6>
          </h6>
            
            </div>

            <div className='ml-2'>
        
       
        
        <TextInput 
            label={'Maximum Bidding Amount Tripple Panna'}  
            variant="standard"
            name="maximumBiddingAmountTriplePanna"
            type="number"
            error={error.maximumBiddingAmountTriplePanna}
            value={data.maximumBiddingAmountTriplePanna}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Maximum Bidding Amount Half Sangam'}  
            variant="standard"
            name="maximumBiddingAmountHalfSangam"
            type="number"
            error={error.maximumBiddingAmountHalfSangam}
            value={data.maximumBiddingAmountHalfSangam}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Maximum Bidding Amount Full Sangam'}  
            variant="standard"
            name="maximumBiddingAmountFullSangam"
            type="number"
            error={error.maximumBiddingAmountFullSangam}
            value={data.maximumBiddingAmountFullSangam}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


<TextInput 
            label={'WithDraw Amount Percentage Deduct'}  
            variant="standard"
            name="percentageDeduct"
            type="number"
            error={error.percentageDeduct}
            value={data.percentageDeduct}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<TextInput 
            label={'Withdraw Request Count'}  
            variant="standard"
            name="withdraw_request_count"
            type="number"
            error={error.withdraw_request_count}
            value={data.withdraw_request_count}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<TextInput 
        name="withdraw_open"
        label="WithDraw Open Time"
        type="time" 
        value={data?.withdraw_open}
        error={error?.withdraw_open}
        handlechange={handlechange}

      />

<TextInput 
        name="withdraw_close"
        label="WithDraw Close Time"
        type="time" 
        value={data?.withdraw_close}
        error={error?.withdraw_close}
        handlechange={handlechange}

      />
</div>
</div>
<div className='mt-4'>
        <ButtonFilledAutoWidth btnName={"Save"}  onClick={submitform} />  
        </div>

    </div>
  )
}

export default AppDefaultCE