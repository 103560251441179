import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const GetAllPaymentTrackService = async (page,step,from_date,to_date,search)=>{
    try {
      const result = await get(`api/wallet/get_payment_track?page=${page}&step=${step}&from_date=${from_date}&to_date=${to_date}&search=${search}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }
