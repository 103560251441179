export const singlePanaValuesList = [
    [
      {"label": "127"},
      {"label": "136"},
      {"label": "145"},
      {"label": "190"},
      {"label": "235"},
      {"label": "280"},
      {"label": "370"},
      {"label": "389"},
      {"label": "460"},
      {"label": "479"},
      {"label": "569"},
      {"label": "578"},
    ],
    // 137, 128, 146, 236, 245, 290, 380, 470, 489, 560, 678, 579
    [
      {"label": "137"},
      {"label": "128"},
      {"label": "146"},
      {"label": "236"},
      {"label": "245"},
      {"label": "290"},
      {"label": "380"},
      {"label": "470"},
      {"label": "489"},
      {"label": "560"},
      {"label": "678"},
      {"label": "579"},
    ],
    // 129, 138, 147, 156, 237, 246, 345, 390, 480, 570, 589, 679
    [
      {"label": "129"},
      {"label": "138"},
      {"label": "147"},
      {"label": "156"},
      {"label": "237"},
      {"label": "246"},
      {"label": "345"},
      {"label": "390"},
      {"label": "480"},
      {"label": "570"},
      {"label": "589"},
      {"label": "679"},
    ],
  // 120, 139, 148, 157, 238, 247, 256, 346, 490, 580, 670, 689
    [
      {"label": "120"},
      {"label": "139"},
      {"label": "148"},
      {"label": "157"},
      {"label": "238"},
      {"label": "247"},
      {"label": "256"},
      {"label": "346"},
      {"label": "490"},
      {"label": "580"},
      {"label": "670"},
      {"label": "689"},
    ],
    //130, 149, 158, 167, 239, 248, 257, 347, 356, 590, 680, 789
    [
      {"label": "130"},
      {"label": "149"},
      {"label": "158"},
      {"label": "167"},
      {"label": "239"},
      {"label": "248"},
      {"label": "257"},
      {"label": "347"},
      {"label": "356"},
      {"label": "590"},
      {"label": "680"},
      {"label": "789"},
    ],
    //140, 159, 168, 230, 249, 258, 267, 348, 357, 456, 690, 780
    [
      {"label": "140"},
      {"label": "159"},
      {"label": "168"},
      {"label": "230"},
      {"label": "249"},
      {"label": "258"},
      {"label": "267"},
      {"label": "348"},
      {"label": "357"},
      {"label": "456"},
      {"label": "690"},
      {"label": "780"},
    ],
    //123, 150, 169, 178, 240, 259, 268, 349, 358, 367, 457, 790
    [
      {"label": "123"},
      {"label": "150"},
      {"label": "169"},
      {"label": "178"},
      {"label": "240"},
      {"label": "259"},
      {"label": "268"},
      {"label": "349"},
      {"label": "358"},
      {"label": "367"},
      {"label": "457"},
      {"label": "790"},
    ],
    //124, 160, 179, 250, 269, 278, 340, 359, 368, 458, 467, 890
    [
      {"label": "124"},
      {"label": "160"},
      {"label": "179"},
      {"label": "250"},
      {"label": "269"},
      {"label": "278"},
      {"label": "340"},
      {"label": "359"},
      {"label": "368"},
      {"label": "458"},
      {"label": "467"},
      {"label": "890"},
    ],
    //125, 134, 170, 189, 260, 279, 350, 369, 378, 459, 468, 567
    [
      {"label": "125"},
      {"label": "134"},
      {"label": "170"},
      {"label": "189"},
      {"label": "260"},
      {"label": "279"},
      {"label": "350"},
      {"label": "369"},
      {"label": "378"},
      {"label": "459"},
      {"label": "468"},
      {"label": "567"},
    ],
    //126, 135, 180, 234, 270, 289, 360, 379, 450, 469, 478, 568
    [
      {"label": "126"},
      {"label": "135"},
      {"label": "180"},
      {"label": "234"},
      {"label": "270"},
      {"label": "289"},
      {"label": "360"},
      {"label": "379"},
      {"label": "450"},
      {"label": "469"},
      {"label": "478"},
      {"label": "568"},
    ],
];
  
  //Double pana values
 export const doublePannaValuesList = [
    //118, 226, 244, 299, 334, 488, 668, 677, 550
    [
      {"label": "118"},
      {"label": "226"},
      {"label": "244"},
      {"label": "299"},
      {"label": "334"},
      {"label": "488"},
      {"label": "668"},
      {"label": "677"},
      {"label": "550"},
    ],
    //119, 155, 227, 335, 344, 399, 588, 669, 100
    [
      {"label": "119"},
      {"label": "155"},
      {"label": "227"},
      {"label": "335"},
      {"label": "344"},
      {"label": "399"},
      {"label": "588"},
      {"label": "669"},
      {"label": "100"},
    ],
    //110, 228, 255, 336, 499, 660, 688, 778, 200
    [
      {"label": "110"},
      {"label": "228"},
      {"label": "255"},
      {"label": "336"},
      {"label": "499"},
      {"label": "660"},
      {"label": "688"},
      {"label": "778"},
      {"label": "200"},
    ],
    //166, 229, 337, 355, 445. 599, 779, 788, 300
    [
      {"label": "166"},
      {"label": "229"},
      {"label": "337"},
      {"label": "355"},
      {"label": "445"},
      {"label": "599"},
      {"label": "779"},
      {"label": "788"},
      {"label": "300"},
    ],
    //112, 220, 266, 338, 446, 455, 699, 770, 400
    [
      {"label": "112"},
      {"label": "220"},
      {"label": "266"},
      {"label": "338"},
      {"label": "446"},
      {"label": "455"},
      {"label": "699"},
      {"label": "770"},
      {"label": "400"},
    ],
    // 113, 122, 177, 339, 366, 447, 799, 889, 500
    [
      {"label": "113"},
      {"label": "122"},
      {"label": "177"},
      {"label": "339"},
      {"label": "366"},
      {"label": "447"},
      {"label": "799"},
      {"label": "889"},
      {"label": "500"},
    ],
    // 114, 277, 330, 448, 466, 556, 880, 889, 600
    [
      {"label": "114"},
      {"label": "277"},
      {"label": "330"},
      {"label": "448"},
      {"label": "466"},
      {"label": "556"},
      {"label": "880"},
      {"label": "889"},
      {"label": "600"},
    ],
    // 115, 133, 188, 223, 377, 449, 557, 566, 700
    [
      {"label": "115"},
      {"label": "133"},
      {"label": "188"},
      {"label": "223"},
      {"label": "377"},
      {"label": "449"},
      {"label": "557"},
      {"label": "566"},
      {"label": "700"},
    ],
    //116, 224, 233, 288, 440, 477, 558, 999, 800
    [
      {"label": "116"},
      {"label": "224"},
      {"label": "233"},
      {"label": "288"},
      {"label": "440"},
      {"label": "477"},
      {"label": "558"},
      {"label": "999"},
      {"label": "800"},
    ],
    //117, 144, 199, 225, 388, 559, 577, 667, 990
    [
      {"label": "117"},
      {"label": "144"},
      {"label": "199"},
      {"label": "225"},
      {"label": "388"},
      {"label": "559"},
      {"label": "577"},
      {"label": "667"},
      {"label": "990"},
    ],
  ];
  
 export const jodiValuesList = [
    {"label": "00"},
    {"label": "02"},
    {"label": "01"},
    {"label": "03"},
    {"label": "04"},
    {"label": "05"},
    {"label": "06"},
    {"label": "07"},
    {"label": "08"},
    {"label": "09"},
    {"label": "10"},
    {"label": "11"},
    {"label": "12"},
    {"label": "13"},
    {"label": "14"},
    {"label": "15"},
    {"label": "16"},
    {"label": "17"},
    {"label": "18"},
    {"label": "19"},
    {"label": "20"},
    {"label": "21"},
    {"label": "22"},
    {"label": "23"},
    {"label": "24"},
    {"label": "25"},
    {"label": "26"},
    {"label": "27"},
    {"label": "28"},
    {"label": "29"},
    {"label": "30"},
    {"label": "31"},
    {"label": "32"},
    {"label": "33"},
    {"label": "34"},
    {"label": "35"},
    {"label": "36"},
    {"label": "37"},
    {"label": "38"},
    {"label": "39"},
    {"label": "40"},
    {"label": "41"},
    {"label": "42"},
    {"label": "43"},
    {"label": "44"},
    {"label": "45"},
    {"label": "46"},
    {"label": "47"},
    {"label": "48"},
    {"label": "49"},
    {"label": "50"},
    {"label": "51"},
    {"label": "52"},
    {"label": "53"},
    {"label": "54"},
    {"label": "55"},
    {"label": "56"},
    {"label": "57"},
    {"label": "58"},
    {"label": "59"},
    {"label": "60"},
    {"label": "61"},
    {"label": "62"},
    {"label": "63"},
    {"label": "64"},
    {"label": "65"},
    {"label": "66"},
    {"label": "67"},
    {"label": "68"},
    {"label": "69"},
    {"label": "70"},
    {"label": "71"},
    {"label": "72"},
    {"label": "73"},
    {"label": "74"},
    {"label": "75"},
    {"label": "76"},
    {"label": "77"},
    {"label": "78"},
    {"label": "79"},
    {"label": "80"},
    {"label": "81"},
    {"label": "82"},
    {"label": "83"},
    {"label": "84"},
    {"label": "85"},
    {"label": "86"},
    {"label": "87"},
    {"label": "88"},
    {"label": "89"},
    {"label": "90"},
    {"label": "91"},
    {"label": "92"},
    {"label": "93"},
    {"label": "94"},
    {"label": "95"},
    {"label": "96"},
    {"label": "97"},
    {"label": "98"},
    {"label": "99"},
  ];
  
 export const singleValuesList = [
    {"value": '',"label": "0"},
    {"value": '',"label": "1"},
    {"value": '',"label": "2"},
    {"value": '',"label": "3"},
    {"value": '',"label": "4"},
    {"value": '',"label": "5"},
    {"value": '',"label": "6"},
    {"value": '',"label": "7"},
    {"value": '',"label": "8"},
    {"value": '',"label": "9"}
  ];
  
 export const tripleValueList = [
    {"value": '',"label": "000"},
    {"value": '',"label": "111"},
    {"value": '',"label": "222"},
    {"value": '',"label": "333"},
    {"value": '',"label": "444"},
    {"value": '',"label": "555"},
    {"value": '',"label": "666"},
    {"value": '',"label": "777"},
    {"value": '',"label": "888"},
    {"value": '',"label": "999"},
  ];
  
 export const digitValidationNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  
 export const panaValidationNumbers = [
    127,
    136,
    145,
    190,
    235,
    280,
    370,
    389,
    460,
    479,
    569,
    578,
    137,
    128,
    146,
    236,
    245,
    290,
    380,
    470,
    489,
    560,
    678,
    579,
    129,
    138,
    147,
    156,
    237,
    246,
    345,
    390,
    480,
    570,
    589,
    679,
    120,
    139,
    148,
    157,
    238,
    247,
    256,
    346,
    490,
    580,
    670,
    689,
    130,
    149,
    158,
    167,
    239,
    248,
    257,
    347,
    356,
    590,
    680,
    789,
    140,
    159,
    168,
    230,
    249,
    258,
    267,
    348,
    357,
    456,
    690,
    780,
    123,
    150,
    169,
    178,
    240,
    259,
    268,
    349,
    358,
    367,
    457,
    790,
    124,
    160,
    179,
    250,
    269,
    278,
    340,
    359,
    368,
    458,
    467,
    890,
    125,
    134,
    170,
    189,
    260,
    279,
    350,
    369,
    378,
    459,
    468,
    567,
    126,
    135,
    180,
    234,
    270,
    289,
    360,
    379,
    450,
    469,
    478,
    568,
    118,
    226,
    244,
    299,
    334,
    488,
    668,
    677,
    550,
    119,
    155,
    227,
    335,
    344,
    399,
    588,
    669,
    100,
    110,
    228,
    255,
    336,
    499,
    660,
    688,
    778,
    200,
    166,
    229,
    337,
    355,
    445,
    599,
    779,
    788,
    300,
    112,
    220,
    266,
    338,
    446,
    455,
    699,
    770,
    400,
    113,
    122,
    177,
    339,
    366,
    447,
    799,
    889,
    500,
    114,
    277,
    330,
    448,
    466,
    556,
    880,
    889,
    600,
    115,
    133,
    188,
    223,
    377,
    449,
    557,
    566,
    700,
    116,
    224,
    233,
    288,
    440,
    477,
    558,
    999,
    800,
    117,
    144,
    199,
    225,
    388,
    559,
    577,
    667,
    990,
    111
  ];


  export const combination = [
      {
        label:0,
        value:["0",127,136,145,190,235, 280,370,479,460,,569,389,578,550,668,244,299, 226, 488, 677,118 ,334,"000"]
      },
      {
        label:1,
        value:[1, 128,137, 146, 236, 245, 290, 380, 470, 489, 560, 678, 579,100,119, 155, 227, 335, 344, 399, 588, 669,777]
      },
      {
        label:2,
        value:[2,129, 138, 147, 156, 237, 246, 345, 390, 480, 570,679, 589,200,110, 228, 255, 336, 499, 660, 688, 778, 444]
      },
      {
        label:3,
        value:[3,120, 139, 148, 157, 238, 247, 256, 346, 490, 580, 670, 689,300, 166, 229, 337, 355, 455, 599, 799, 788,111]
      },
      {
        label:4,
        value:[4,130, 149, 158, 167, 239, 248, 257, 347, 356, 590, 680, 789,400, 112, 220, 266, 338, 446, 455, 699, 770,888]
      },
      {
        label:5,
        value:[5,140, 159, 168, 230, 249, 258, 267, 348, 357, 456, 690, 780,500,113, 122, 177, 339, 366, 447, 799, 889, 555]
      },
      {
        label:6,
        value:[6,123, 150, 169, 178, 240, 259, 268, 349, 358, 457, 367, 790,600,114, 277, 330, 448, 466, 556, 880, 889, 222]
      },
      {
        label:7,
        value:[7,124, 160, 179, 250, 269, 278, 340, 359, 368, 458, 467, 890,700, 115,133, 188, 223, 377, 449, 557, 566, 999]
      },
      {
        label:8,
        value:[8,125, 134, 170, 189, 260, 279, 350, 369, 378, 459, 567, 468,800,116, 224, 233, 288, 440, 477, 558, 990, 666]
      },
      {
        label:9,
        value:[9,126, 135, 180, 234, 270, 289, 360, 379, 450, 469, 478, 568,900,117, 144, 199, 225, 388, 559, 577, 667, 333]
      }
  ]