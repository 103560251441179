import React,{useEffect, useState} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import GoBack from '../../components/back/GoBack';
import { useNavigate, useLocation } from 'react-router-dom'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import Uploader from '../../components/Uploader';
import {BiErrorCircle} from 'react-icons/bi'
import { CreateAppOfferService, UpdateAppOfferService } from '../../services/AppofferServices';
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import { Drawer } from 'antd';
import axios from 'axios';
import { UpdateUserById, getUserById } from '../../services/AuthServices';


function UsersCE() {
   
   const [data,setdata] = useState({offerName:'',offerDescription:'',offerImage:'',amountsToAdd:'',targetPlace:'',profitPercentage:'',isDeleted:false});  
   const [error,seterror] = useState({offerName:'',offerDescription:'',offerImage:'',amountsToAdd:'',targetPlace:'',profitPercentage:'',isDeleted:false}); 
   const [loader,setloader] = useState(false);  

   const {state,pathname} = useLocation();
  
   const path = pathname.split('/')[pathname.split('/').length - 1]
   const navigate = useNavigate();

    
   function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

 

  useEffect(()=>{
       if(path === 'edit'){
        getData()
       }
  },[state])


  async function getData(){
   const response =  await getUserById(state?._id)
   let d = response?.data?.data[0]
//    console.log("response",d) 
    setdata({...data,...d})
  }

  async function submitform(){
    if(!data?.phoneNumber){
        seterror({...error,phoneNumber:'This Field is required*'})
    }else if(!data?.otp){
        seterror({...error,otp:'This Field is required*'})
    }else{
        setloader(true)
        
        const response  = await UpdateUserById(state._id,data)
        if(response?.status === 200){
            setloader(false)
            setdata({phoneNumber:'',otp:'',isVerified:false,isDeleted:false})
            seterror({phoneNumber:'',otp:'',isVerified:'',isDeleted:''})
            toast.success("User Updated")
            navigate(-1)
           }else{
            setloader(false)
           }
    }

  }

//   console.log("error",error)

  return (
    <div className='w-[300px] px-5 mt-5'>
        

        <GoBack />

            <h6 className='font-bold text-[14px]'>Edit User Account Info</h6>
        <h6 className='text-[10px] mt-2 bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to update the <b> Account Info</b> for your game players.</h6>
     

        <TextInput 
            label={'Phone Number'}  
            variant="standard"
            mandatory={true}
            name="phoneNumber"
            type="number"
            error={error.phoneNumber}
            value={data.phoneNumber}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


       


        <TextInput 
            label={'OTP'}  
            variant="standard"
            mandatory={true}
            name="otp"
            type="number"
            error={error.otp}
            value={data.otp}
            handlechange={handlechange}
            placeholder="Enter  name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
          
        <div className='flex'>
        <div className='flex items-center my-2 mr-2' onClick={()=>setdata({...data,isDeleted:!data.isDeleted})}>      
        {!data?.isDeleted ? <BiCheckbox className='text-slate-300' size={20} /> :
        <BiCheckboxSquare className='text-slate-700' size={20}/> }
        <h6 className='text-[11px]'>Deleted Status</h6>
        </div> 

        <div className='flex items-center my-2' onClick={()=>setdata({...data,isVerified:!data.isVerified})}>      
        {!data?.isVerified ? <BiCheckbox className='text-slate-300' size={20} /> :
        <BiCheckboxSquare className='text-slate-700' size={20}/> }
        <h6 className='text-[11px]'>Verified Status</h6>
        </div> 
        </div>  


        {!loader ? 
        <div className='mt-2'>
        <ButtonFilledAutoWidth btnName={"Save"} onClick={submitform} />  
        </div>
        :
        <div className='mt-2'>
        <ButtonFilledAutoWidth btnName={"Save"} />  
        </div>}

    </div>
  )
}

export default UsersCE