import './App.css';
import { Routes,Route, useLocation } from 'react-router-dom';
import SideBar from './SideBar';
import { routes } from './folder/components/routes/router';
import PrivateRoute from './folder/components/routes/PrivateRoute'
import { Toaster } from 'react-hot-toast';


function App() {

  const disableDrawer = ['/','/login']
  const {pathname} = useLocation()


  return (
    // 
    <div className="flex max-h-screen h-screen no-scrollbar overflow-hidden">
       {!disableDrawer.includes(pathname) && <SideBar />}
       <Routes>
       {routes.map((screen,index) => (
          <Route key={index} exact path={screen.path}  element={<PrivateRoute roles={screen.roles}  >{screen.component}</PrivateRoute>} />
        ))}
       </Routes>
       
     

       <Toaster  position="bottom-center" toastOptions={{
          success: {
            style:{
              background:'black',
              color:'white',
              padding:'4px 7px',
              fontSize:'12px',
              borderRadius:'2px',
              iconTheme: {
                fontSize:'4px'
              }
            }
          },
          error: {
            style:{
              background:'black',
              color:'white',
              padding:'4px 7px',
              fontSize:'12px',
              borderRadius:'2px',
              iconTheme: {
                fontSize:'4px'
              }
            }
          }
        }}  />

    </div>
  );
}

export default App;
