import ManageAppList from "../../screens/ManageAppList";
import StatisticAppList from "../../screens/StatisticAppList";
import AddFund from "../../screens/add_fund/AddFund";
import AppDefaultCE from "../../screens/app_default/AppDefaultCE";
import AppDefaultList from "../../screens/app_default/AppDefaultList";
import AppOfferCE from "../../screens/app_offer/AppOfferCE";
import AppOfferList from "../../screens/app_offer/AppOfferList";
import Login from "../../screens/auth/Login";
import BannerCE from "../../screens/banner/BannerCE";
import BannerList from "../../screens/banner/BannerList";
import BiddingList from "../../screens/bidding/BiddingList";
import BiddingListCE from "../../screens/bidding/BiddingListCE";
import BlogBannerCE from "../../screens/blogbanner/BlogBannerCE";
import BlogBannerList from "../../screens/blogbanner/BlogBannerList";
import BlogCE from "../../screens/blogs/BlogCE";
import BlogList from "../../screens/blogs/BlogList";
import BlogCategoryCE from "../../screens/blogscategory/BlogCategoryCE";
import BlogCategoryList from "../../screens/blogscategory/BlogCategoryList";
import BlogHomeCE from "../../screens/blogshome/BlogHomeCE";
import BlogHomeList from "../../screens/blogshome/BlogHomeList";
import Dashboard from "../../screens/dashboard/Dashboard";
import GamesCE from "../../screens/games/GamesCE";
import GamesList from "../../screens/games/GamesList";
import GamePlayAllocateResult from "../../screens/gamesplay/GamePlayAllocateResult";
import GamePlayResult from "../../screens/gamesplay/GamePlayResult";
import GamesPlayList from "../../screens/gamesplay/GamesPlayList";
import GamesPlayListCE from "../../screens/gamesplay/GamesPlayListCE";
import NotificationCE from "../../screens/notification/NotificationCE";
import NotificationList from "../../screens/notification/NotificationList";
import PaymentTrack from "../../screens/payment_track/PaymentTrack";
import PromotionalTextCE from "../../screens/promotionalText/PromotionalTextCE";
import PromotionalTextList from "../../screens/promotionalText/PromotionalTextList";
import RatioCE from "../../screens/ratio/RatioCE";
import RatioList from "../../screens/ratio/RatioList";
import TransactionList from "../../screens/transaction/TransactionList";
import UserListWalletTransactionCE from "../../screens/users/UserListWalletTransactionCE";
import UsersCE from "../../screens/users/UsersCE";
import UsersList from "../../screens/users/UsersList";
import UserWalletTransaction from "../../screens/wallet/UserWalletTransaction";
import UserWalletTransactionCE from "../../screens/wallet/UserWalletTransactionCE";
import WithDrawCE from "../../screens/withdraw/WithDrawCE";
import WithDrawList from "../../screens/withdraw/WithDrawList";


export const routes = [
    {
        path:"/",
        component:<Login/>,
        private:false,
        roles:[]
    },
    {
        path:"/login",
        component:<Login/>,
        private:false,
        roles:[]
    },
    {
        path:"/dashboard",
        component:<Dashboard />,
        private:false,
        roles:[]
    },
    {
        path:"/banner",
        component:<BannerList />,
        private:false,
        roles:[]
    },
    {
        path:"/banner/:type",
        component:<BannerCE />,
        private:false,
        roles:[]
    },
    {
        path:"/blog_banner",
        component:<BlogBannerList />,
        private:false,
        roles:[]
    },
    {
        path:"/blog_banner/:type",
        component:<BlogBannerCE />,
        private:false,
        roles:[]
    },
    {
        path:"/offer",
        component:<AppOfferList />,
        private:false,
        roles:[]
    },
    {
        path:"/offer/:type",
        component:<AppOfferCE />,
        private:false,
        roles:[]
    },
    {
        path:"/app_default",
        component:<AppDefaultList />,
        private:false,
        roles:[]
    },
    {
        path:"/app_default/:type",
        component:<AppDefaultCE />,
        private:false,
        roles:[]
    },
    {
        path:"/ratio",
        component:<RatioList />,
        private:false,
        roles:[]
    },
    {
        path:"/ratio/:type",
        component:<RatioCE />,
        private:false,
        roles:[]
    },
    {
        path:"/promotional_text",
        component:<PromotionalTextList />,
        private:false,
        roles:[]
    },
    {
        path:"/promotional_text/:type",
        component:<PromotionalTextCE />,
        private:false,
        roles:[]
    },
    {
        path:"/statistics",
        component:<StatisticAppList />,
        private:false,
        roles:[]
    },
    {
        path:"/manage_app",
        component:<ManageAppList />,
        private:false,
        roles:[]
    },
    {
        path:"/notification",
        component:<NotificationList />,
        private:false,
        roles:[]
    },
    {
        path:"/blog",
        component:<BlogList />,
        private:false,
        roles:[]
    },
    {
        path:"/blog/:type",
        component:<BlogCE />,
        private:false,
        roles:[]
    },
    {
        path:"/blog_category",
        component:<BlogCategoryList />,
        private:false,
        roles:[]
    },
    {
        path:"/blog_category/:type",
        component:<BlogCategoryCE />,
        private:false,
        roles:[]
    },
    {
        path:"/blog_home",
        component:<BlogHomeList />,
        private:false,
        roles:[]
    },
    {
        path:"/blog_home/:type",
        component:<BlogHomeCE />,
        private:false,
        roles:[]
    },
    {
        path:"/biddings",
        component:<BiddingList />,
        private:false,
        roles:[]
    },
    {
        path:"/biddings/:type",
        component:<BiddingListCE />,
        private:false,
        roles:[]
    },
    {
        path:"/notification/:type",
        component:<NotificationCE />,
        private:false,
        roles:[]
    },
    {
        path:"/withdraw",
        component:<WithDrawList />,
        private:false,
        roles:[]
    },
    {
        path:"/transaction",
        component:<TransactionList />,
        private:false,
        roles:[]
    },
    {
        path:"/withdraw/:type",
        component:<WithDrawCE />,
        private:false,
        roles:[]
    },
    {
        path:"/games",
        component:<GamesList />,
        private:false,
        roles:[]
    },
    {
        path:"/games/:type",
        component:<GamesCE />,
        private:false,
        roles:[]
    },
    {
        path:"/games_play",
        component:<GamesPlayList />,
        private:false,
        roles:[]
    },
    {
        path:"/games_play_result",
        component:<GamePlayResult />,
        private:false,
        roles:[]
    },

    {
        path:"/games_play/open",
        component:<GamePlayAllocateResult />,
        private:false,
        roles:[]
    },
    {
        path:"/games_play/close",
        component:<GamePlayAllocateResult />,
        private:false,
        roles:[]
    },
    {
        path:"/games_play/status",
        component:<GamePlayAllocateResult />,
        private:false,
        roles:[]
    },
    {
        path:"/games_play/:type",
        component:<GamesPlayListCE />,
        private:false,
        roles:[]
    },
    
    {
        path:"/users",
        component:<UsersList />,
        private:false,
        roles:[]
    },
    {
        path:"/users/wallet_transaction_ce",
        component:<UserListWalletTransactionCE />,
        private:false,
        roles:[]
    },
    {
        path:"/users/wallet_transaction",
        component:<UserWalletTransaction />,
        private:false,
        roles:[]
    },
    {
        path:"/users/wallet_transaction/edit",
        component:<UserWalletTransactionCE />,
        private:false,
        roles:[]
    },
    {
        path:"/users/:type",
        component:<UsersCE />,
        private:false,
        roles:[]
    },
    {
        path:"/add_fund",
        component:<AddFund />,
        private:false,
        roles:[]
    },
    {
        path:"/payment_from_upi",
        component:<PaymentTrack />,
        private:false,
        roles:[]
    },
    
]