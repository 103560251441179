import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AiOutlineEdit,AiOutlineDelete,AiOutlineWallet } from 'react-icons/ai';
import moment from 'moment';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { DeleteBannerService, GetAllBannerService } from '../../services/BannerServices';
import { Modal } from 'antd';
import toast from 'react-hot-toast';
import { DeleteBlogBannerService, GetAllBlogBannerService } from '../../services/BlogBannerServices';

function BlogBannerList() {
  
  const [datas,setdatas] = useState([])  
  const [selectedData,setselectedData] = useState({})  
  const [modal, setModal] = useState(false);

  const navigate = useNavigate()
 
  useEffect(()=>{
    getData()
  },[])

  async function getData(){
    const response = await GetAllBlogBannerService()
    if(response?.status == 200){
      setdatas(response?.data?.data)
    }
  }


  async function addData(){
    navigate('create')
  }


  async function deleteData(){
    const response = await DeleteBlogBannerService(selectedData._id)
    if(response.status === 200){
     getData()
     setModal(false)
     toast.success("Banner Deleted")
    }
   }


  return (
    <div className='w-[calc(100%-200px)] p-5'>
         <div className='flex items-center justify-between border-b pb-2'>
        <h6 className='font-[700] text-[14px] '>Total Blog Banner ({datas?.length})</h6>

        <Modal
       keepMounted
       open={modal}
       onClose={()=>setModal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-[11px] leading-normal p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteData()}  /> 
           </div>
         </div>
       </div>
     </Modal>

        <div className='flex items-center'>
          <div className='flex items-center text-[12px] mr-2'>

            

<ButtonFilledAutoWidth btnName="Add Data" onClick={()=>addData()} /> 
          </div>


       
        <div className='mr-2'>
            

           
        </div>      

       
      
        </div>

        </div>
        


        <div className='grid mt-2 grid-cols-5 gap-2'>
          {datas?.map((d)=>(
            <div className='border relative p-1 px-2'>
               {d?.isDeleted && <span className='text-[7px] absolute top-0 right-0 z-0 bg-red-600 font-[800] p-[4px] uppercase text-white'>Deleted</span>}

                <div className='flex flex-wrap w-max border mt-2'>
                   <AiOutlineEdit size={20} onClick={()=>navigate('edit',{state:d})} className='text-slate-700 p-1 border-r' />  
                   <AiOutlineDelete size={20} onClick={()=>{setselectedData(d);setModal(!modal)}} className='text-slate-700 p-1 ' />  
                </div>  


                <img alt={d?.image} src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.image}`} className='w-[100%] border h-[80px] object-contain' />

                <h6 className='text-[10px] bg-slate-100 p-1 mt-1'>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>

            </div>  
          ))}

        </div>
    </div>
  )
}

export default BlogBannerList