import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const GetAllNotificationService = async (page)=>{
    try {
      const result = await get(`api/notification/get?page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

export const CreateNotificationService = async (data)=>{
    try {
      const result = await postfd(`api/notification/create`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }  

export const CreateOldNotificationService = async (data)=>{
  try {
    const result = await postfd(`api/notification/create_old_notification`,data);
    return result;
  } catch (err) {
    return err.response;
  }
}    

export const UpdateNotificationService = async (id,data)=>{
    try {
      const result = await postfd(`api/notification/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }    

export const DeleteNotificationService = async (id,data)=>{
    try {
      const result = await deleteRequest(`api/notification/delete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }   