import React,{useState,useEffect} from 'react'
import { HiOutlineUsers } from "react-icons/hi2";
import { CgGames } from "react-icons/cg";
import { TbPigMoney } from "react-icons/tb";

import { GetDashboardAnalyticService, GetDashboardService } from '../services/DashboardServices';
import Priceconstants from '../constants/imageConstants';
import { GrGamepad } from "react-icons/gr";
import { BsCardChecklist } from "react-icons/bs";
import ReactApexChart from 'react-apexcharts';
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";

function StatisticAppList() {

  const [data,setdata] = useState({})
  const [analytics,setanalytics] = useState({})
  const [series,setseries] = useState([])
  const [series_val,setseries_val] = useState({})
  const [series1,setseries1] = useState([])
  const [series_val1,setseries_val1] = useState({})

  const [category_bar,setcategory_bar] = useState({
    series: [],

    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        
      },
    },
    dataLabels: {
      enabled: false
    },
    grid:{
      show: true,
      borderColor: '#fafafa',
    },
  
    stroke: {
      // show: true,
      width: 1,
      
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: 'Bidding vs Winning '
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          console.log("val",val)
          return  val
        }
      }
    },
    fill:['#027cd1']
  })

  const chartOptions = {
    plotOptions: {
      color:['#000','#000'],
      size:'40%',
      radialBar: {
          inverseOrder: false,
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          hollow: {
              margin: 5,
              size: '50%',
              color: '#f2f2f2',
              image: undefined,
              imageWidth: 150,
              imageHeight: 150,
              imageOffsetX: 0,
              imageOffsetY: 0,
              imageClipped: true,
              position: 'front',
             
          },
          track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: '#f2f2f2',
              strokeWidth: '50%',
              opacity: 1,
              margin: 5, 
              dropShadow: {
                  enabled: false,
                  top: 0,
                  left: 0,
                  opacity: 1
              }
          },
          dataLabels: {
              show: true,
              name: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: undefined,
                  fontWeight: 400,
                  color: undefined,
                  color:'#000',
                },
                value: {
                  show: true,
                  fontSize: '14px',
                  fontFamily: undefined,
                  fontWeight: 900,
                  color: undefined,
                  offsetY: 1,
                  formatter: function (val) {
                    return val + '%'
                  }
                },
                
          },
          
      },
    },
    labels: ['Winning / Bidding',],
    fill:{
      colors: ['#008ef2'],
    }
  }


  useEffect(()=>{
    getData()
    getAnalytics()
  },[])

  // console.log("analytics",analytics)

  async function getData(){
    const response = await GetDashboardService()
    setdata(response?.data?.data)
  }

  async function getAnalytics(){
    const response = await GetDashboardAnalyticService()
    let d = response?.data?.data

    console.log("d here okkk",d)

    let arr = []
    let arr1 = []
    let bidding_amount =  d?.bidding_amount?.length >  0  ? (d?.bidding_amount[0]?.bidding_amount !== undefined ? d?.bidding_amount[0]?.bidding_amount : 0) : 0
    let winning_amount =  d?.bidding_amount?.length >  0  ? (d?.bidding_amount[0]?.winning_amount !== undefined ? d?.bidding_amount[0]?.winning_amount : 0) : 0
    let bidding_total_amount =  ((d?.bidding_amount[0]?.bidding_amount !== undefined ? d?.bidding_amount[0]?.bidding_amount : 0) + (d?.bidding_amount[0]?.winning_amount !== undefined ? d?.bidding_amount[0]?.winning_amount : 0))

    let today_bidding_amount =  d?.today_bidding_info?.length >  0  ? (d?.today_bidding_info[0]?.bidding_amount !== undefined ? d?.today_bidding_info[0]?.bidding_amount : 0) : 0
    let today_winning_amount =  d?.today_bidding_info?.length >  0  ? (d?.today_bidding_info[0]?.winning_amount !== undefined ? d?.today_bidding_info[0]?.winning_amount : 0) : 0
    let today_bidding_total_amount =  d?.today_bidding_info?.length > 0 ? ((d?.today_bidding_info[0]?.bidding_amount !== undefined ? d?.today_bidding_info[0]?.bidding_amount : 0) + (d?.today_bidding_info[0]?.winning_amount !== undefined ? d?.today_bidding_info[0]?.winning_amount : 0)) : 0


    let category_list = []
    let series_list = []
    let series_list1 = []

    // name: 'Bidding Amount',
    // name: 'Winning Amount',

    // console.log("d?.bidding_result",d)

    d?.bidding_result?.forEach((r)=>{
      category_list.push(r?._id?.todayGame?.game?.name)
      series_list?.push(r?.bidding_amount)
      series_list1?.push(r?.winning_amount)
    })

    // console.log("series_list",series_list)
    // console.log("series_list1",series_list1)


    setcategory_bar({
      ...category_bar,
      series:[
       
            {
            name:'Bidding',
            data:series_list
            }
            ,
            {
              name:'Winning',
              data:series_list1
            }
      
      ],
      xaxis:{
        categories : category_list
      }  
    })


    

    arr = [ Math.round(100-(bidding_amount/bidding_total_amount)*100)]
    arr1 = [ Math.round(100-(isNaN(today_bidding_amount/today_bidding_total_amount) ? 0 : today_bidding_amount/today_bidding_total_amount)*100)]

    // console.log("arr1",today_bidding_amount/today_bidding_total_amount)
    setseries(arr)
    setseries_val({bidding_amount,winning_amount,bidding_total_amount})
    setseries1(arr1)
    setseries_val1({today_bidding_amount,today_winning_amount,today_bidding_total_amount})
    setanalytics(response?.data?.data)
  }


  // console.log("CategoryBar",category_bar.series)
 
  return (
    <div className='w-[calc(100%-200px)] p-5'>
        <div className='flex items-center justify-between border-b pb-2'>
        <h6 className='font-[700] text-[14px] '>Overall Analytics</h6>
        </div>

        <div className='grid grid-cols-7  mt-4'>

            <div className='py-2 border p-2'>
                <div className='flex items-start '>
                <CgGames size={20} className='bg-slate-200 p-[4px]'  />
                <div className='ml-2'>
                    <h6 className='text-[12px] font-[500]'>Total Deposit</h6>
                </div>
                </div>
                <h6 className='font-[600] text-[12px] mt-2'>{Priceconstants(data?.wallettransaction_deposit)} /  <span className='text-[8px] font-[400]'>Profit Amount : <span className='font-[800]'>{Priceconstants(data?.wallettransaction_deposit/100)}</span></span></h6>

            </div>

            <div  className='py-2 border-t border-r border-b p-2'>
                <div className='flex items-start '>
                <CgGames size={20} className='bg-slate-200 p-[4px]'  />
                <div className='ml-2'>
                    <h6 className='text-[13px] font-[400]'>Today Deposit</h6>
                </div>
                </div>
                <h6 className='font-[600] text-[12px] mt-2'>{Priceconstants(data?.wallettransaction_today_deposit)} / <span className='text-[8px] font-[400]'>Profit Amount : <span className='font-[800]'>{Priceconstants(data?.wallettransaction_today_deposit/100)}</span></span> </h6>

            </div>

            <div className='py-2 border-t border-r border-b  p-2'>
                <div className='flex items-start '>
                <TbPigMoney size={20} className='bg-slate-200 p-[4px]' />
                <div className='ml-2'>
                    <h6 className='text-[13px] font-[400]'>Today Withdraw</h6>
                </div>
                </div>

                <h6 className='font-[600] text-[12px] mt-2'>{Priceconstants(data?.wallettransaction_today_withdrawl)}</h6>

            </div>

            <div className='py-2 border-t border-r border-b p-2'>
                <div className='flex items-start '>
                <CgGames size={20} className='bg-slate-200 p-[4px]'  />
                <div className='ml-2'>
                    <h6 className='text-[13px] font-[400]'>Today Bidding</h6>
                </div>
                </div>
                <h6 className='font-[600] text-[12px] mt-2'>{Priceconstants(data?.today_bidding)}</h6>
            </div>

            <div className='py-2 border-t border-r border-b p-2'>
                 <div className='flex items-start '>
                <TbPigMoney size={20} className='bg-slate-200 p-[4px]'  />
                <div className='ml-2'>
                    <h6 className='text-[13px] font-[400]'>Total Bidding</h6>
                </div>
                </div>
                <h6 className='font-[600] text-[12px] mt-2'>{Priceconstants(data?.total_bidding)}</h6>

            </div>

            <div className='py-2 border-t border-r border-b p-2'>
                <div className='flex items-start '>
                <GrGamepad size={20} className='bg-slate-200 p-[4px]'  />
                <div className='ml-2'>
                    <h6 className='text-[13px] font-[400]'>Games Completed</h6>
                </div>
                </div>
                <h6 className='font-[600] text-[12px] mt-2'>{data?.total_game}</h6>

            </div>

            <div className='py-2 border-t border-r border-b p-2'>
                 <div className='flex items-start '>
                <BsCardChecklist size={20} className='bg-slate-200 p-[4px]'  />
                <div className='ml-2'>
                    <h6 className='text-[13px] font-[400]'>Today Bidding No</h6>
                </div>
                </div>
                <h6 className='font-[600] text-[12px] mt-2'>{data?.total_bidding_no}</h6>

            </div>

        </div>

        <div className='flex w-full'>
           <div className={`border w-[30%] mr-2 h-[200px]  mt-2 border-slate-100 px-3 py-2 relative`}>
                <h1 className='text-[12px] font-[700]'>Total Bidding vs Winning</h1>
                <div className='flex items-center -ml-10 -mt-4 '>
                <ReactApexChart options={chartOptions} size={'10'} series={series} type="radialBar" width={240} height={240} />
                {console.log("series",series,"chartOptions",chartOptions)}  
              
                <div className='-ml-4 -mt-14'>
                  <h6 className='text-[11px] flex items-center'><h6 className='w-[8px] h-[8px] bg-red-400 mr-2'></h6> Bidding Amount</h6>
                  <h6 className='text-[11px] font-[600]'>{Priceconstants(series_val?.bidding_amount)}</h6>

                  <h6 className='text-[11px] flex items-center mt-2'><h6 className='w-[8px] h-[8px] bg-sky-400 mr-2'></h6> Winning Amount</h6>
                  <h6 className='text-[11px] font-[600]'>{Priceconstants(series_val?.winning_amount)}</h6>
                </div>
                </div>
           </div>

           <div className={`border w-[30%] relative mr-2 h-[200px]  mt-2 border-slate-100 px-3 py-2 relative`}>
                <h1 className='text-[12px] font-[700]'>Today Bidding vs Winning</h1>

                <span className='bg-sky-100 w-[150px] top-[4px] right-[4px] absolute p-1 text-[10px]'>The total active user bid for today is <span className='font-[800]'>{analytics?.active_bidding_user?.length > 0 ? analytics?.active_bidding_user[0]?.count : 0}</span></span>
                <div className='flex items-center -ml-10 -mt-4 '>
                {console.log("series1",series1,"chartOptions",chartOptions)}  
                <ReactApexChart options={chartOptions} size={'10'} series={series1} type="radialBar" width={240} height={240} />
                <div className='-ml-4 -mt-14'>
                  <h6 className='text-[11px] flex items-center'><h6 className='w-[8px] h-[8px] bg-red-400 mr-2'></h6> Bidding Amount</h6>
                  <h6 className='text-[11px] font-[600]'>{Priceconstants(series_val1?.today_bidding_amount)}</h6>

                  <h6 className='text-[11px] flex items-center mt-2'><h6 className='w-[8px] h-[8px] bg-sky-400 mr-2'></h6> Winning Amount</h6>
                  <h6 className='text-[11px] font-[600]'>{Priceconstants(series_val1?.today_winning_amount)}</h6>
                </div>
                </div>
           </div>

           <div className={`border w-[40%] h-[200px]  mt-2 border-slate-100 px-3 py-2 relative`}>
                <h1 className='text-[12px] font-[700]'>Profit Ratio</h1>
                <div className='mt-2 flex border-t border-b py-2 border-slate-100 items-center justify-between ml-2'>
                  <div className='border-r  w-[33%]'>
                  <h6 className='text-[13px] font-[600]'>{Priceconstants(series_val?.bidding_amount)}</h6>
                  <h6 className='text-[11px] flex items-center'><h6 className='w-[8px] h-[8px] bg-red-400 mr-2'></h6> Total Bididng Amount</h6>
                  </div>
                  <div className='border-r px-1 w-[33%]'>
                  <h6  className='text-[13px] font-[600]'>{Priceconstants(series_val?.winning_amount)}</h6>
                  <h6 className='text-[11px] flex items-center'><h6 className='w-[8px] h-[8px] bg-sky-400 mr-2'></h6>Total Winning Amount</h6>
                  </div>
                  <div className='w-[33%]  px-1'>
                  <h6  className='text-[13px] font-[600]'>{Priceconstants(analytics?.bidding_amount?.length > 0 ? analytics?.bidding_amount[0]?.count : 0)}</h6>
                  <h6 className='text-[11px] flex items-center'><h6 className='w-[8px] h-[8px] bg-green-400 mr-2'></h6>Total Bids Placed</h6>
                  </div>
                </div>

                <div className='flex border-b border-slate-100 justify-between py-2'>
                <div className='pb-2 border-slate-100 w-[50%] border-r'>
                <h6 className='text-[11px] mt-2 px-1s flex items-center'>Total Deposit Amount Using Coupon</h6>
                <h6  className='text-[11px] font-[700]'>{Priceconstants(data?.wallettransaction_deposit_using_coupon?.length > 0 ? data?.wallettransaction_deposit_using_coupon[0]?.amount : 0) + ' / ' + Priceconstants(data?.wallettransaction_deposit_using_coupon?.length > 0  ? data?.wallettransaction_deposit_using_coupon[0]?.offer_amount : 0)}</h6>
                </div>

                <div className='pb-2 border-slate-100'>
                <h6 className='text-[11px] mt-2 px-1s flex items-center'>Today Deposit Amount Using Coupon</h6>
                <h6  className='text-[11px] font-[700]'>{Priceconstants(data?.wallettransaction_today_deposit_using_coupon !== undefined ? data?.wallettransaction_today_deposit_using_coupon[0]?.amount : 0 ) + ' / ' +  Priceconstants(data?.wallettransaction_today_deposit_using_coupon !== undefined ? data?.wallettransaction_today_deposit_using_coupon[0]?.offer_amount : 0)}</h6>
                </div>
                </div>

                {/* <div className='mt-7 border-b pb-2  border-slate-100 flex justify-between'>
                  <div className='flex w-[33%]'>
                  <FaArrowAltCircleUp size={24} className='text-red-400' />
                  <div className='ml-2'>
                    <h5 className='text-[10px] font-[900]'>+   100 %</h5>
                    <h6 className='text-[7px]'>Over All Report</h6>
                  </div>
                  </div>  

                   <div className='flex w-[33%]'>
                  <FaArrowAltCircleDown size={24} className='text-sky-400' />
                  <div className='ml-2'>
                    <h5 className='text-[10px] font-[900]'>+   45 %</h5>
                    <h6 className='text-[7px]'>Over All Report</h6>
                  </div>
                  </div>  

                   <div className='flex w-[33%]'>
                  <FaArrowAltCircleUp size={24} className='text-green-400' />
                  <div className='ml-2'>
                    <h5 className='text-[10px] font-[900]'>+   80 %</h5>
                    <h6 className='text-[7px]'>Over All Report</h6>
                  </div>
                  </div>   
                </div> */}
           </div>
        </div>  

        <div className='flex min-w-[100%] max-w-[100%]  border mt-2 p-2 border-slate-100 w-full'>
        <h1 className='text-[12px] font-[700]'>Overall Game Report</h1>
        <div className='mt-4 -ml-28'>
          {/* {JSON.stringify(category_bar?.series)} */}
        {category_bar?.series !== undefined && category_bar!== undefined &&
        <ReactApexChart options={category_bar} width="100%" size={'10'} series={category_bar?.series} type="bar" height={240}  />
        }
        </div>
        </div>   


    </div>
  )
}

export default StatisticAppList