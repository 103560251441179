import { ERROR, LOG_IN, LOG_OUT } from "../actionTypes/authActions";
import { FULL_SCREEN, GALLERY_STATE, NOTES } from "../actionTypes/staticActions";

const initialState = {
    message:"",
    search:{},
    page:null,
    fullScreen:false
}


export default function StaticReducer(state = initialState,action){
    const payload = action.payload;
    // console.log("payload",payload)
    switch (action.type) {
        case NOTES:
            return{...state,message:{...payload}}
        case GALLERY_STATE:
            return{...state,search:payload.search,page:payload.page}    
        case FULL_SCREEN:
            return{...state,fullScreen:payload}    
        default:
            return state
    }
}