import React,{useEffect, useState} from 'react'
import { ButtonOutlinedAutoWidth } from '../../components/button'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip } from '@mui/material';
import { GetAllPaymentTrackService } from '../../services/PaymentTrackServices';
import {DatePicker} from 'antd'
import moment from 'moment';
import Priceconstants from '../../constants/imageConstants';
import { useNavigate } from 'react-router-dom';


function PaymentTrack() {

  const navigate = useNavigate()  
  const [page,setpage] = useState(1)  
  const [datas,setdatas] = useState({pagination:{limit:0}})  
  const [search,setsearch] = useState({search_text:'',from_date:'',to_date:'',from_date1:'',to_date1:''}) 
   
  const [step,setstep] = useState(1) 

  useEffect(()=>{
    getData()
  },[step,page])

  async function getData(){
     const response = await GetAllPaymentTrackService(page,step,search?.from_date1,search?.to_date1,search?.search_text)
     setdatas(response?.data?.data)
     console.log("response?.data",response?.data)
  }

  async function resetfilterfunction(){
    setpage(1)
    setsearch({search_text:'',from_date:'',to_date:'',from_date1:'',to_date1:''})
    const response = await GetAllPaymentTrackService(1,step,'','','')
    setdatas(response?.data?.data)
  }

  async function applyfilterfunction(){
    setpage(1)
    const response = await GetAllPaymentTrackService(1,step,search?.from_date1,search?.to_date1,search?.search_text)
    setdatas(response?.data?.data)
  }

  return (
    <div className='w-[calc(100%-200px)] p-5'>
        <div className='flex items-center justify-between border-b pb-2'>
        <h6 className='font-[700] text-[14px] '>Total Payment Track ({datas?.pagination?.total})</h6>
        <div className='flex items-center'>
          <div className='flex items-center text-[11px] mr-2'>

            <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.data?.length > 0 ? 1 : 0 : (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
            <IconButton onClick={resetfilterfunction}>
            <BsArrowRepeat size={16} />
            </IconButton>
            <div className='flex'>
               <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}>
                <FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>
                <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}>
                <FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.data?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>

            </div>

            <DatePicker size='small'  ampm={false} placeholder='From Date' className='text-[10px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

            <DatePicker size='small'  ampm={false} placeholder='To Date' className='text-[10px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 


            <div className='mr-2'>
            

                <input  type="number" id="search_text" placeholder='Search text' value={search.search_text} onChange={(e)=>setsearch({...search,search_text:e.target.value})} className='border text-[11px] py-[5px] focus:ring-0 focus:outline-0   w-28 px-2 rounded-md border-slate-300' />

                </div>      

            <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
          </div>


       
       
       
      
        </div>

        </div>


        <div className='flex border-b'>
          <h6 onClick={()=>{setstep(1);setpage(1)}} className={`${step == 1 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>In Process</h6>
          <h6 onClick={()=>{setstep(2);setpage(1)}} className={`${step == 2 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[160px] text-[12px]`}>Amount Added / Pending</h6>
          <h6 onClick={()=>{setstep(3);setpage(1)}} className={`${step == 3 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Caputed</h6>
          <h6 onClick={()=>{setstep(4);setpage(1)}} className={`${step == 4 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Failed</h6>
        </div>


        <div className='max-h-[80vh] mt-4 border-t   border-l border-r overflow-y-scroll no-scrollbar'>
        <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
            <h6 className='top-0 bg-white z-50 text-[11px] w-20  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
            <h6 className='sticky top-0 z-50  text-[11px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>User Info</h6>
            <h6 className='sticky top-0 z-50  text-[11px] w-[110px]  px-2 py-1 font-[600] text-slate-600 border-r'>Amount</h6>
            <h6 className='sticky top-0 z-50  text-[11px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Offer Info</h6>
            <h6 className='sticky top-0 z-50  text-[11px] w-[200px]  px-2 py-1 font-[600] text-slate-600 border-r'>Order Id</h6>
            <h6 className='sticky top-0 z-50  text-[11px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Transaction Id</h6>
            <h6 className='sticky top-0 z-50  text-[11px] w-[120px] border-r px-2 py-1 font-[600] text-slate-600'>Payment Status</h6>
            <h6 className='sticky top-0 z-50  text-[11px] w-[120px] border-r px-2 py-1 font-[600] text-slate-600'>Amount Added</h6>
            <h6 className='sticky top-0 z-50  text-[11px] w-[120px] border-r px-2 py-1 font-[600] text-slate-600'>Bank Verify</h6>
            <h6 className='sticky top-0 z-50  text-[11px] w-[170px]  px-2 py-1 font-[600] text-slate-600'>Transaction Record</h6>
            {/* <h6 className='sticky top-0 z-50  text-[11px] w-[140px]  px-2 py-1 font-[600] text-slate-600'>Action</h6> */}
        </div>

       {datas?.data?.map((d,i)=>(
       <div className='z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
        <h6 className='bg-white z-50 font-[500] text-[11px] w-20  px-2 py-2 border-r flex  justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* datas?.pagination?.limit)}</h6>
        <h6 className='z-50 font-[700]  text-[11px] w-[150px]  px-2 py-1  border-r break-all'>+91 {d?.mobile} <span className='font-[400]'>{(d?.name !== null && d?.name !== undefined && d?.name !== '') && `/ ${d?.name}`}</span> </h6>
        <h6 className='z-50 font-[800]  text-[11px] w-[110px]  px-2 py-1  border-r break-all'>{Priceconstants(d?.amount)}</h6>
        <h6 className='z-50 font-[500]  text-[11px] w-[150px]  px-2 py-1  border-r break-all'>{d?.appOffer?._id === undefined ? d?.appOffer?.offerName : 'No Offer Applied'}</h6>
        <h6 className='z-50 font-[500]  text-[11px] w-[200px]  px-2 py-1  border-r break-all'>{d?.order_id}
        <br></br>
        <span className='text-[10px] font-[400]'>Created At :{moment(d?.createdAt)?.format('LLL')}</span>
        </h6>
        <h6 className='z-50 font-[700]  text-[10px] w-[170px]  px-2 py-1  border-r break-all'>{d?.transaction_id} <br></br> <span className='text-[10px] font-[400]'>T_ID :{d?.tid}</span>  </h6>
        <h6 className='z-50 font-[500]  text-[10px] w-[120px]  px-2 py-1  border-r break-all capitalize'>{d?.paymentStatus?.replace('-', ' ')}</h6>
        <h6 className='z-50 font-[500]  text-[10px] w-[120px]  px-2 py-1  border-r break-all'>{d?.amounted_added ? <span className='text-[7px]  z-0  bg-slate-600 font-[800] p-[4px] uppercase text-white'>Amount Credited</span> : <span className='text-[7px]  z-0  bg-slate-600 font-[800] p-[4px] uppercase text-white'>pENDING</span>}</h6>
        <h6 className='z-50 font-[500]  text-[10px] w-[120px]  px-2 py-1  border-r break-all'>{d?.bank_verified ? <span className='text-[7px]  z-0  bg-slate-600 font-[800] p-[4px] uppercase text-white'>Bank Verified</span> : <span className='text-[7px]  z-0  bg-slate-600 font-[800] p-[4px] uppercase text-white'>pENDING</span>}</h6>
        <h6 className='z-50 font-[500]  text-[10px] w-[170px]  break-all px-2 py-1 '>{d?.transaction_record?._id !== undefined ?  'Transaction Record Exist': 'No Transaction Record Exist'}</h6>
        {/* <h6 className='z-50 font-[500]  text-[11px] w-[140px]  px-2 py-1 '>
        <div className='flex max-w-[140px]  '>
                   <p className='text-[10px] z-0 cursor-pointer h-fit mr-1  bg-slate-700 font-[500] p-[2px] px-[4px] w-max text-white border rounded'>Captured</p>
                   <p className='text-[10px] z-0 cursor-pointer h-fit  bg-slate-200 font-[500] p-[2px] px-[4px] w-max text-black border rounded'>Failed</p>
                  
                </div>  
        </h6> */}
       </div>))}



       </div>
    </div>
  )
}

export default PaymentTrack