import React,{useEffect, useState} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import { Select } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import GoBack from '../../components/back/GoBack'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { CreateAdminWalletTransactionService } from '../../services/WalletServices'
import toast from 'react-hot-toast'
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from "react-icons/md";


function UserListWalletTransactionCE() {

  const navigate = useNavigate()  

  const {state} = useLocation()
  const [transaction,settransaction] = useState({amount:'',type:'Deposit',description:''})
  const [step,setstep] = useState(1)
  const [type,settype] = useState([])

  useEffect(()=>{
      if(step == 1){
        settype([
          // {
          //   label:'Deposit',
          //   val:'Deposit'
          // },
          // {
          //   label:'Bonus',
          //   val:'Bonus'
          // }
          {
            label:'Deposit',
            val:'Add'
          },
          {
            label:'Withdraw',
            val:'Deduct'
          }
        ])
        settransaction({...transaction,type:'Deposit'})
      }else if(step == 2){
        settype([
          // {
          //   label:'Admin Deducted',
          //   val:'Admin Deducted'
          // },
          {
            label:'Deposit',
            val:'Add'
          },
          {
            label:'Withdraw',
            val:'Deduct'
          }
        ])
        settransaction({...transaction,type:'Admin Deducted'})

      }
  },[step])
 

  async function createtransactionfunction(){
    if(!transaction?.amount){
       toast.error("Transaction amount field is required *")
    }else{
    let sendData = {
        ...transaction,
        user:state?._id,
        step:step

    }
    const response = await CreateAdminWalletTransactionService(sendData)
      if(response?.status === 200){
          toast.success("Transaction Created successfully")
          navigate(-1)
      }else{
        toast.error(response?.data?.message)
      }
    } 
  }

  async function handlechange(e){
    settransaction({...transaction,[e.target.name]:e.target.value})
  }

  return (
    <div className='ml-4 mt-4 w-[280px]'>
        <div>
           <GoBack /> 
         

           <h6 className='font-[700]'>Add Wallet Amount</h6>
           <h6 className='text-[10px] leading-[14px] p-2 bg-slate-100 mt-1'>Use the below form to add a amount o wallet and select option to create a wallet transaction.</h6>

           <TextInput
             label={'Amount'} 
             name="amount"
             type="number"
             min={1}
             value={transaction?.amount}
             handlechange={handlechange}
           />

           <TextAreaInput1 
            label={'Description'} 
            name="description"
            type="text"
            min={1}
            value={transaction?.description}
            handlechange={handlechange}
           
           />

            <h6 className='text-[11px] mt-2 font-[700]'>Select Type *</h6>

            <div className='mt-2'>
                {type?.map((t)=><span onClick={()=>{settransaction({...transaction,type:t?.label})}} className={`bg-slate-200 cursor-pointer p-1.5 text-[8px] font-[500] ${transaction?.type === t?.label && 'bg-slate-700 text-white'} uppercase mr-2 mb-2`}>{t.val}</span>)}
            </div>

            
            <div className='flex mt-2 items-cnter'>
              <div onClick={()=>setstep(1)} className='text-[11px] cursor-pointer mr-4 flex items-center mt-2'>
                {step !== 1 ?
                <MdOutlineRadioButtonUnchecked className='text-gray-100' size={16} />:
                <MdOutlineRadioButtonChecked className='text-slate-600' size={16} />}
                <span className='ml-1 font-[700]'>Deposit Wallet</span>
              </div>

              <div onClick={()=>setstep(2)} className='text-[11px] cursor-pointer flex items-center mt-2'>
                {step !== 2 ?
                <MdOutlineRadioButtonUnchecked className='text-gray-100' size={16} />:
                <MdOutlineRadioButtonChecked className='text-slate-600' size={16} />}
                <span className='ml-1 font-[700]'>Winning Wallet</span>
              </div>
            </div>
  
           
           <div className='pt-2 mt-4 border-t flex justify-end'>
           <ButtonOutlinedAutoWidth onClick={()=>navigate(-1)} btnName="Cancel" style="mr-2" />
           <ButtonFilledAutoWidth onClick={createtransactionfunction} btnName="Save" />
           </div>
        </div>
    </div>
  )
}

export default UserListWalletTransactionCE