import React, { useState,useEffect } from 'react'
import {ButtonFilled} from '../../components/button'
import { useNavigate } from 'react-router-dom';
import { DeleteGameDefaultService, GetGameDefaultService } from '../../services/GameDefaultServices';
import moment from 'moment';
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import { Modal } from 'antd';
import toast from 'react-hot-toast';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { DeleteRatioService, GetAllRatioService } from '../../services/RatioServices';

function RatioList() {
  
  const [datas,setdatas] = useState([])  
  const [modal, setModal] = useState(false);
  const [selectedData,setselectedData] = useState({})  
  
  const navigate = useNavigate()

  useEffect(()=>{
    getData()
  },[])

  async function getData(){
    const response = await GetAllRatioService()
    if(response?.status == 200){
      setdatas(response?.data?.data)
    }
  }

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  async function deleteData(){
    const response = await DeleteRatioService(selectedData._id)
    if(response.status === 200){
     getData()
     setModal(false)
     toast.success("Ratio Deleted")
    }
   }




  return (
    <div className='w-[calc(100%-200px)] p-5'>
        <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Ratio List ({datas?.length})</h6>
            <div className='flex items-center'>
            
        
            <div className='ml-2 mr-0'>
            <ButtonFilled btnName="Add Data" onClick={()=>navigate('create')} /> 
            </div>
        
            </div>

        </div>

        <Modal
       keepMounted
       open={modal}
       onClose={()=>setModal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-[11px] leading-normal p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteData()}  /> 
           </div>
         </div>
       </div>
     </Modal>

        <div className='grid mt-2 grid-cols-5 gap-2'>
          {datas?.map((d)=>(
            <div className='border relative'>
                <div className='absolute border-l border-b  right-0 top-0 bg-white '>
                    <div className='flex'>
                    <AiOutlineEdit size={17} onClick={()=>navigate('edit',{state:d})} className='p-[2px] cursor-pointer border-r' />
                    <AiOutlineDelete size={17}  onClick={()=>{setselectedData(d);setModal(!modal)}} className='p-[2px] cursor-pointer' />
                   </div>
                 </div>  
               <div className='text-[12px]'>
                <h6 className='w-[100%] text-[10px] font-[400] px-1 mt-1'>Name : <span className='font-[700]'>{d?.name}</span></h6>
                <h6 className='w-[100%] text-[10px] font-[400] px-1'>Amount : <span className='font-[700]'>{d?.amount}</span></h6>
                <h6 className='w-[100%] text-[10px] font-[400] px-1'>Sort : <span className='font-[700]'>{d?.sort}</span></h6>
               </div> 

               <h6 className='text-[11px] mx-2 mb-2 bg-slate-100 p-1'>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>
               
            </div>    
          ))}
        </div>
       
    </div>
  )
}

export default RatioList