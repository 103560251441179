import React, { useEffect, useState } from 'react'
import { FilterAllWithdrawTransactionService, FilterWithdrawTransactionByUserIdService, GetAllWithdrawTransactionService, GetWithdrawTransactionByUserIdService } from '../../services/WithdrawServices'
import { useLocation, useNavigate } from 'react-router-dom'
import Priceconstants from '../../constants/imageConstants'
import moment from 'moment'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material';
import { AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai'
import { Modal } from 'antd'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import toast from 'react-hot-toast'
import { DeleteBiddingServices, GetAllBiddingServices } from '../../services/BiddingServices'


function BiddingList() {

  const [data,setdata] = useState([]) 
  const [page,setpage] = useState(1)  

  const [selectedData,setselectedData] = useState({})
  const [modal,setmodal] = useState(false)


  const {state} = useLocation()
  const navigate = useNavigate()
  const [search,setsearch] = useState({text:'',date:'',date1:'',type:''})

 

  useEffect(()=>{
    getData()
  },[page])


  async function getData(){
    const response = await GetAllBiddingServices(page)
    setdata(response?.data?.data?.data)
  }


  async function filterData(v){
    const response = await FilterAllWithdrawTransactionService((v !== undefined && v !== null) ? v : page,search?.text,search?.date,search?.type)
    setdata(response?.data?.data)
  }

  async function deleteData(){
     const response = await DeleteBiddingServices(selectedData?._id)
     console.log("response?.data",response?.data)
     if(response?.status === 200){
      setmodal(false)
      toast.success("Deleted successfully")
      getData()
     }
  }

  async function resetfilterfunction(){
    setsearch({text:'',date:'',date1:'',type:''})
    setpage(1)
    const response = await GetWithdrawTransactionByUserIdService(state,page)
    setdata(response?.data?.data)
  }

  function formatTime(timeString) {
    // console.log("timeString",timeString)
    if(timeString !== undefined){
      const [hourString, minute] = timeString?.split(":");
      const hour = +hourString % 24;
      return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
    }
  }
    
  return (
    <div className='w-[calc(100%-200px)] p-5'>
        <Modal width={300} open={modal} footer={false} closable={false}>
          <div>
            <h6 className='text-[14px] font-[700]'>Delete data</h6>
            <h6 className='text-[11px]'>Are you sure want to delete the selected record of type .once deleted will not be retrieved back. </h6>
           
           
           <div className='border-t pt-2 mt-2 flex justify-end items-center'>
           <ButtonOutlinedAutoWidth onClick={()=>setmodal(false)} style='mr-2' btnName={'Cancel'} />
           <ButtonFilledAutoWidth onClick={deleteData} btnName={'Save'} />
           </div>
          </div>
        </Modal>

        
        <div className='flex border-b pb-1 items-center justify-between'>
        <h6 className='text-[13px] font-[700]'>Total Bidding Record ({data?.pagination?.total})</h6>
             
            <div className='flex items-center'>
            <h6 className='mr-2 text-[11px] font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
            <IconButton onClick={resetfilterfunction}>
            <BsArrowRepeat size={16} />
            </IconButton>
            <div className='flex'>
               <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}>
                <FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>
                <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}>
                <FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>
            </div>

          
            {/* <Select bordered={false} value={stages?.find((s)=>s?.label == search?.type)} onChange={(e)=>setsearch({...search,type:e})} className='border h-[26px] w-[100px] rounded-[4px]' options={stages} />
             <input type='number' value={search?.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='focus:outline-none text-[11px] p-1 px-[10px] rounded border ml-2 w-[100px]' />
              <span onClick={()=>filterData(1)} className='ml-2 cursor-pointer'>
                <AiOutlineFilter size={24} className='bg-slate-100 rounded text-salte-700 p-1' />
              </span> */}
            </div> 
        </div>

        <div className='max-h-[85vh] no-scrollbar mt-4 border-t   border-l border-r overflow-y-scroll'>
        <div className='sticky top-0 z-50 flex  bg-gray-100 py-[-5px] border-b border-slate-200 relative'>
        <h6 className='top-0 bg-gray-100 z-50 text-[11px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Bidding Type</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[110px]  px-2 py-1 font-[600] text-slate-600 border-r'>Amount</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[110px]  px-2 py-1 font-[600] text-slate-600 border-r'>Game Name</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[110px]  px-2 py-1 font-[600] text-slate-600 border-r'>Game Type</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[90px]  px-2 py-1 font-[600] text-slate-600 border-r'>Number</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[110px]  px-2 py-1 font-[600] text-slate-600 border-r'>Result Status</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Winning Amount</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[110px] border-r px-2 py-1 font-[600] text-slate-600'>Created At</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[140px] border-r px-2 py-1 font-[600] text-slate-600'>Game Result</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[140px] border-r px-2 py-1 font-[600] text-slate-600'>Open Bid Time</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[140px] border-r px-2 py-1 font-[600] text-slate-600'>Close Bid Time</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[70px]  px-2 py-1 font-[600] text-slate-600'>Action</h6>
       </div>


        {data?.datas?.map((d,i)=>(
        <div  className='relative flex  bg-white border-b'>
        <h6 className='bg-white text-[11px] w-[60px]  px-2 py-1 font-[600] border-r text-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* data?.pagination?.limit)}</h6>
        <h6 className='text-[10px] w-[120px]  px-2 py-1 font-[600] border-r'><span className='font-[400] text-[8px] px-2 py-[4px] uppercase bg-slate-100'>{d?.biddingType}</span> </h6>
        <h6 className='text-[10px] w-[110px]  px-2 py-1 font-[600] border-r'>{Priceconstants(d?.amount)}</h6>
        <h6 className='text-[10px] w-[110px]  px-2 py-1 font-[600] border-r'>{d?.gameName}</h6>
        <h6 className='text-[10px] w-[110px]  px-2 py-1 font-[600] border-r'>{d?.gameType}</h6>
        <h6 className='text-[10px] w-[90px]  px-2 py-1  border-r'>{JSON.stringify(d?.number)}</h6>
        <h6 className='text-[10px] w-[110px]  px-2 py-1 font-[600] border-r'><span className={`text-[8px] font-[500] p-1 rounded ${d?.result === 'loss' && 'text-red-800 bg-red-100'} ${d?.result === 'pending' && 'text-yellow-800 bg-yellow-100'} ${d?.result === 'win' && 'text-green-800 bg-green-100'} ${d?.result === 'game cancelled' && 'text-sky-800 bg-sky-100'}   uppercase`} >{d?.result}</span></h6>
        <h6 className='text-[10px] w-[130px]  px-2 py-1 font-[600] border-r'><span className={`text-[10px] font-[800] p-1 uppercase`} >{Priceconstants(d?.winning_amount)}</span></h6>
        <h6 className='text-[10px] w-[110px] border-r px-2 py-1 font-[600]'>{moment(d?.createdAt)?.format('lll')}</h6>
        <h6 className='text-[10px] w-[140px] border-r px-2 py-1 font-[600]'>{d?.todayGame?.open_result !== '' && d?.todayGame?.open_result?.slice(0,3) +"-"+d?.todayGame?.open_result?.slice(3)} {d?.todayGame?.closed_result !== '' && d?.todayGame?.closed_result?.slice(0,1)+"-"+d?.todayGame?.closed_result?.slice(1)}</h6>
        <h6 className='text-[10px] w-[140px] border-r px-2 py-1 font-[600]'>{formatTime(d?.todayGame?.openBiddingTime)}</h6>
        <h6 className='text-[10px] w-[140px] border-r px-2 py-1 font-[600]'>{formatTime(d?.todayGame?.closeBiddingTime)}</h6>
        <h6 className='text-[10px] flex w-[70px]  px-2 py-1 font-[600]'>
            <AiOutlineEdit size={11} onClick={()=>navigate('edit',{state:d})} className='mr-2' />
            <AiOutlineDelete onClick={()=>{setselectedData(d);setmodal(true)}} size={11} />
        </h6>
        </div>
        ))}
        </div>

        
    </div>
  )
}

export default BiddingList