import React,{useEffect, useState} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import GoBack from '../../components/back/GoBack';
import { useNavigate, useLocation } from 'react-router-dom'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import Uploader from '../../components/Uploader';
import {BiErrorCircle} from 'react-icons/bi'
import { CreateNotificationService, CreateOldNotificationService, UpdateNotificationService } from '../../services/NotificationServices';

function NotificationCE() {
   
   const [data,setdata] = useState({image:'',title:'',description:'',date:''});  
   const [loader,setloader] = useState(false);  
   const [error,seterror] = useState({image:'',title:'',description:'',date:''}); 

   const {state,pathname} = useLocation();
  
   const path = pathname.split('/')[pathname.split('/').length - 1]
   const navigate = useNavigate();

    
 

  useEffect(()=>{
       if(path === 'edit'){
        setdata({...data,...state})
       }
  },[state])

  async function submitform(){
    if(!data?.image){
        seterror({...error,image:'This Field is required*'})
    }else if(!data?.title){
        seterror({...error,title:'This Field is required*'})
    }else if(!data?.description){
        seterror({...error,description:'This Field is required*'})
    }else{
        console.log("11")
       if(path === 'create'){
           setloader(true)
           const fd = new FormData()
           fd.append('image',data?.image)
           fd.append('title',data?.title)
           fd.append('description',data?.description)

           if(data?.date !== ''){
            fd.append('createdAt',data?.date)
            const response  = await CreateOldNotificationService(fd)
            if(response?.status === 201){
                setloader(false)
                setdata({image:'',title:'',description:'',date:''})
                seterror({image:'',title:'',description:'',date:''})
                toast.success("Notification Created for Old Date")
            }
           }else{
            const response  = await CreateNotificationService(fd)
            if(response?.status === 201){
                setloader(false)
                setdata({image:'',title:'',description:'',date:''})
                seterror({image:'',title:'',description:'',date:''})
                toast.success("Notification Created")
            }
           }
       }else{
        setloader(true)
        const fd = new FormData()
        fd.append('image',data?.image)
        fd.append('title',data?.title)
        fd.append('description',data?.description)

        if(data?.date !== ''){
            fd.append('createdAt',data?.date)
        }
        const response  = await UpdateNotificationService(state._id,fd)
        if(response?.status === 200){
            setloader(false)
            setdata({image:'',title:'',description:'',date:''})
            seterror({image:'',title:'',description:'',date:''})
            toast.success("Notification Updated")
            navigate(-1)
           }
       }
    }

  }


  function handlechange(e){
     setdata({...data,[e.target.name] : e.target.value})
     seterror({...error,[e.target.name]:''})
  }

//   console.log("error",error)

  return (
    <div className='w-[300px] px-5 mt-5'>

        <GoBack />

            <h6 className='font-bold text-[14px]'>Add / Update Notification</h6>
        <h6 className='text-[10px] mt-2 bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Notification</b> for your game user to access this notification.</h6>
     

        <h6 className='text-[11px] font-[600] mb-1 mt-1' >Select Image</h6>
       
        <Uploader image={data?.image}  setimagefunc={(e)=>{setdata({...data,image:e});seterror({...error,image:''})}}  removeimageuploadfunc = {()=>{setdata({...data,image:''});seterror({...error,image:''})}} />
        {error?.image !== '' && error?.image !== undefined &&
        <div className='flex items-center mt-1'>
        <BiErrorCircle className='text-red-500' size={14} />
        <span className='text-[10px] text-red-500 ml-1'>{error?.image}</span>
        </div>}

        <TextInput
                    label={'Title'}  
                    variant="standard"
                    mandatory={true}
                    name="title"
                    type="text"
                    error={error.title}
                    value={data.title}
                    handlechange={handlechange}
                    placeholder="Enter  name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />

                <TextAreaInput1
                    label={'Description'}  
                    variant="standard"
                    mandatory={true}
                    name="description"
                    type="text"
                    error={error.description}
                    value={data.description}
                    handlechange={handlechange}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />
            {path === 'create' &&
                <TextInput
                    label={'Date'}  
                    variant="standard"
                    mandatory={true}
                    name="date"
                    type="date"
                    error={error.date}
                    value={data.date}
                    handlechange={handlechange}
                    placeholder="Enter  name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />}


        {!loader ? 
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} onClick={submitform} />  
        </div>
        :
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} />  
        </div>}

    </div>
  )
}

export default NotificationCE