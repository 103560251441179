import React, { useEffect, useState } from 'react'
import { BiSolidCheckboxChecked } from "react-icons/bi";
import { ButtonFilledAutoWidth } from '../components/button';
import { CreateAppStateServices, GetAllAppStateServices, UpdateAppStateServices } from '../services/AppStateServices';
import toast from 'react-hot-toast';
import { TextInput } from '../components/input';

function ManageAppList() {

  const [step,setstep] = useState('')
  const [data,setdata] = useState({state:'',buildVersion:'',appVersion:'',playStoreExist:false,playstoreurl:'',websiteurl:''})

  useEffect(()=>{
    getData()
  },[])


  async function getData(){
    const response = await GetAllAppStateServices()
    if(response?.data?.data === null){
      setstep('1')
    }else{
      if(response?.data?.data?.state === 'Matka'){
        setstep('1')
      }else{
        setstep('2')
      }
      setdata(response?.data?.data)
    }
  }

  async function appstatefunction(){

    let sendData = {
      state:step === '1' ? 'Matka' : 'Blog',
      buildVersion:data?.buildVersion,
      appVersion:data?.appVersion,
      playStoreExist:data?.playStoreExist,
      playstoreurl:data?.playstoreurl,
      websiteurl:data?.websiteurl
    }

    if(data?._id !== undefined){
      const response = await UpdateAppStateServices(data?._id,sendData)
      if(response?.status === 200){
        getData()
        toast.success("App State Updated")
      }
    }else{
      const response = await CreateAppStateServices(sendData)
      if(response?.status === 201){
        getData()
        toast.success("App State Created")
      }
    }
  }

  async function handlechange(e){
    setdata({...data,[e.target.name]:e.target.value})
  }

  return (
    <div className='w-[calc(100%-200px)] p-5'>
        <div className='flex items-center justify-between border-b pb-2'>
          <h6 className='font-[700] text-[14px] '>Manage App</h6>

         
        </div>

         
        <h6 className='text-[11px] mt-5'>App Name : <span className='font-[700]'> Kuber 567</span></h6>
        <h6 className='text-[12px] mt-1'>App Category : <span className='font-[700]'> Matka App</span></h6>
        <h6 className='text-[12px] mt-1'>App Version : <span className='font-[700]'> 1.00.00.001</span></h6>


        <div className='flex mt-5 mb-5'>

          <h6 onClick={()=>setstep('1')} className='flex cursor-pointer items-center mr-4'>
            <BiSolidCheckboxChecked className={step === '1' ? 'text-slate-700' : `text-slate-200`} size={25} />
            <h6 className='ml-2 text-[12px] font-[700]'>Matka</h6>
          </h6>

          <h6 onClick={()=>setstep('2')} className='flex cursor-pointer items-center'>
            <BiSolidCheckboxChecked className={step === '2' ? 'text-slate-700' : `text-slate-200`}  size={25} />
            <h6 className='ml-2 text-[12px] font-[700]'>Blogs</h6>
          </h6>
        </div>


        <div className='w-60 mb-4'>

        <TextInput 
          label={'Build Version'}
          name="buildVersion"
          value={data?.buildVersion}
          handlechange={handlechange}
         />

        <TextInput 
          label={'App Version'}
          name="appVersion"
          value={data?.appVersion}
          handlechange={handlechange}
         />

        <h6 onClick={()=>setdata({...data,playStoreExist:!data.playStoreExist})} className='flex mt-2 cursor-pointer items-center'>
            <BiSolidCheckboxChecked className={data?.playStoreExist ? 'text-slate-700' : `text-slate-200`}  size={25} />
            <h6 className='ml-2 text-[12px] font-[700]'>Play Store Exist</h6>
          </h6>

        <TextInput 
          label={'Play Store URL'}
          name="playstoreurl"
          value={data?.playstoreurl}
          handlechange={handlechange}
         />

<TextInput 
          label={'Web Site URL'}
          name="websiteurl"
          value={data?.websiteurl}
          handlechange={handlechange}
         />
        </div>

        <ButtonFilledAutoWidth onClick={appstatefunction}  btnName="Update App Status"/>
    </div>
  )
}

export default ManageAppList