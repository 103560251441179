import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const GetGameDefaultService = async ()=>{
    try {
      const result = await get(`api/game_default/get`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const CreateGameDefaultService = async (data)=>{
    try {
      const result = await post(`api/game_default/create`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const UpdateGameDefaultService = async (id,data)=>{
    try {
      const result = await put(`api/game_default/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const DeleteGameDefaultService = async (id)=>{
    try {
      const result = await deleteRequest(`api/game_default/delete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const GetGameRunForDateService = async (date)=>{
    try {
      const result = await get(`api/game/get_today_game_service_based_on_date?date=${date}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }