import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const GetAllBlogService = async (page)=>{
    try {
      const result = await get(`api/blog/get?page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

export const GetAllBlogBasedCategoryService = async (id,page)=>{
    try {
      const result = await get(`api/blog/get_blogs_category/${id}?page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }  

export const CreateBlogService = async (data)=>{
    try {
      const result = await postfd(`api/blog/create`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }  

export const UpdateBlogService = async (id,data)=>{
    try {
      const result = await postfd(`api/blog/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }    

export const DeleteBlogService = async (id,data)=>{
    try {
      const result = await deleteRequest(`api/blog/delete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }   