import { deleteRequest, get, post, put } from "../helpers/apihelpers";

export const GetAllGameService = async ()=>{
    try {
      const result = await get(`api/game/get`);
      return result;
    } catch (err) {
      return err.response;
    }
  }


  export const CreateGameService = async (data)=>{
    try {
      const result = await post(`api/game/create`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const CreateGameTodayService = async (data)=>{
    try {
      const result = await post(`api/game/create_today_game`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const UpdateGameTodayTimingService = async (id,data)=>{
    try {
      const result = await put(`api/game/update_today_game_timing/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const UpdateGameTodayService = async (id,data)=>{
    try {
      const result = await put(`api/game/update_today_game/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const UpdateGameTodayRollBackService = async (id,data)=>{
    try {
      const result = await put(`api/game/update_today_game_rollback/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const UpdateGameService = async (id,data)=>{
    try {
      const result = await put(`api/game/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const GetTodayGamePlayService = async (step,page)=>{
    try {
      const result = await get(`api/game/get_today_game?step=${step}&page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const GetGameBiddingAmountBasedonIdService = async (id)=>{
    try {
      const result = await get(`api/game/get_bidding_amount_based_game_id/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const GetTodayGameAdminService = async (id)=>{
    try {
      const result = await get(`api/game/get_today_game_service`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const GetTodayGamePlayDetailService = async (id)=>{
    try {
      const result = await get(`api/game/get_today_game_detail/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const CompleteTodayGameService = async (id,data)=>{
    try {
      const result = await post(`api/game/close_today_game_service/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const CloseGamePlayService = async (id)=>{
    try {
      const result = await put(`api/game/cancel_today_game/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }