import { deleteRequest, get, put, } from "../helpers/apihelpers";

export const GetAllBiddingServices = async (page,step)=>{
    try {
      const result = await get(`api/bidding/get_all_bidding_admin?page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const DeleteBiddingServices = async (id)=>{
    try {
      const result = await deleteRequest(`api/bidding/delete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  } 

  export const UpdateBiddingServices = async (id,data)=>{
    try {
      const result = await put(`api/bidding/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  } 
