import React, { useEffect, useState } from 'react'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { GetAllUserService } from '../../services/UserServices';
import { IconButton, Tooltip } from '@mui/material';
import { AiOutlineEdit,AiOutlineDelete,AiOutlineWallet } from 'react-icons/ai';
import { RiFolderShieldLine } from "react-icons/ri";
import moment from 'moment';
import {DatePicker,Drawer, Modal, Select} from 'antd';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { TextInput, TextInput1 } from '../../components/input';
import { GetWalletByIdService } from '../../services/WalletServices';
import { DeleteActiveState, updateUserRole } from '../../services/AuthServices';
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import Priceconstants from '../../constants/imageConstants';
import UserWalletTransaction from '../wallet/UserWalletTransaction';
import { TbMoneybag } from "react-icons/tb";
import toast from 'react-hot-toast';

function AddFund() {
  
  const [datas,setdatas] = useState({pagination:{limit:0}})  
  const [page,setpage] = useState(1)  
  const [search,setsearch] = useState({search_text:'',from_date:'',to_date:'',from_date1:'',to_date1:''}) 
  const [step,setstep] = useState(1) 

  const [roles,setroles] = useState([])


  const [selectedUser,setselectedUser] = useState({})
  const [wallet,setwallet] = useState({})
  const [modal,setmodal] = useState(false)
  const [modal1,setmodal1] = useState(false)
  const [deletemodal,setdeletemodal] = useState(false)


  const type = [
    {
      label:'Deposit',
      val:'Deposit'
    },
    {
      label:'Bonus',
      val:'Bonus'
    }
  ]

  const navigate = useNavigate()
 
  useEffect(()=>{
    getData()
  },[page,step])

  useEffect(()=>{
     if(selectedUser?._id !== undefined){
      getWalletInfo()
     }
  },[selectedUser])

  async function getData(){
    const response = await GetAllUserService(step === 1 ? 1 : 0,page,search?.search_text,search?.from_date1,search?.to_date1)
    if(response?.status == 200){
      setdatas(response?.data?.data?.data)
    }
  }

  async function applyfilterfunction(){
    setpage(1)
    const response = await GetAllUserService(step === 1 ? 1 : 0,1,search?.search_text,search?.from_date1,search?.to_date1)
    if(response?.status == 200){
      setdatas(response?.data?.data?.data)
    }
  }

  async function resetfilterfunction(){
    setpage(1)
    setsearch({search_text:'',from_date:'',to_date:'',from_date1:'',to_date1:''})
    const response = await GetAllUserService(step === 1 ? 1 : 0,1,'','','')
    if(response?.status == 200){
      setdatas(response?.data?.data?.data)
    }
  }

 

  async function getWalletInfo(){
    const response = await GetWalletByIdService(selectedUser?._id)
    setwallet(response?.data?.data)
  }


  async function deactivateUser(){
    let sendData = {
         isDeleted:selectedUser?.isDeleted
    }
    const response = await DeleteActiveState(selectedUser?._id,sendData)
    setdeletemodal(false)
    setselectedUser({})
    getData()
  }

  async function addrole(r){
    if(roles?.includes(r)){
      setroles(roles?.filter((r1)=>r1 == r))
    }else{
      setroles([...roles,r])
    }
  }

  async function assignRoleAdmin(){
    const response = await updateUserRole(selectedUser?._id,roles)
    if(response?.status === 200){
      toast.success("Roles Updated Successfully")
      setmodal1(!modal1)
    }
  }




  return (
    <div className='w-[calc(100%-200px)] p-5'>
      <Modal open={deletemodal} closable={false} footer={false} width={300}>
        <h6 className='font-[700]'>Delete Status</h6>
        <h6 className='font-[400] text-[11px] bg-slate-100 p-1 leading-tight'>Use the below form to update the active status of the user by clicking on the below link.</h6>

        <div className='flex items-center my-2' onClick={()=>setselectedUser({...selectedUser,isDeleted:!selectedUser.isDeleted})}>      
        {!selectedUser?.isDeleted ? <BiCheckbox className='text-slate-300' size={20} /> :
        <BiCheckboxSquare className='text-slate-700' size={20}/> }
        <h6 className='text-[11px]'>Deleted Status</h6>
        </div> 

        <div className='flex justify-end border-t  pt-2'>
          <div className='mr-2'>
          <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setdeletemodal(false)} />
          </div>
          <ButtonFilledAutoWidth btnName="Save" onClick={deactivateUser} />
        </div>
      </Modal>


      <Modal open={modal1} closable={false} footer={false} width={300}>
      <h6 className='font-[700]'>Give Access</h6>
      <h6 className='font-[400] text-[11px] bg-slate-100 p-1 leading-tight'>Use the below form to provide roles for the selected user.</h6>
      
      <div className='flex mt-2 flex-wrap'>
      {['Admin','User']?.map((r)=>(
            <span onClick={()=>addrole(r)} className={`text-[11px] px-4 ${roles?.includes(r) && 'bg-slate-700 text-white'} cursor-pointer font-[600] p-1 bg-slate-100  mr-2`}>{r}</span>
      ))}
      </div>


      <div className='flex border-t pt-2 mt-2 flex items-center justify-end'>
        <ButtonOutlinedAutoWidth btnName="Cancel" style={'mr-2'} onClick={()=>setmodal1(!modal1)} />
        <ButtonFilledAutoWidth btnName="Save" onClick={()=>assignRoleAdmin()} />

      </div>

      </Modal>
       
     

        <Drawer open={modal} width={300} closable={false}>
            <h1 className='text-[15px] font-[700]'>Wallet Detail</h1>
            <h1 className='text-[11px] leading-tight bg-slate-100 text-slate-700 p-2 mt-2'>With the below you can get the detail of wallet and winned amount of the user!</h1>

            <TextInput
              label="Account Holder No"
              readOnly={true}
              value={selectedUser?.phoneNumber}
             />


             <div className='flex'>
              <div className='mr-2 w-[100%]'>
              <TextInput
              label="Deposit Amount"
              readOnly={true}
              type="number"
              value={wallet?.amount}
             />
              </div>

              <div className='w-[100%]'>
              <TextInput
              label="Earned Amount"
              readOnly={true}
              type="number"
              value={wallet?.earnedAmount}

             />
              </div>
             </div>

             <div className='mt-4'>
               <ButtonOutlinedAutoWidth onClick={()=>setmodal(false)} style={'mr-2'} btnName="Close" />
               <ButtonFilledAutoWidth  onClick={()=>{setmodal(false);navigate('wallet_transaction_ce',{state:selectedUser})}} btnName="Add Wallet Transaction" />
             </div>
            
             {/* <div className='flex  mt-4 justify-end'>
             <div className='mr-2'>
                <ButtonOutlinedAutoWidth onClick={()=>setmodal(false)} btnName="Close" />
              </div>

              <div>

                <ButtonFilledAutoWidth btnName="Save" />
              </div>
             </div>   */}

            {/* <UserWalletTransaction /> */}

        </Drawer>
         <div className='flex items-center justify-between border-b pb-2'>
        <h6 className='font-[700] text-[14px] '>Total Users ({datas?.pagination?.total})</h6>
        <div className='flex items-center'>
          <div className='flex items-center text-[11px] mr-2'>

            <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 : (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
            <IconButton onClick={resetfilterfunction}>
            <BsArrowRepeat size={16} />
            </IconButton>
            <div className='flex'>
               <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}>
                <FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>
                <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}>
                <FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>

            </div>

            {/* <DatePicker size='small'  ampm={false} placeholder='From Date' className='text-[10px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

<DatePicker size='small'  ampm={false} placeholder='To Date' className='text-[10px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 
 */}

<div className='mr-2'>
  

<input  id="search_text" placeholder='Search text' type='text' value={search.search_text} onChange={(e)=>setsearch({...search,search_text:e.target.value})} className='border text-[11px] py-[5px] focus:ring-0 focus:outline-0   w-28 px-2 rounded-md border-slate-300' />

</div>      

<ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
          </div>


       
        <div className='mr-2'>
            

           
        </div>      

       
      
        </div>

        </div>
        {/* <div className='flex border-b'>
          <h6 onClick={()=>{setstep(1);setpage(1)}} className={`${step == 1 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Verified</h6>
          <h6 onClick={()=>{setstep(2);setpage(1)}} className={`${step == 2 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Not Verified</h6>
        </div> */}

        
       <div className='max-h-[85vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
       <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
        <h6 className='top-0 bg-white z-50 text-[11px] w-20  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
        <h6 className='sticky top-0 z-50  text-[11px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Name</h6>
        <h6 className='sticky top-0 z-50  text-[11px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Phone No</h6>
        <h6 className='sticky top-0 z-50  text-[11px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Referral Amount</h6>
        <h6 className='sticky top-0 z-50  text-[11px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Deposit Wallet</h6>
        <h6 className='sticky top-0 z-50  text-[11px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Winning Wallet</h6>
        <h6 className='sticky top-0 z-50  text-[11px] w-[100px] border-r px-2 py-1 font-[600] text-slate-600'>Status</h6>
        <h6 className='sticky top-0 z-50  text-[11px] w-[170px]  px-2 py-1 font-[600] text-slate-600'>Action</h6>
       </div>

       {datas?.datas?.map((d,i)=>(
       <div className='z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
        <h6 className='bg-white z-50 font-[500] text-[11px] w-20  px-2  border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* datas?.pagination?.limit)}</h6>
        <h6 className='z-50 font-[700]  text-[11px] w-[170px]  px-2 py-1  border-r'>{d?.name}</h6>
        <h6 className='z-50 font-[700]  text-[11px] w-[170px]  px-2 py-1  border-r'>+91 {d?.phoneNumber}</h6>
        <h6 className='z-50 font-[500]  text-[11px] w-[170px]  px-2 py-1  border-r'>{Priceconstants(d?.refer_amount)}</h6>
        <h6 className='z-50 font-[500]  text-[11px] w-[170px]  px-2 py-1  border-r'>{Priceconstants(d?.wallet?.amount)}</h6>
        <h6 className='z-50 font-[500]  text-[11px] w-[170px]  px-2 py-1  border-r'>{Priceconstants(d?.wallet?.earnedAmount)}</h6>
        <h6 className='z-50 font-[500]  text-[10px] w-[100px]  px-2 py-1  border-r'>{d?.isDeleted && <span className='text-[7px]  z-0 bg-red-600 font-[800] p-[4px] uppercase text-white'>Deleted</span>} {!d?.isDeleted && <span className='text-[7px] z-0 bg-slate-600 font-[800] p-[4px] uppercase text-white'>Active</span>}</h6>
        <h6 className='z-50 font-[500]  text-[11px] w-[170px]  px-2 py-1 '>
        <div className='flex flex-wrap w-max'>
                 <span onClick={()=>{navigate('/users/wallet_transaction_ce',{state:d})}} className='bg-slate-100 cursor-pointer p-1 text-[10px]'>Add Fund</span>
                </div>  
        </h6>
       </div>))}



       </div>

        {/* <div className='grid mt-2 grid-cols-5 gap-2'>
          {datas?.datas?.map((d)=>(
            <div className='border relative p-1 px-2'>
               {d?.isDeleted && <span className='text-[7px] absolute top-0 right-0 z-0 bg-red-600 font-[800] p-[4px] uppercase text-white'>Deleted</span>}
                <h1 className='text-[11px] text-slate-700 mt-4 z-10'>PhoneNumber : <span className='font-[600] text-black'>+91 {d?.phoneNumber}</span></h1>
                <h1 className='text-[11px] mt-1 text-slate-700 '>ReferralId : <span className='font-[600] text-black bg-slate-100 p-1.5 uppercase text-[10px] rounded-[2px]'>{d?.referralId}</span></h1>

                <div className='flex flex-wrap w-max border mt-2'>
                   <AiOutlineEdit onClick={()=>navigate('edit',{state:d})} size={20} className='text-slate-700 p-1 border-r' />  
                   <AiOutlineDelete onClick={()=>{setselectedUser(d);setdeletemodal(true)}} size={20} className='text-slate-700 p-1 border-r' />  
                   <AiOutlineWallet onClick={()=>{setselectedUser(d);setmodal(true)}} size={20} className='text-slate-700 p-1 ' />  
                </div>  

                <h6 className='text-[10px] bg-slate-100 p-1 mt-1'>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>

            </div>  
          ))}

        </div> */}
    </div>
  )
}

export default AddFund