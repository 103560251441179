import React,{useEffect, useState} from 'react'
import { TextInput } from '../../components/input'
import GoBack from '../../components/back/GoBack';
import { useNavigate, useLocation } from 'react-router-dom'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import { CreatePromotionalTextService, UpdatePromotionalTextService } from '../../services/PromotionalTextServices';
import Uploader from '../../components/Uploader';
import {BiErrorCircle} from 'react-icons/bi'
import { CreateBannerService } from '../../services/BannerServices';
import { CreateBlogBannerService, UpdateBlogBannerService } from '../../services/BlogBannerServices';

function BlogBannerCE() {
   
   const [data,setdata] = useState({image:''});  
   const [loader,setloader] = useState(false);  
   const [error,seterror] = useState({image:''}); 

   const {state,pathname} = useLocation();
  
   const path = pathname.split('/')[pathname.split('/').length - 1]
   const navigate = useNavigate();

    
 

  useEffect(()=>{
       if(path === 'edit'){
        setdata({...data,image:state?.image})
       }
  },[state])

  async function submitform(){
    if(!data?.image){
        seterror({...error,image:'This Field is required*'})
    }else{
       if(path === 'create'){
           setloader(true)
           const fd = new FormData()
           fd.append('image',data?.image)
           const response  = await CreateBlogBannerService(fd)
           if(response?.status === 201){
            setloader(false)
            setdata({name:''})
            seterror({name:''})
            toast.success("Blog Banner Created")
           }
       }else{
        setloader(true)
        const response  = await UpdateBlogBannerService(state._id,data)
        if(response?.status === 200){
            setloader(false)
            setdata({name:''})
            seterror({name:''})
            toast.success("Blog Banner Updated")
            navigate(-1)
           }
       }
    }

  }

//   console.log("error",error)

  return (
    <div className='w-[300px] px-5 mt-5'>

        <GoBack />

            <h6 className='font-bold text-[14px]'>Add / Update Banner</h6>
        <h6 className='text-[10px] mt-2 bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Banner</b> for your game employees to access this poster.</h6>
     

        <h6 className='text-[11px] font-[600] mb-1 mt-1' >Select Banner</h6>
       
        <Uploader image={data?.image}  setimagefunc={(e)=>{setdata({...data,image:e});seterror({...error,image:''})}}  removeimageuploadfunc = {()=>{setdata({...data,image:''});seterror({...error,image:''})}} />
        {error?.image !== '' && error?.image !== undefined &&
        <div className='flex items-center mt-1'>
        <BiErrorCircle className='text-red-500' size={14} />
        <span className='text-[10px] text-red-500 ml-1'>{error?.image}</span>
        </div>}

        {!loader ? 
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} onClick={submitform} />  
        </div>
        :
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} />  
        </div>}

    </div>
  )
}

export default BlogBannerCE