import { deleteRequest, get, post, put, } from "../helpers/apihelpers";

export const GetAllAppStateServices = async ()=>{
    try {
      const result = await get(`api/appstate/get`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const CreateAppStateServices = async (data)=>{
    try {
      const result = await post(`api/appstate/create`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  } 


  export const UpdateAppStateServices = async (id,data)=>{
    try {
      const result = await put(`api/appstate/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  } 

  export const DeleteAppStateServices = async (id)=>{
    try {
      const result = await deleteRequest(`api/appstate/delete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  } 


