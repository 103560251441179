import React, { useEffect, useState } from 'react'
import { FilterWithdrawTransactionByUserIdService, GetWithdrawTransactionByUserIdService } from '../../services/WithdrawServices'
import { useLocation, useNavigate } from 'react-router-dom'
import Priceconstants from '../../constants/imageConstants'
import moment from 'moment'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { Icon, IconButton } from '@mui/material';
import { AiOutlineEdit,AiOutlineDelete, AiOutlineFilter, AiOutlineReload } from 'react-icons/ai'
import { Modal,Select } from 'antd'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { DeleteWalletTransactionByIdService } from '../../services/WalletServices'
import toast from 'react-hot-toast'
function UserWalletTransaction() {

  const [data,setdata] = useState([]) 
  const [page,setpage] = useState(1)  

  const [selectedData,setselectedData] = useState({})
  const [modal,setmodal] = useState(false)


  const {state} = useLocation()
  const navigate = useNavigate()
  const [search,setsearch] = useState({text:'',date:'',date1:'',type:''})

  const stages = [
    {
      label:'Winning',
      value:'Winning'
    },
    {
      label:'Deposit',
      value:'Deposit'
    },
    {
      label:'Withdrawl',
      value:'Withdrawl'
    },
    {
      label:'Refer',
      value:'Refer'
    },
    {
      label:'GamePlay',
      value:'GamePlay'
    },
    {
      label:'Bonus',
      value:'Bonus'
    },
  ]

  useEffect(()=>{
    getData()
  },[page])


  async function getData(){
    const response = await GetWithdrawTransactionByUserIdService(state,page)
    setdata(response?.data?.data)
  }

  async function filterData(v){
    const response = await FilterWithdrawTransactionByUserIdService(state,(v !== undefined && v !== null) ? v : page,search?.text,search?.date,search?.type)
    setdata(response?.data?.data)
  }

  async function deleteData(){
     const response = await DeleteWalletTransactionByIdService(selectedData?._id)
     if(response?.status === 200){
      setmodal(false)
      toast.success("Deleted successfully")
      getData()
     }
  }

  async function resetfilterfunction(){
    setsearch({text:'',date:'',date1:'',type:''})
    setpage(1)
    const response = await GetWithdrawTransactionByUserIdService(state,page)
    setdata(response?.data?.data)
  }

    
  console.log("data",data) 
  return (
    <div className='w-[calc(100%-200px)] p-5'>
        <Modal width={300} open={modal} footer={false} closable={false}>
          <div>
            <h6 className='text-[14px] font-[700]'>Delete data</h6>
            <h6 className='text-[11px]'>Are you sure want to delete the selected record of type .once deleted will not be retrieved back. </h6>
           
           
           <div className='border-t pt-2 mt-2 flex justify-end items-center'>
           <ButtonOutlinedAutoWidth onClick={()=>setmodal(false)} style='mr-2' btnName={'Cancel'} />
           <ButtonFilledAutoWidth onClick={deleteData} btnName={'Save'} />
           </div>
          </div>
        </Modal>

        
        <div className='flex border-b pb-1 items-center justify-between'>
        <h6 className='text-[13px] font-[700]'>Total Transaction ({data?.pagination?.total})</h6>
             
            <div className='flex items-center'>
            <h6 className='mr-2 text-[11px] font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
            <IconButton onClick={resetfilterfunction}>
            <BsArrowRepeat size={16} />
            </IconButton>
            <div className='flex'>
               <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}>
                <FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>
                <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}>
                <FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>
            </div>

          
            <Select bordered={false} value={stages?.find((s)=>s?.label == search?.type)} onChange={(e)=>setsearch({...search,type:e})} className='border h-[26px] w-[100px] rounded-[4px]' options={stages} />
             <input type='number' value={search?.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='focus:outline-none text-[11px] p-1 px-[10px] rounded border ml-2 w-[100px]' />
              <span onClick={()=>filterData(1)} className='ml-2 cursor-pointer'>
                <AiOutlineFilter size={24} className='bg-slate-100 rounded text-salte-700 p-1' />
              </span>
            </div> 
        </div>

        <div className='max-h-[85vh] no-scrollbar mt-4 border-t   border-l border-r overflow-y-scroll'>
        <div className='sticky top-0 z-50 flex  bg-gray-100 py-[-5px] border-b border-slate-200 relative'>
        <h6 className='top-0 bg-gray-100 z-50 text-[11px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Transaction Type</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[110px]  px-2 py-1 font-[600] text-slate-600 border-r'>Amount</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[110px]  px-2 py-1 font-[600] text-slate-600 border-r'>Opening_Balance</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[400px]  px-2 py-1 font-[600] text-slate-600 border-r'>Remarks</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Status</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[150px] border-r px-2 py-1 font-[600] text-slate-600'>Created At</h6>
        <h6 className='sticky bg-gray-100 top-0 z-50  text-[11px] w-[70px]  px-2 py-1 font-[600] text-slate-600'>Action</h6>
       </div>


        {data?.datas?.map((d,i)=>(
        <div  className='relative flex  bg-white border-b'>
        <h6 className='bg-white text-[11px] w-[60px]  px-2 py-1 font-[600] border-r text-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* data?.pagination?.limit)}</h6>
        <h6 className='text-[10px] w-[150px]  px-2 py-1 font-[600] border-r'>{d?.typeOfTransaction}</h6>
        <h6 className='text-[10px] w-[110px]  px-2 py-1 font-[600] border-r'>{Priceconstants(d?.amount)}</h6>
        <h6 className='text-[10px] w-[110px]  px-2 py-1 font-[600] border-r'>{Priceconstants(d?.totalAmount)}</h6>
        <h6 className='text-[10px] w-[400px]  px-2 py-1  border-r'>
          {((d?.typeOfTransaction == 'Deposit' || d?.typeOfTransaction == 'Bonus') && d?.paymenttrackRecord?.transaction_id !== undefined) && <span className='font-[700] text-[8px]'>Transaction ID : {d?.paymenttrackRecord?.transaction_id}</span>}
          {d?.description}
        </h6>
        <h6 className='text-[10px] w-[170px]  px-2 py-1 font-[600] border-r'><sapn className={`text-[8px] font-[500] p-1 rounded ${d?.status === 'Rejected' && 'text-red-800 bg-red-100'} ${d?.status === 'Pending' && 'text-yellow-800 bg-yellow-100'} ${d?.status === 'Completed' && 'text-green-800 bg-green-100'}  uppercase`} >{d?.status}</sapn></h6>
        <h6 className='text-[10px] w-[150px] border-r px-2 py-1 font-[600]'>{moment(d?.createdAt)?.format('LLL')}</h6>
        <h6 className='text-[10px] flex w-[70px]  px-2 py-1 font-[600]'>
            <AiOutlineEdit size={11} onClick={()=>navigate('edit',{state:d})} className='mr-2' />
            <AiOutlineDelete onClick={()=>{setselectedData(d);setmodal(true)}} size={11} />
        </h6>
        </div>
        ))}
        </div>

        
    </div>
  )
}

export default UserWalletTransaction