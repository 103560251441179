import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const GetAllRatioService = async ()=>{
    try {
      const result = await get(`api/ratio/get`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

export const CreateRatioService = async (data)=>{
    try {
      const result = await post(`api/ratio/create`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }  

export const UpdateRatioService = async (id,data)=>{
    try {
      const result = await put(`api/ratio/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }  


export const DeleteRatioService = async (id)=>{
    try {
      const result = await deleteRequest(`api/ratio/delete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }