import React,{useEffect, useState} from 'react'



import { TextInput } from '../../components/input'
import { LogInService } from '../../services/AuthServices';
import { useDispatch } from 'react-redux';
import { LogInAction, LogOutAction } from '../../redux/actions/authAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {AiFillEye,AiFillEyeInvisible} from 'react-icons/ai';

function Login() {

    const [data,setdata] = useState({phoneNumber:'',password:''});
    const [error,seterror] = useState({phoneNumber:'',password:''});

    const [stateMsg,setstateMsg] = useState('')
    const [visible,setvisible] = useState(false)

    
    const dispatch = useDispatch();
    const {state} = useLocation();
    const navigate = useNavigate();



    useEffect(()=>{
        if(state !== null){
        setstateMsg(state)
        }
        dispatch(LogOutAction())

    },[])


    function handlechange(e){
        setdata({...data,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name]:''})
    }

    function onKeyDownHandler(e){
       
        if (e.keyCode === 13) {
            submitform();
        }
    }

    async function submitform(){

        if(!data.phoneNumber || data.phoneNumber.length !== 10){
            seterror({...error,phoneNumber:'Enter a valid 10 digit mobile no'})
        }else if(!data.password){
            seterror({...error,password:'Password field is required'})
        }else{
            // console.log("data",data)
            const response = await LogInService(data)
            let d = response?.data
            if(response.status === 200){
                if(d?.data?.roles?.includes('admin') || d?.data?.roles?.includes('Admin')){
                    dispatch(LogInAction(response.data?.data))
                    navigate('/dashboard')
                }else{
                    toast.error("Invalid access ")
                    seterror({...error,password:"Invalid access"})
                }
            }else if(response.status === 400){
                toast.error(d?.message)
                seterror({...error,password:d?.message})
            }
        }
    }

  return (

   

    <div className='flex min-h-screen w-full max-h-screen justify-between'>

  

    <div className='w-[100vw] flex justify-center items-center' >

         <div className='mx-auto'>
            <div className='p-1 w-72'  >  

            <h6 className='text-[18px] font-[800]'>Sign In</h6>      
            <h6 className={`text-[10px] font-[400] leading-normal mb-4 my-2`}>Add your admin credentials which is created for you for the application access.</h6>
            
            <form
             onKeyDown={onKeyDownHandler}
             onSubmit={submitform}
            > 
            <TextInput 
                label={'Phone No'}  
                variant="standard"
                name="phoneNumber"
                type="text"
                // error={error.phoneNumber}
                value={data.phoneNumber}
                handlechange={handlechange}
                placeholder="Enter your FCPL_ID"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


            <div className='relative'>
            <TextInput 
                label={'Password'}  
                variant="standard"
                name="password"
                type={visible ? "text" : "password"}
                // error={error.password}
                value={data.password}
                handlechange={handlechange}
                placeholder="Enter your password"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  
               {visible ?  <AiFillEye className='absolute right-1 top-[28px] cursor-pointer text-slate-200' onClick={()=>setvisible(!visible)} /> : <AiFillEyeInvisible className='absolute right-1 top-[28px] cursor-pointer text-slate-200 ' onClick={()=>setvisible(!visible)} />}
            </div>



                <h6 onClick={submitform} className="bg-slate-600 cursor-pointer text-center mt-4 text-white font-[500] py-[5px] px-4 mt-0  w-full rounded-[4px] border-slate-600  text-[13px] hover:border-slate-600">SIGN IN</h6>
            </form>
           

            <h6 className='text-[10px] mt-4 text-800 leading-normal'>By continuing, you agree to Kuber 567<span className='text-black font-bold text-[#0a69da]'> Conditions of Use</span>  and <span className='text-black font-bold text-[#0a69da]'> Privacy Notice </span> .</h6>


            <h6 onClick={()=>navigate('/forgot_password')}  className="w-full  py-2 px-4 mt-5 rounded text-center  text-[12px] cursor-pointer">Forgot Password</h6>

            </div>
        </div>  
    </div> 

    {/* <Card variant='outlined' sx={{textAlign:'center',padding:2,marginTop:2}} className="border-[#d7dee3] border">
        <h6 className='text-[13px] font-[500]'>New to Fidelitus? <span className='text-[#0a69da] cursor-pointer'> Create an account.</span></h6>
    </Card> */}
    {/* <Copyright sx={{ mt: 8, mb: 4 }} className="center w-full" /> */}
    </div>    


      
  )
}

export default Login