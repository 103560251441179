import React, { useState,useEffect } from 'react'
import { ButtonFilled, ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { useNavigate } from 'react-router-dom'
import { CloseGamePlayService, CompleteTodayGameService, GetTodayGamePlayService, UpdateGameTodayRollBackService, UpdateGameTodayService } from '../../services/GameServices'
import moment from 'moment'
import { AiOutlineEdit } from 'react-icons/ai'
import { MdOutlineRollerShadesClosed } from 'react-icons/md'
import { Modal } from 'antd'
import toast from 'react-hot-toast'
import { GrGamepad } from "react-icons/gr";
import { RiTimeLine } from "react-icons/ri";
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip } from '@mui/material';

function GamesPlayList() {
  
  const [datas,setdatas] = useState({length:0})
  const navigate = useNavigate()
  const [page,setpage] = useState(1)  
  const [step,setstep] = useState(1)  
  const [type,settype] = useState('')  
  const [selectedData,setselectedData] = useState({})  
  const [modal,setmodal] = useState(false)  
  const [modal1,setmodal1] = useState(false)  
  const [modal2,setmodal2] = useState(false)  

  useEffect(()=>{
    getData()
  },[step,page])

  async function getData(){
    const response = await GetTodayGamePlayService(step,page)
    if(response?.status == 200){
      setdatas(response?.data?.data?.data)
    }
  }

  function formatTime(timeString) {
    const [hourString, minute] = timeString?.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  }


  const [result_open,setresult_open] = useState({one:'',two:''})
  const [result_close,setresult_close] = useState({one:'',two:''})


  async function handlechange(e,d){
    setselectedData(d)
    setresult_open({...result_open,[e.target.name]:e.target.value})
  }
  async function handlechange1(e,d){
    setselectedData(d)
    setresult_close({...result_close,[e.target.name]:e.target.value})
  }

  console.log("result_open",result_open)
  console.log("result_close",result_close)

  async function submitform(v){

    if(selectedData?._id !== undefined){ 
      if(v == 1){
        if(selectedData?.open_result === '' && (new Date() > (new Date(`${selectedData?.date?.slice(0,10)}T${selectedData?.openBiddingTime}:00`)))){
            if(!result_open?.one){
              toast.error("Open 1st digit is required")
            }else if(!result_open?.two){
              toast.error("Open 2nd digit is required")
            }else{
              let send_data = {...selectedData} 
              delete send_data.game
              send_data['open_result'] = result_open?.one +  result_open?.two
              send_data['type'] = 'open'
              const response = await UpdateGameTodayService(selectedData?._id,send_data)
              if(response?.status === 200){
                toast.success('Open Result announced')
                getData()
                setresult_open({one:'',two:'',three:'',four:''})
              }
          }
        }else if((!(new Date() > (new Date(`${selectedData?.date?.slice(0,10)}T${selectedData?.openBiddingTime}:00`))))){
          toast.error("Open Bidding Time Not Completed")
        }else if(selectedData?.open_result !== '' ){
          toast.error("Result already announced rollback first to re assign result!")
        }
      }else if(v == 2){
        if(selectedData?.closed_result === '' && (new Date() > (new Date(`${selectedData?.date?.slice(0,10)}T${selectedData?.closeBiddingTime}:00`)))){
          if(!result_close?.one){
            toast.error("Close 1st digit is required")
          }else if(!result_close?.two){
            toast.error("Close 2nd digit is required")
          }else{
            let send_data = {...selectedData}
            delete send_data.game
            send_data['closed_result'] = result_close?.one +  result_close?.two
            send_data['type'] = 'close'
            const response = await UpdateGameTodayService(selectedData?._id,send_data)
            if(response?.status === 200){
              toast.success('Close Result announced')
              getData()
              setresult_close({one:'',two:'',three:'',four:''})
            }
        }
      }else if((!(new Date() > (new Date(`${selectedData?.date?.slice(0,10)}T${selectedData?.closeBiddingTime}:00`))))){
        toast.error("Close Bidding Time Not Completed")
      }else if(selectedData?.open_result !== '' ){
        toast.error("Result already announced rollback first to re assign result!")
      }
      }
    }else{
      toast.error("You have not selected Data to update result")
    }
  }

  async function rollback(){

    let send_data = {
      type:type == '1' ? 'open' : 'close'
    }
    const response = await UpdateGameTodayRollBackService(selectedData?._id,send_data)
    if(response?.status === 200){
      setmodal(!modal)
      setselectedData({})
      getData()
      toast.success(type == '1' ? "Open Roll Back Successful *" : "Close Roll Back Successful *")
    }
  }


  async function completegame(){
    
    const response = await CompleteTodayGameService(selectedData?._id,{completed:!selectedData?.game_completed})
    if(response?.status === 200){
      setmodal2(!modal2)
      setselectedData({})
      getData()
      toast.success("Game Completed Successful *")
    }
  }

  async function closegame(){
    const response = await CloseGamePlayService(selectedData?._id)
    console.log("respone?.data",response?.data)
    if(response?.status === 200){
      setmodal1(!modal1)
      setselectedData({})
      getData()
      toast.success("Game Closed Successful *")
    }
  }

  async function rollbackfunc(v,d){
    if(step == 1){
      if(v == 1){
        setselectedData(d);
        settype(v)
        d?.open_result === '' ? toast.error("Open Result Not announced yet") : setmodal(true)
      }else{
        setselectedData(d);
        settype(v);
        d?.closed_result === '' ? toast.error("Close Result Not announced yet") : setmodal(true)
      }  
    }else{
      toast.error("Can Rollback when game is completed")
    }
  }

  async function resetfilterfunction(){
      setpage(1)
  }

  return (
    <div className='w-[calc(100%-200px)] p-5'>

    <Modal width={300} open={modal} footer={false} closable={false}>
      <div>
        <h6 className='text-[13px] font-[700]'>{type == 1 ? 'Open' : 'Close'} Roll Back</h6>
        <h6 className='text-[11px] p-1 bg-slate-100'>Are you sure want to make roll back for the selected game be sure once rollback will not be updated further</h6>
        <h6 className='text-[11px] pb-2 pt-1'>Game Name : <span className='font-[700]'>{selectedData?.game?.name}</span> </h6>
        <div className='flex items-center border-t py-1 border-b' >
          <div className='w-[50%] border-r'>
            <h6 className='text-[10px] text-center'>Open Bid Time</h6>
            <h6 className='text-center font-[700] text-[12px]'>{formatTime(selectedData?.openBiddingTime + ":00")}</h6>
          </div>
          <div className='w-[50%]'>
            <h6 className='text-[10px] text-center'>Close Bid Time</h6>
            <h6 className='text-center font-[700] text-[12px]'>{formatTime(selectedData?.closeBiddingTime + ":00")}</h6>
          </div>
        </div>

        <div className='flex items-center justify-end border-t pt-2 mt-2'>
          <ButtonOutlinedAutoWidth onClick={()=>setmodal(!modal)} btnName="Cancel" style={'mr-2'}/>
          <ButtonFilledAutoWidth btnName="Save" onClick={rollback} />
        </div>
      </div>
    </Modal>

    <Modal width={300} open={modal1} footer={false} closable={false}>
      <div>
        <h6 className='text-[13px] font-[700]'>Cancel Game</h6>
        <h6 className='text-[11px] p-1 bg-slate-100'>Are you sure want to make close the game and revert back the bidding amount to them.</h6>
        <h6 className='text-[11px] pb-2 pt-1'>Game Name : <span className='font-[700]'>{selectedData?.game?.name}</span> </h6>
        <div className='flex items-center border-t py-1 border-b' >
          <div className='w-[50%] border-r'>
            <h6 className='text-[10px] text-center'>Open Bid Time</h6>
            <h6 className='text-center font-[700] text-[12px]'>{formatTime(selectedData?.openBiddingTime + ":00")}</h6>
          </div>
          <div className='w-[50%]'>
            <h6 className='text-[10px] text-center'>Close Bid Time</h6>
            <h6 className='text-center font-[700] text-[12px]'>{formatTime(selectedData?.closeBiddingTime + ":00")}</h6>
          </div>
        </div>

        <div className='flex items-center justify-end border-t pt-2 mt-2'>
          <ButtonOutlinedAutoWidth onClick={()=>setmodal1(!modal1)} btnName="Cancel" style={'mr-2'}/>
          <ButtonFilledAutoWidth btnName="Save" onClick={closegame} />
        </div>
      </div>
    </Modal> 

    <Modal width={300} open={modal2} footer={false} closable={false}>
      <div>
        <h6 className='text-[13px] font-[700]'>Game Completed</h6>
        <h6 className='text-[11px] p-1 bg-slate-100'>Are you sure want to update the game completed status to be completed!.</h6>
        <h6 className='text-[11px] pb-2 pt-1'>Game Name : <span className='font-[700]'>{selectedData?.game?.name}</span> Which was created for <h6 className='font-[700] text-[10px]'>{moment(selectedData?.date)?.format('LL')}</h6>  </h6>
     

        <div className='flex items-center justify-end border-t pt-2 mt-2'>
          <ButtonOutlinedAutoWidth onClick={()=>setmodal2(!modal2)} btnName="Cancel" style={'mr-2'}/>
          <ButtonFilledAutoWidth btnName="Save" onClick={completegame} />
        </div>
      </div>
    </Modal> 

    <div className='flex items-center justify-between border-b pb-2'>
        <h6 className='font-[700] text-[14px] '>Played Games ({datas?.pagination?.total})</h6>
        <div className='flex items-center'>
    
        {/* <div className='ml-2 mr-0'>
        <ButtonFilled btnName="Add Data" onClick={()=>navigate('create')} /> 
        </div> */}
         <h6 className='mr-2 text-[11px] font-[600]'>{page == 1 ? datas?.data?.length > 0 ? 1 : 0 : (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
            <IconButton onClick={resetfilterfunction}>
            <BsArrowRepeat size={16} />
            </IconButton>
            <div className='flex'>
               <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}>
                <FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>
                <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}>
                <FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.data?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} />
                </IconButton>

            </div>
        
        </div>
    </div>

    <div className='flex border-b'>
          <h6 onClick={()=>{setstep(1);setpage(1)}} className={`${step == 1 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Pending</h6>
          <h6 onClick={()=>{setstep(2);setpage(1)}} className={`${step == 2 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Completed</h6>
        </div>

    <div className='max-h-[85vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
       <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
            <h6 className='top-0 bg-white z-50 text-[11px] min-w-[60px] max-w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
            <h6 className='sticky top-0 z-50  text-[11px] min-w-[130px] max-w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Game Name</h6>
            <h6 className='sticky top-0 z-50  text-[11px] min-w-[100px] max-w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Date</h6>
            <h6 className='sticky top-0 z-50  text-[11px] min-w-[100px] max-w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Open Bid Time</h6>
            <h6 className='sticky top-0 z-50  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Open Result</h6>
            <h6 className='sticky top-0 z-50  text-[11px] min-w-[150px] max-w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Action</h6>
            <h6 className='sticky top-0 z-50  text-[11px] min-w-[100px] max-w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Close Bid Time</h6>
            <h6 className='sticky top-0 z-50  text-[11px] min-w-[120px] max-w-[120px] border-r px-2 py-1 font-[600] text-slate-600'>Close Result</h6>
            <h6 className='sticky top-0 z-50  text-[11px] min-w-[150px] max-w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Action</h6>
            <h6 className='sticky top-0 z-50  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] text-slate-600'>Status / Action</h6>
       </div>
       {datas?.datas?.map((d,i)=>(
       <div className='z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
            <h6 className='top-0 bg-white z-50 text-[11px] min-w-[60px] max-w-[60px]   px-2 py-1 font-[600] border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* datas?.pagination?.limit)}</h6>
            <h6 className='z-50  text-[11px] min-w-[130px] max-w-[130px]  px-2 py-1 font-[600] border-r'>{d?.game?.name}</h6>
            <h6 className='z-50  text-[11px] min-w-[100px] max-w-[100px]  px-2 py-1 font-[600] border-r'>{moment(d?.date)?.format('ll')}</h6>
            <h6 className='z-50  text-[11px] min-w-[100px] max-w-[100px]  px-2 py-1 font-[600] border-r'>{formatTime(d?.openBiddingTime + ":00")}</h6>
            <h6 className='z-50  text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600] border-r'>
            {!d?.game_completed ? 
            <>
                 {d?.open_result !== '' ? 
                      <h6 className='flex'>
                       <p className='w-[60px] h-[20px]  font-[700] text-gray-800 flex items-center justify-center border'>{d?.open_result?.slice(0,3)}</p>
                        <p className='text-gray-200 mx-1'>-</p>
                        <p className='w-[20px] h-[20px]  font-[700] text-gray-800 flex items-center justify-center border'>{d?.open_result[3]}</p>
                      </h6> 
                      :
                      <h6 className='flex'>
                      <input value={selectedData?._id === d?._id ? result_open?.one : ''} name="one" onChange={(e)=>handlechange(e,d)} maxLength={3} className='focus:outline-none text-center border w-[60px] text-[11px] center h-[20px]' />
                      {/* <input value={selectedData?._id === d?._id ? result_open?.two : ''} name="two" onChange={(e)=>handlechange(e,d)} maxLength={1} className='focus:outline-none text-center border-r border-t border-b w-[20px] text-[11px] center h-[20px]' />
                      <input value={selectedData?._id === d?._id ? result_open?.three : ''} name="three" onChange={(e)=>handlechange(e,d)} maxLength={1} className='focus:outline-none text-center border-r border-t border-b w-[20px] text-[11px] center h-[20px]' /> */}
                      <p className='text-gray-200 mx-1'>-</p>
                      <input value={selectedData?._id === d?._id ? result_open?.four : ''} name="two" onChange={(e)=>handlechange(e,d)} maxLength={1} className='focus:outline-none text-center border w-[20px] text-[11px] center h-[20px] mr-2' />
                    </h6>
                    }
            </>
            :   
            <h6 className='flex'>
              <p className='w-[60px] h-[20px]  font-[700] text-gray-800 flex items-center justify-center  border'>{d?.open_result?.slice(0,3)}</p>
              <p className='text-gray-200 mx-1'>-</p>
              <p className='w-[20px] h-[20px]  font-[700] text-gray-800 flex items-center justify-center border'>{d?.open_result[3]}</p>
             </h6> }     
                    </h6>
            <h6 className='z-50  text-[11px] min-w-[150px] max-w-[150px] font-[600] border-r'>
            <h6 className='z-50  text-[11px]  min-w-[170px] max-w-[170px] flex px-2 py-1 font-[600]'>
              <p onClick={()=>submitform(1)} className={`text-[10px]  z-0 cursor-pointer mr-2  bg-slate-600 font-[500] p-[2px] px-[10px] w-fit text-white h-fit rounded ${(d?.open_result !== '' || (new Date().getTime() < new Date(`${d?.date?.slice(0,10)}T${d?.openBiddingTime}:00`)) || d?.game_completed || d?.game_cancelled ) && 'opacity-30'} `}>Save</p>
              <p onClick={()=>rollbackfunc(1,d)} className={`text-[10px]  z-0 cursor-pointer h-fit mr-2  bg-slate-200 font-[500] p-[2px] px-[10px] w-max text-black border rounded ${(d?.open_result === ''  || d?.game_completed || d?.game_cancelled) && 'opacity-30'} `}>Roll Back</p>
            </h6>
              
              </h6>
            <h6 className='z-50  text-[11px] min-w-[100px] max-w-[100px]  px-2 py-1 font-[600] border-r'>{formatTime(d?.closeBiddingTime + ":00")}</h6>
            <h6 className='z-50  text-[11px] min-w-[120px] max-w-[120px] border-r px-2 py-1 font-[600]'>

            {!d?.game_completed ?  
            <>
           
            {d?.closed_result !== '' ? 
                      <h6 className='flex'>
                        {/* {d?.closed_result} */}
                        <p className='w-[20px] h-[20px]  font-[700] text-gray-800 flex items-center justify-center border'>{d?.closed_result[0]}</p>
                        <p className='text-gray-200 mx-1'>-</p>
                        <p className='w-[60px] h-[20px]  font-[700] text-gray-800 flex items-center justify-center  border'>{d?.closed_result?.slice(1)}</p>
                      </h6> 
                      :
                      <h6 className='flex'>
                      <input value={selectedData?._id === d?._id ? result_close?.one : ''} name="one" onChange={(e)=>handlechange1(e,d)} maxLength={1} className='focus:outline-none text-center border w-[20px] text-[11px] center h-[20px]' />
                      <p className='text-gray-200 mx-1'>-</p>
                      <input value={selectedData?._id === d?._id ? result_close?.two : ''} name="two" onChange={(e)=>handlechange1(e,d)} maxLength={3} className='focus:outline-none text-center border-r border w-[60px] text-[11px] center h-[20px]' />
                      {/* <input value={selectedData?._id === d?._id ? result_close?.three : ''} name="three" onChange={(e)=>handlechange1(e,d)} maxLength={1} className='focus:outline-none text-center border-r border-t border-b w-[20px] text-[11px] center h-[20px]' />
                      <input value={selectedData?._id === d?._id ? result_close?.four : ''} name="four" onChange={(e)=>handlechange1(e,d)} maxLength={1} className='focus:outline-none text-center border-r border-t border-b w-[20px] text-[11px] center h-[20px] mr-2' /> */}
                    </h6>
                    }
            </>
              :
            <h6 className='flex'>
    
              <p className='w-[20px] h-[20px]  font-[700] text-gray-800 flex items-center justify-center border'>{d?.closed_result[0]}</p>
              <p className='text-gray-200 mx-1'>-</p>
              <p className='w-[60px] h-[20px]  font-[700] text-gray-800 flex items-center justify-center  border'>{d?.closed_result?.slice(1)}</p>
              {/* <p className='w-[20px] h-[20px]  font-[700] text-gray-800 flex items-center justify-center  border-l border-t border-b'>{d?.closed_result[1]}</p>
              <p className='w-[20px] h-[20px]  font-[700] text-gray-800 flex items-center justify-center  border-l border-t border-b'>{d?.closed_result[2]}</p>
              <p className='w-[20px] h-[20px]  font-[700] text-gray-800 flex items-center justify-center  border-l border-t border-b border-r'>{d?.closed_result[3]}</p> */}
            </h6> }
            </h6>
            <h6 className='z-50  text-[11px] min-w-[150px] max-w-[150px] font-[600] border-r'>
            <h6 className='z-50  text-[11px]  min-w-[170px] max-w-[170px] flex px-2 py-1 font-[600]'>
              <p onClick={()=>submitform(2)} className={`text-[10px]  z-0 cursor-pointer mr-2  bg-slate-600 font-[500] p-[2px] px-[10px] w-fit text-white h-fit rounded ${(d?.closed_result !== ''  || (new Date().getTime() < new Date(`${d?.date?.slice(0,10)}T${d?.closeBiddingTime}:00`))  || d?.open_result === '' || d?.game_completed || d?.game_cancelled) && 'opacity-30'}`}>Save</p>
              <p onClick={()=>rollbackfunc(2,d)}  className={`text-[10px]  z-0 cursor-pointer h-fit mr-2  bg-slate-200 font-[500] p-[2px] px-[10px] w-max text-black border ${(d?.closed_result === '' || d?.game_completed || d?.game_cancelled)  && 'opacity-30'} rounded`}>Roll Back</p>
            </h6>
            </h6>
            <h6 className='z-50  flex items-center  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600]'> <span className='text-[7px]  z-0  bg-slate-600 font-[800] p-[4px] uppercase text-white mr-2'>{d?.game_completed ? 'Completed' : 'Pending'}</span>  <AiOutlineEdit onClick={()=>navigate('status',{state:d?._id})} /> {step == 1 && <GrGamepad className='ml-2' onClick={()=>{setselectedData(d);setmodal1(true)}} />} <MdOutlineRollerShadesClosed className='ml-2' onClick={()=>{setselectedData(d);setmodal2(true)}} /> <RiTimeLine className='ml-2' onClick={()=>navigate('edit',{state:d})} /> </h6>
       </div>))}
     </div>  
   </div>
  )
}

export default GamesPlayList