import React, { useState,useEffect } from 'react'
import { HiOutlineUsers } from "react-icons/hi2";
import { CgGames } from "react-icons/cg";
import { TbPigMoney } from "react-icons/tb";
import moment from 'moment';
import { RiNotification4Line } from "react-icons/ri";
import { HiLogout } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { TextAreaInput1, TextInput } from '../../components/input';
import { ButtonFilledAutoWidth } from '../../components/button';
import { IoMdClose } from "react-icons/io";
import Uploader from '../../components/Uploader';
import { CreateNotificationService } from '../../services/NotificationServices';
import toast from 'react-hot-toast';
import { GetDashboardService } from '../../services/DashboardServices';
import Priceconstants from '../../constants/imageConstants';
import { GetTodayGameAdminService } from '../../services/GameServices';

function Dashboard() {
    
    const navigate = useNavigate()

    const [message,setmessage] = useState({title:'',description:'',image:''})
    const [error,seterror] = useState({title:'',description:'',image:''})
    const [modal,setmodal] = useState(false)
    const [data,setdata] = useState({})
    const [games,setgames] = useState([])
    
    
    async function logoutfunc(){
        navigate("/",{ replace: true });
    }

    useEffect(()=>{
        getData()
        getTodayGame()
    },[])

    
    async function getData(){
        const response = await GetDashboardService()
        setdata(response?.data?.data)
    }

    async function getTodayGame(){
        const response = await GetTodayGameAdminService()
        setgames(response?.data?.data)

    }



    function handlechange(e){
        setmessage({...message,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name]:''})
    }

   async function submitdata(){
        if(!message?.image){
            seterror({...error,image:'This field is required*'})
        }else if(!message?.title){
            seterror({...error,title:'This field is required*'})
        }else if(!message?.description){
            seterror({...error,description:'This field is required*'})
        }else{
            const fd = new FormData()
            fd.append('image',message?.image)
            fd.append('title',message?.title)
            fd.append('description',message?.description)
            const response = await CreateNotificationService(fd)
            if(response?.status === 201){
                setmessage({title:'',description:'',image:''})
                seterror({title:'',description:'',image:''})
                setmodal(!modal)
                toast.success("Notification Created")
            }
        }
    }
    
  return (
    <div className='w-[calc(100%-200px)] p-5'>

        <Modal open={modal} width={300} closable={false} footer={false}>
            <div className='relative'>
                <IoMdClose onClick={()=>setmodal(!modal)} className='absolute right-0 cursor-pointer' />
               <h1 className='text-[13.5px] font-[700]'>Create a Notification </h1>
               <h6 className='text-[12px] leading-[15px] mt-1 bg-slate-100 p-1 text-slate-700'>Use the below form to create a notification which will trigger to all the active game users.</h6>

               <h6 className='text-[11px] mt-2 font-[600] mb-1' >Image</h6>
               <Uploader image={message?.image}  setimagefunc={(e)=>{setmessage({...message,image:e});seterror({...error,image:''})}}  removeimageuploadfunc = {()=>{setmessage({...message,image:''});seterror({...error,image:''})}} />

               <TextInput
                    label={'Title'}  
                    variant="standard"
                    mandatory={true}
                    name="title"
                    type="text"
                    error={error.title}
                    value={message.title}
                    handlechange={handlechange}
                    placeholder="Enter  name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />

                <TextAreaInput1
                    label={'Description'}  
                    variant="standard"
                    mandatory={true}
                    name="description"
                    type="text"
                    error={error.description}
                    value={message.description}
                    handlechange={handlechange}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />

               
                <div className='mt-2 flex justify-end'>
                <ButtonFilledAutoWidth btnName="Save" onClick={submitdata} />
                </div>
            </div>

        </Modal>


        <div className='absolute right-5'>
        <div className='flex'>
        <RiNotification4Line  onClick={()=>setmodal(!modal)} size={25} className='bg-slate-200 rounded-[4px] p-[5px] mr-2' />
        <HiLogout onClick={logoutfunc} size={25} className='bg-slate-200 rounded-[4px] p-[5px]' />
        </div>
        </div> 

        <h6 className='text-[14px] font-[700]'>Dashboard</h6>
        <h6 className='text-[12px] font-[400] text-slate-600'> {moment().format('LL')}</h6>

        <div className='grid grid-cols-5 gap-6 mt-4'>

            <div onClick={()=>navigate('/users')} className='bg-[#cdc4ff] py-4  p-2 rounded-lg'>
                <div className='flex items-start '>
                <HiOutlineUsers size={20} className='bg-[#ada0f5] p-[4px]'  />
                <div className='ml-2 '>
                    <h6 className='text-[12px] font-[500]'>Total Users</h6>
                </div>
                </div>
                <h6 className='font-[900] text-center mt-2'>{data?.user}</h6>
                <h6 className='text-[11px] text-center'>Total Active Users Registered</h6>

            </div>

            <div onClick={()=>navigate('/transaction',{state:{type:'Deposit',date:new Date().toISOString().slice(0,10)}})} className='bg-[#aac9ff] py-4  p-2 rounded-lg'>
                <div className='flex items-start '>
                <CgGames size={20} className='bg-[#83acf3] p-[4px]'  />
                <div className='ml-2 '>
                    <h6 className='text-[13px] font-[400]'>Today Deposit</h6>
                </div>
                </div>
                <h6 className='font-[900] text-center mt-2'>{Priceconstants(data?.wallettransaction_today_deposit)}</h6>
                <h6 className='text-[11px] text-center'>Total Deposited Amount</h6>

            </div>

            <div onClick={()=>navigate('/withdraw')} className='bg-[#92e3b8] py-4  p-2 rounded-lg'>
                <div className='flex items-start '>
                <TbPigMoney size={20} className='bg-[#5ab786] p-[4px]' />
                <div className='ml-2 '>
                    <h6 className='text-[13px] font-[400]'>Today Withdraw</h6>
                </div>
                </div>

                <h6 className='font-[900] text-center mt-2'>{Priceconstants(data?.wallettransaction_today_withdrawl)}</h6>
                <h6 className='text-[11px] text-center'>Total Withdraw Amount</h6>

            </div>

            <div onClick={()=>navigate('/games_play')} className='bg-[#2099a2] py-4  p-2 rounded-lg'>
                <div className='flex items-start '>
                <CgGames size={20} className='bg-[#1d7e86] p-[4px]'  />
                <div className='ml-2 '>
                    <h6 className='text-[13px] font-[400]'>Today Bidding</h6>
                </div>
                </div>
                <h6 className='font-[900] text-center mt-2'>{Priceconstants(data?.today_bidding)}</h6>
                <h6 className='text-[11px] text-center'>Today Bidding Amount</h6>
            </div>

            <div  className='bg-[#dcff70] py-4  p-2 rounded-lg'>
                 <div className='flex items-start '>
                <TbPigMoney size={20} className='bg-[#b4d258] p-[4px]'  />
                <div className='ml-2 '>
                    <h6 className='text-[13px] font-[400]'>Total Bidding</h6>
                </div>
                </div>
                <h6 className='font-[900] text-center mt-2'>{Priceconstants(data?.total_bidding)}</h6>
                <h6 className='text-[11px] text-center'>Total Bidding Amount</h6>

            </div>

        </div>

       {games?.length > 0 && 
        <>
        <h6 className='text-[14px] mt-6 font-[700]'>Today Game</h6>

        <div className='grid grid-cols-5 gap-6 mt-2'>
            {games?.map((g)=>(
                <div className='border p-1'>
                    <h6 className='text-[11px]'>Game Name : <span className='font-[700]'>{g?.game?.name}</span></h6>
                    <h6 className='text-[11px] flex items-center'>Result : 
                    <h6 className='font-[700] pt-[4px] pl-[2px]'>{g?.open_result === "" ? '***' : g?.open_result?.slice(0,3)}-{g?.open_result === '' ? '*' :  g?.open_result?.slice(3)}
                  
                    {g?.closed_result === "" ? '*' :  g?.closed_result?.slice(0,1)}-{g?.closed_result === '' ? '***' :  g?.closed_result?.slice(1)}</h6></h6>
                    {
                    <>
                    {(g?.open_result === "" || g?.closed_result === "") ?
                    <>
                    {((new Date() > (new Date(`${g?.date?.slice(0,10)}T${g?.openBiddingTime}:00`))) && g?.open_result === "") &&
                    <h6 onClick={()=>navigate('/games_play/status',{state:g?._id})} className='cursor-pointer text-[10px] underline font-[700]  text-sky-500'>{g?.open_result === '' && <h6>Announce Open Result</h6>}</h6>}
                    {((new Date() > (new Date(`${g?.date?.slice(0,10)}T${g?.closeBiddingTime}:00`))) && g?.closed_result === "") &&
                    <h6 onClick={()=>navigate('/games_play/status',{state:g?._id})} className='cursor-pointer text-[10px] underline font-[700]  text-sky-500'>{(g?.open_result !== '' && g?.closed_result === '') && <h6 className='text-[10px] underline font-[700]  text-sky-500'>Announce Close Result</h6>}</h6>}
                    </>
                    :
                    <h6 onClick={()=>navigate('/games_play/status',{state:g?._id})} className='cursor-pointer text-[10px] underline font-[700]  text-sky-500'>View Game Statistics</h6>}
                    </>}
                </div>    
            ))}

        </div>
        </>}
    </div>
  )
}

export default Dashboard