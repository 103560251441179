import React from 'react'
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { CgGames } from "react-icons/cg";
import { PiUserSquare } from "react-icons/pi";
import { CiWallet } from "react-icons/ci";
import { AiOutlineTransaction } from "react-icons/ai";
import { MdOutlineManageHistory,MdOutlineFormatTextdirectionRToL } from "react-icons/md";
import { HiLogout } from "react-icons/hi";
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from './folder/assets/images/logo.png'
import { PiFlagBanner } from "react-icons/pi";
import { FaScaleUnbalancedFlip } from "react-icons/fa6";
import { BiSolidOffer } from "react-icons/bi";
import { useDispatch } from 'react-redux';
import { LogOutAction } from './folder/redux/actions/authAction';
import { PiNotificationBold } from "react-icons/pi";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { TbMoneybag } from "react-icons/tb";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { RiBillLine } from "react-icons/ri";
import { TbCategory2 } from "react-icons/tb";
import { CgSmartHomeCooker } from "react-icons/cg";
import { MdPayment } from "react-icons/md";
function SideBar() {

  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {pathname} = useLocation()


  async function navigatefun(v){
    navigate(v)
  }

  const menu = [
         {id:1,name:'My Dashboard',icon:MdOutlineSpaceDashboard,path:'/dashboard'},
         {id:2,name:'Bidding Ratio ',icon:FaScaleUnbalancedFlip,path:'/ratio'},
         {id:3,name:'Extra Offer ',icon:BiSolidOffer,path:'/offer'},
         {id:4,name:'Promo Banner',icon:PiFlagBanner,path:'/banner'},
         {id:5,name:'Promo Text',icon:MdOutlineFormatTextdirectionRToL,path:'/promotional_text'},
         {id:6,name:'Money Default',icon:MdOutlineFormatTextdirectionRToL,path:'/app_default'},
         {id:7,name:'Bidding Games',icon:CgGames,path:'/games'},
         {id:7,name:'All Games',icon:CgGames,path:'/games_play'},
         {id:8,name:'Joined Users',icon:PiUserSquare,path:'/users'},
         {id:9,name:'WithDraw Request',icon:CiWallet,path:'/withdraw'},
         {id:10,name:'Overall Transactions',icon:AiOutlineTransaction,path:'/transaction'},
         {id:10,name:'Overall Bidding',icon:TbMoneybag,path:'/biddings'},
         {id:11,name:'Add Fund',icon:HiOutlineReceiptRefund,path:'/add_fund'},
         {id:12,name:'Payment Track',icon:MdPayment,path:'/payment_from_upi'},
  ]     


  const menu1 = [
    {id:1,name:'Manage App',icon:MdOutlineManageHistory,path:'/manage_app'},
    {id:2,name:'Notification App',icon:PiNotificationBold,path:'/notification'},
    {id:3,name:'Blog Banner',icon:PiFlagBanner,path:'/blog_banner'},
    {id:3,name:'Blog Home',icon:CgSmartHomeCooker,path:'/blog_home'},
    {id:3,name:'Blog Post',icon:RiBillLine,path:'/blog'},
    {id:3,name:'Blog Category',icon:TbCategory2,path:'/blog_category'},
    {id:4,name:'Statistic Analytics',icon:TbDeviceDesktopAnalytics,path:'/statistics'},
    {id:5,name:'Logout Application',icon:HiLogout},
  ]     

  async function logoutfunc(){
    console.log("okkk")
    dispatch(LogOutAction())
    navigate("/",{ replace: true });
  }
  
  
  return (
    <div className='w-[200px]  bg-slate-800 relative text-white min-h-screen max-h-screen overflow-y-scroll no-scrollbar'>
        <div className='opacity-60 py-2 pt-4 cursor-pointer bg-white'>
        <img src={Logo}  className='-mt-2 object-contain px-2  h-[55px]'/>
        </div>

        <h6 className='text-[10px] opacity-80 font-[700] mb-2 ml-4 mt-4'>MAIN OPTIONS</h6>
        {menu?.map((m)=>(
            <div onClick={()=>navigatefun(m?.path)} className={`opacity-60 pl-5 cursor-pointer hover:opacity-100 ${pathname === m?.path ? 'bg-slate-900 opacity-100' : 'opacity-60'} hover:bg-slate-900 flex p-2 items-center `}>
             <m.icon size={15} className='text-white' />
             <h6 className='text-[11px] text-center ml-2 text-white'>{m?.name}</h6>
            </div>    
        ))}
        <h6 className='text-[10px] opacity-80 font-[700] mb-2 ml-4 mt-4'>SUPPORT</h6>

        {menu1?.map((m)=>(
            <div onClick={()=>m?.name === 'Logout Application' ? logoutfunc() : navigatefun(m?.path)} className={`opacity-60 pl-5 cursor-pointer hover:opacity-100 ${pathname === m?.path ? 'bg-slate-900 opacity-100' : 'opacity-60'} hover:bg-slate-900 flex p-2 items-center `}>
             <m.icon size={15} className='text-white' />
             <h6 className='text-[11px] text-center ml-2 text-white'>{m?.name}</h6>
            </div>    
        ))}


        {/* <h6 className='text-[8px] text-center text-white absolute left-[25%] bottom-[5px] opacity-60'>© Kuber 567. 2023</h6> */}

    </div>
  )
}

export default SideBar