import React,{useEffect, useState} from 'react'
import { TextInput } from '../../components/input'
import GoBack from '../../components/back/GoBack';
import { useNavigate, useLocation } from 'react-router-dom'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import { CreateBlogCategoryService, UpdateBlogCategoryService } from '../../services/BlogCategoryServices';

function BlogCategoryCE() {
   
   const [data,setdata] = useState({name:''});  
   const [loader,setloader] = useState(false);  
   const [error,seterror] = useState({name:''}); 

   const {state,pathname} = useLocation();
  
   const path = pathname.split('/')[pathname.split('/').length - 1]
   const navigate = useNavigate();

    
 

  useEffect(()=>{
       if(path === 'edit'){
        setdata({...data,...state})
       }
  },[state])

  async function submitform(){
    if(!data?.name){
        seterror({...error,name:'This Field is required*'})
    }else{
       if(path === 'create'){
           setloader(true)
           const response  = await CreateBlogCategoryService(data)
           if(response?.status === 201){
            setloader(false)
            setdata({name:''})
            seterror({name:''})
            toast.success("Blogs Category Created")
           }
       }else{
        setloader(true)
        const response  = await UpdateBlogCategoryService(state._id,data)
        if(response?.status === 200){
            setloader(false)
            setdata({name:''})
            seterror({name:''})
            toast.success("Blogs Updated Updated")
            navigate(-1)
           }
       }
    }

  }


  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
    }

//   console.log("error",error)

  return (
    <div className='w-[300px] px-5 mt-5'>

        <GoBack />

            <h6 className='font-bold text-[14px]'>Add / Update Blog Category</h6>
        <h6 className='text-[10px] mt-2 bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Blog Category</b> for your game user to access this blogs.</h6>
     

       

        <TextInput
                    label={'Name'}  
                    variant="standard"
                    mandatory={true}
                    name="name"
                    type="text"
                    error={error.name}
                    value={data.name}
                    handlechange={handlechange}
                    placeholder="Enter  name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />




        {!loader ? 
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} onClick={submitform} />  
        </div>
        :
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} />  
        </div>}

    </div>
  )
}

export default BlogCategoryCE