import React, { useState,useEffect } from 'react'
import {ButtonFilled} from '../../components/button'
import { useNavigate } from 'react-router-dom';
import { DeleteGameDefaultService, GetGameDefaultService } from '../../services/GameDefaultServices';
import moment from 'moment';
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import { Modal } from 'antd';
import toast from 'react-hot-toast';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';

function AppDefaultList() {
  
  const [datas,setdatas] = useState([])  
  const [modal, setModal] = useState(false);
  const [selectedData,setselectedData] = useState({})  
  
  const navigate = useNavigate()

  useEffect(()=>{
    getData()
  },[])

  async function getData(){
    const response = await GetGameDefaultService()
    if(response?.status == 200){
      setdatas(response?.data?.data)
    }
  }

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  async function deleteData(){
    const response = await DeleteGameDefaultService(selectedData._id)
    if(response.status === 200){
     getData()
     setModal(false)
     toast.success("Promotional Text Deleted")
    }
   }

   function formatTime(timeString) {
    const [hourString, minute] = timeString?.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  }




  return (
    <div className='w-[calc(100%-200px)] p-5'>
        <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total App Default ({datas?.length})</h6>
            <div className='flex items-center'>
            
        
            <div className='ml-2 mr-0'>
            <ButtonFilled btnName="Add Data" onClick={()=>navigate('create')} /> 
            </div>
        
            </div>

        </div>

        <Modal
       keepMounted
       open={modal}
       onClose={()=>setModal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-[11px] leading-normal p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteData()}  /> 
           </div>
         </div>
       </div>
     </Modal>

        <div className='grid mt-2 grid-cols-4 gap-2'>
          {datas?.map((d)=>(
            <div className='border relative'>
                <div className='absolute border-l border-b  right-0 top-0 bg-white '>
                    <div className='flex'>
                    <AiOutlineEdit size={17} onClick={()=>navigate('edit',{state:d})} className='p-[2px] cursor-pointer border-r' />
                    {/* <AiOutlineDelete size={17}  onClick={()=>{setselectedData(d);setModal(!modal)}} className='p-[2px] cursor-pointer' /> */}
                   </div>
                 </div>  
               <div className='p-2'> 
               <div className='flex border-t border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>Minimum Deposit Amount</h6>
                <h6 className="p-1 text-[10px] font-[400]">{rupee?.format(d?.minimumDepositAmount)}</h6>
               </div> 
               <div className='flex border-t border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>Minimum WithDraw Amount</h6>
                <h6 className="p-1 text-[10px] font-[400]">{rupee?.format(d?.minmumWithDrawAmount)}</h6>
               </div> 
               <div className='flex border-t border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>Minimum Bidding Amount</h6>
                <h6 className="p-1 text-[10px] font-[400]">{rupee?.format(d?.minimumBiddingAmount)}</h6>
               </div> 
               <div className='flex border-t border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>Maximum Deposit Amount Single</h6>
                <h6 className="p-1 text-[10px] font-[400]">{rupee?.format(d?.maximumBiddingAmountSingle)}</h6>
               </div> 
               <div className='flex border-t border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>Maximum Deposit Amount Double</h6>
                <h6 className="p-1 text-[10px] font-[400]">{rupee?.format(d?.maximumBiddingAmountDouble)}</h6>
               </div> 
               <div className='flex border-t border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>Maximum Deposit Amount Single Panna</h6>
                <h6 className="p-1 text-[10px] font-[400]">{rupee?.format(d?.maximumBiddingAmountSinglePanna)}</h6>
               </div> 
               <div className='flex border-t border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>Maximum Deposit Amount Double Panna</h6>
                <h6 className="p-1 text-[10px] font-[400]">{rupee?.format(d?.maximumBiddingAmountDoublePanna)}</h6>
               </div> 
               <div className='flex border-t border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>Maximum Deposit Amount Tripple Panna</h6>
                <h6 className="p-1 text-[10px] font-[400]">{rupee?.format(d?.maximumBiddingAmountTriplePanna)}</h6>
               </div> 
               <div className='flex border-t border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>Maximum Deposit Amount Half Sangam</h6>
                <h6 className="p-1 text-[10px] font-[400]">{rupee?.format(d?.maximumBiddingAmountHalfSangam)}</h6>
               </div> 
               <div className='flex border text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>Maximum Deposit Amount Full Sangam</h6>
                <h6 className="p-1 text-[10px] font-[400]">{rupee?.format(d?.maximumBiddingAmountFullSangam)}</h6>
               </div> 
               <div className='flex border-b border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>WithDraw Amount Percentage Deduct</h6>
                <h6 className="p-1 text-[10px] font-[400]">{d?.percentageDeduct} %</h6>
               </div> 
               <div className='flex border-b border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>WithDraw Avaialble</h6>
                <h6 className="p-1 text-[10px] font-[400]">{d?.withdrawAvailable  ? 'Available' : 'Not Available'}</h6>
               </div> 
               <div className='flex border-b border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>WithDraw Open</h6>
                <h6 className="p-1 text-[10px] font-[400]">{formatTime(d?.withdraw_open + ":00")}</h6>
               </div> 
               <div className='flex border-b border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>WithDraw Close</h6>
                <h6 className="p-1 text-[10px] font-[400]">{formatTime(d?.withdraw_close + ":00")}</h6>
               </div> 
               <div className='flex border-b border-l border-r text-[12px]'>
                <h6 className='w-[60%] text-[10px] font-[700] border-r p-1'>WithDraw Request Count</h6>
                <h6 className="p-1 text-[10px] font-[400]">{d?.withdraw_request_count}</h6>
               </div> 
               </div>

               <h6 className='text-[11px] mx-2 mb-2 bg-slate-100 p-1'>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>
               
            </div>    
          ))}
        </div>
       
    </div>
  )
}

export default AppDefaultList