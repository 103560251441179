import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import moment from 'moment';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { DeletePromotionalTextService, GetAllPromotionalTextService } from '../../services/PromotionalTextServices';
import { Modal } from 'antd';
import toast from 'react-hot-toast';

function PromotionalTextList() {
  
  const [datas,setdatas] = useState([])  
  const [selectedData,setselectedData] = useState({})  
  const [modal, setModal] = useState(false);
  

  const navigate = useNavigate()
 
  useEffect(()=>{
    getData()
  },[])

  async function getData(){
    const response = await GetAllPromotionalTextService()
    if(response?.status == 200){
      setdatas(response?.data?.data)
    }
  }


  async function addData(){
    navigate('create')
  }

  async function deleteData(){
   const response = await DeletePromotionalTextService(selectedData._id)
   if(response.status === 200){
    getData()
    setModal(false)
    toast.success("Promotional Text Deleted")
   }
  }




    


  return (
    <div className='w-[calc(100%-200px)] p-5'>
         <div className='flex items-center justify-between border-b pb-2'>
         <Modal
       keepMounted
       open={modal}
       onClose={()=>setModal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-[11px] leading-normal p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteData()}  /> 
           </div>
         </div>
       </div>
     </Modal>
         
        <h6 className='font-[700] text-[14px] '>Total Promotional Text ({datas?.length})</h6>
        <div className='flex items-center'>
          <div className='flex items-center text-[12px] mr-2'>

            

          <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>addData()} /> 
          </div>


      

       
      
        </div>

        </div>
        


        <div className='grid mt-2 grid-cols-5 gap-2'>
          {datas?.map((d)=>(
            <div className='border relative p-1 px-2'>
                 <div className='absolute border-l border-b  right-0 top-0 bg-white '>
                    <div className='flex'>
                    <AiOutlineEdit size={17} onClick={()=>navigate('edit',{state:d})} className='p-[2px] cursor-pointer border-r' />
                    <AiOutlineDelete size={17}  onClick={()=>{setselectedData(d);setModal(!modal)}} className='p-[2px] cursor-pointer' />
                   </div>
                 </div>  
                <h1 className='text-[12px] text-slate-700 mt-2 z-10'>Promotional Text : <span className='font-[600] text-black'>{d?.name}</span></h1>
                

                <h6 className='text-[10px] bg-slate-100 p-1 mt-1'>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>

            </div>  
          ))}

        </div>
    </div>
  )
}

export default PromotionalTextList