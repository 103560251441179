import { deleteRequest, get, post} from "../helpers/apihelpers";

export const GetAllBlogHomeService = async (page)=>{
    try {
      const result = await get(`api/bloghome/get?page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

export const CreateBlogHomeService = async (data)=>{
    try {
      const result = await post(`api/bloghome/create`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }  

export const UpdateBlogHomeService = async (id,data)=>{
    try {
      const result = await post(`api/bloghome/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }    

export const DeleteBlogHomeService = async (id,data)=>{
    try {
      const result = await deleteRequest(`api/bloghome/delete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }   