import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const LogInService = async (data)=>{
    try {
      const result = await publicPost(`api/auth/signin`, data);
      return result;
    } catch (err) {
      return err.response;
    }
}

export const UpdateUserNameService = async (data,id)=>{
  try {
    const result = await post(`api/user/update/${id}`, data);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const UpdateUserPasswordService = async (data,id)=>{
  try {
    const result = await post(`api/user/change-password-admin`, data);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const GetSearchService = async (search,page)=>{
  try {
    const result = await get(`api/user/search?search=${search}&page=${page}`);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const RequestOtpService = async (data) =>{
  try {
    let sendData={...data,user_id:data.official_email}
    // const result = await publicPost(`api/user/request_otp_to_pasword`, data);
    const result = await publicPost(`api/user/send-reset-password-link`, sendData);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const VerifyOtpService =  async (data) =>{
  try {
    const result = await publicPost(`api/user/verify_otp`, data);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const ForgotPasswordService = async (data,token) =>{
  try {
    const result = await post(`api/user/reset-password?token=${token}`, data);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const UpdatePasswordService =  async (data) =>{
  try {
    const result = await put(`api/user/change-password`, data);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const UploadProfilepic = async(data)=>{
  try {
    const result = await postfd(`api/user/update-profile-photo`, data);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const UploadProfiledata = async(data,id)=>{
  try {
    const result = await postfd(`api/user/update/${id}`, data);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const SearchUser = async(data)=>{
  try {
    const result = await get(`api/user/get_operator_based_filter?search=${data}`);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const getMyTarget = async () =>{
  try {
    const result = await get(`api/user/target_vs_Achieved`);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const getMyProfile = async (id) =>{
  try {
    const result = await get(`api/user/profile/${id}`);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const updateOnboardDetails = async (data) =>{
  try {
    const result = await post(`api/user/update_onboard_detials`,data);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const updateOnboardDetailsByHr = async (data) =>{
  try {
    const result = await post(`api/user/update_onboard_detials_by_hr`,data);
    return result;
  } catch (err) {
    return err.response;
  }
}


export const updateUserRole = async (id,data) =>{
  try {
    const result = await put(`api/auth/user_roles_assign/${id}`,data);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const uploadFile = async (data) =>{
  try {
    const result = await postfd(`api/user/upload_file`,data);
    return result;
  } catch (err) {
    return err.response;
  }
}


export const getactiveUser = async (department,page,text) =>{
  try {
    const result = await get(`api/user/active_users/:${department}/${page}?search=${text}`);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const getUserById = async (id) =>{
  try {
    const result = await get(`api/auth/user/${id}`);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const DeleteActiveState = async (id) =>{
  try {
    const result = await deleteRequest(`api/auth/deactivate/${id}`);
    return result;
  } catch (err) {
    return err.response;
  }
}

export const UpdateUserById = async (id,body) =>{
  try {
    const result = await put(`api/auth/user/${id}`,body);
    return result;
  } catch (err) {
    return err.response;
  }
}



