import React,{useEffect, useState} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import GoBack from '../../components/back/GoBack';
import { useNavigate, useLocation } from 'react-router-dom'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import Uploader from '../../components/Uploader';
import {BiErrorCircle} from 'react-icons/bi'
import { CreateNotificationService, UpdateNotificationService } from '../../services/NotificationServices';
import { CreateBlogService, UpdateBlogService } from '../../services/BlogServices';
import { GetAllBlogCategoryService } from '../../services/BlogCategoryServices';
import { Select } from 'antd'

function BlogCE() {
   
   const [data,setdata] = useState({image:'',title:'',description:'',author:'',tags:'',small_description:'',category:''});  
   const [loader,setloader] = useState(false);  
   const [error,seterror] = useState({image:'',title:'',description:'',author:'',tags:'',small_description:'',category:''}); 
   
   const [category,setcategory] = useState([])

   const {state,pathname} = useLocation();
  
   const path = pathname.split('/')[pathname.split('/').length - 1]
   const navigate = useNavigate();

 

   useEffect(()=>{
    getData()
   },[])
   
 

  useEffect(()=>{
       if(path === 'edit'){
        setdata({...data,...state})
       }
  },[state])


  async function getData(){
    const response = await GetAllBlogCategoryService(1)
    let arr = []
    let d = response?.data?.data?.datas
    d?.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setcategory(arr)
  }

//   console.log("category",category)

  async function submitform(){
    if(!data?.image){
        seterror({...error,image:'This Field is required*'})
    }else if(!data?.title){
        seterror({...error,title:'This Field is required*'})
    }else if(!data?.description){
        seterror({...error,description:'This Field is required*'})
    }else{
       if(path === 'create'){
           setloader(true)
           const fd = new FormData()
           fd.append('image',data?.image)
           fd.append('title',data?.title)
           fd.append('tags',data?.tags)
           fd.append('author',data?.author)
           fd.append('small_description',data?.small_description)
           fd.append('description',data?.description)
           fd.append('category',data?.category)
           const response  = await CreateBlogService(fd)
           if(response?.status === 201){
            setloader(false)
            setdata({image:'',title:'',description:'',author:'',tags:'',small_description:'',category:''})
            seterror({image:'',title:'',description:'',author:'',tags:'',small_description:'',category:''})
            toast.success("Blogs Created")
           }
       }else{
        setloader(true)
        const fd = new FormData()
        fd.append('image',data?.image)
        fd.append('title',data?.title)
        fd.append('tags',data?.tags)
        fd.append('author',data?.author)
        fd.append('small_description',data?.small_description)
        fd.append('description',data?.description)
        fd.append('category',data?.category)
        const response  = await UpdateBlogService(state._id,fd)
        if(response?.status === 200){
            setloader(false)
            setdata({image:'',title:'',description:'',author:'',tags:'',small_description:'',category:''})
            seterror({image:'',title:'',description:'',author:'',tags:'',small_description:'',category:''})
            toast.success("Blogs Updated")
            navigate(-1)
           }
       }
    }

  }


  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
    }

//   console.log("error",error)

  return (
    <div className='w-[300px] px-5 mt-5'>

        <GoBack />

            <h6 className='font-bold text-[14px]'>Add / Update Blog</h6>
        <h6 className='text-[10px] mt-2 bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Blog</b> for your game user to access this blogs.</h6>
     

        <h6 className='text-[11px] font-[600] mb-1 mt-1' >Select Image</h6>
       
        <Uploader image={data?.image}  setimagefunc={(e)=>{setdata({...data,image:e});seterror({...error,image:''})}}  removeimageuploadfunc = {()=>{setdata({...data,image:''});seterror({...error,image:''})}} />
        {error?.image !== '' && error?.image !== undefined &&
        <div className='flex items-center mt-1'>
        <BiErrorCircle className='text-red-500' size={14} />
        <span className='text-[10px] text-red-500 ml-1'>{error?.image}</span>
        </div>}

        <TextInput
                    label={'Title'}  
                    variant="standard"
                    mandatory={true}
                    name="title"
                    type="text"
                    error={error.title}
                    value={data.title}
                    handlechange={handlechange}
                    placeholder="Enter  name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />

        <h6 className='text-[11px] font-[600] mb-1 mt-1' >Category</h6>
        <Select value={category?.find((f)=>f?.value === data?.category)} onChange={(e)=>setdata({...data,category:e})} className='border h-[30px] w-full border-l-4 border-l-slate-600' bordered={false} options={category} />



<TextInput
                    label={'Tags'}  
                    variant="standard"
                    mandatory={true}
                    name="tags"
                    type="text"
                    error={error.tags}
                    value={data.tags}
                    handlechange={handlechange}
                    placeholder="Enter  name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />

<TextInput
                    label={'Author'}  
                    variant="standard"
                    mandatory={true}
                    name="author"
                    type="text"
                    error={error.author}
                    value={data.author}
                    handlechange={handlechange}
                    placeholder="Enter  name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />

<TextAreaInput1
                    label={'Small Description'}  
                    variant="standard"
                    mandatory={true}
                    name="small_description"
                    type="text"
                    error={error.small_description}
                    value={data.small_description}
                    handlechange={handlechange}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />

                <TextAreaInput1
                    label={'Description'}  
                    variant="standard"
                    mandatory={true}
                    name="description"
                    type="text"
                    error={error.description}
                    value={data.description}
                    handlechange={handlechange}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />

        {!loader ? 
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} onClick={submitform} />  
        </div>
        :
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} />  
        </div>}

    </div>
  )
}

export default BlogCE