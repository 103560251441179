import { deleteRequest, get, post, put } from "../helpers/apihelpers";

export const GetAllPromotionalTextService = async ()=>{
    try {
      const result = await get(`api/promotionaltext/get`);
      return result;
    } catch (err) {
      return err.response;
    }
  }



  export const CreatePromotionalTextService = async (data)=>{
    try {
      const result = await post(`api/promotionaltext/create`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const UpdatePromotionalTextService = async (id,data)=>{
    try {
      const result = await put(`api/promotionaltext/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }

  export const DeletePromotionalTextService = async (id,data)=>{
    try {
      const result = await deleteRequest(`api/promotionaltext/delete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }