import { deleteRequest, get, post,} from "../helpers/apihelpers";

export const GetAllBlogCategoryService = async (page)=>{
    try {
      const result = await get(`api/blogcategory/get?page=${page}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }

export const CreateBlogCategoryService = async (data)=>{
    try {
      const result = await post(`api/blogcategory/create`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }  

export const UpdateBlogCategoryService = async (id,data)=>{
    try {
      const result = await post(`api/blogcategory/update/${id}`,data);
      return result;
    } catch (err) {
      return err.response;
    }
  }    

export const DeleteBlogCategoryService = async (id,data)=>{
    try {
      const result = await deleteRequest(`api/blogcategory/delete/${id}`);
      return result;
    } catch (err) {
      return err.response;
    }
  }   