import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CloseGamePlayService, CompleteTodayGameService, GetGameBiddingAmountBasedonIdService, GetTodayGamePlayDetailService, UpdateGameTodayRollBackService, UpdateGameTodayService } from '../../services/GameServices'
import moment from 'moment'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import toast from 'react-hot-toast'
import { combination, jodiValuesList } from '../../constants/CombinationConstants'
import Priceconstants from '../../constants/imageConstants'
import { GrGamepad } from "react-icons/gr";
import { Modal } from 'antd';
import { MdOutlineRollerShadesClosed } from 'react-icons/md'
import { RiTimeLine } from "react-icons/ri";


function GamePlayAllocateResult() {

  const {state} = useLocation()
  const [data,setdata] = useState({})
  const [result,setresult] = useState({})

  const [step,setstep] = useState(1)
  const [modal,setmodal] = useState(false)
  const [modal1,setmodal1] = useState(false)
  const [modal2,setmodal2] = useState(false)
  const [type,settype] = useState('')  


  const [result_open,setresult_open] = useState({one:'',two:''})
  const [result_close,setresult_close] = useState({one:'',two:''})

  const navigate = useNavigate()

  useEffect(()=>{
    getdata()
    getresult()
  },[])
  
  async function getdata(){
    const response = await GetTodayGamePlayDetailService(state)
    let d = response?.data?.data[0]
    if(d?.open_result !== ''){
      let r = d?.open_result?.split('')
      setresult_open({one:r?.slice(0,3),two:r?.slice(3)})
    }else{
      setresult_open({one:'',two:''})
    }
    if(d?.closed_result !== ''){
      let r = d?.closed_result?.split('')
      setresult_close({one:r?.slice(0,1),two:r?.slice(1)})
    }else{
      setresult_close({one:'',two:''})
    }

    setdata(d)
  }

  async function rollback(){

    let send_data = {
      type:type == '1' ? 'open' : 'close'
    }
    const response = await UpdateGameTodayRollBackService(data?._id,send_data)
    if(response?.status === 200){
      setmodal(!modal)
      getdata()
      toast.success(type == '1' ? "Open Roll Back Successful *" : "Close Roll Back Successful *")
    }
  }

  async function getresult(){
    const response = await GetGameBiddingAmountBasedonIdService(state)
    setresult(response?.data?.data)
  }

  async function handlechange(e){
    setresult_open({...result_open,[e.target.name]:e.target.value})
  }
  async function handlechange1(e){
    setresult_close({...result_close,[e.target.name]:e.target.value})
  }

  async function submitform(v){

    if(v == 1){
      if(data?.open_result === '' && (new Date().getTime() > new Date(`${data?.date?.slice(0,10)}T${data?.openBiddingTime}:00`))){
          if(!result_open?.one){
            toast.error("Open 1st digit is required")
          }else if(!result_open?.two){
            toast.error("Open 2nd digit is required")
          }else{
            let send_data = {...data}
            delete send_data.game
            send_data['open_result'] = result_open?.one +  result_open?.two 
            send_data['type'] = 'open'
            const response = await UpdateGameTodayService(data?._id,send_data)
            if(response?.status === 200){
              toast.success('Open Result announced')
              getdata()
              setresult_open({one:'',two:''})
            }
        }
      }else if((!(new Date() > (new Date(data?.date?.split('-')[0],data?.date?.split('-')[1],data?.date?.split('-')[2]?.slice(0,2),data?.openBiddingTime?.split(':')[0],data?.openBiddingTime?.split(':')[1],'00'))))){
        toast.error("Open Bidding Time Not Completed")
      }else if(data?.open_result !== '' ){
        toast.error("Result already announced rollback first to re assign result!")
      }
    }else if(v == 2){
      if(data?.closed_result === '' && (new Date() > (new Date().getTime() > new Date(`${data?.date?.slice(0,10)}T${data?.closeBiddingTime}:00`)))){
        if(!result_close?.one){
          toast.error("Close 1st digit is required")
        }else if(!result_close?.two){
          toast.error("Close 2nd digit is required")
        }else{
          let send_data = {...data}
          delete send_data.game
          send_data['closed_result'] = result_close?.one +  result_close?.two 
          send_data['type'] = 'close'
          const response = await UpdateGameTodayService(data?._id,send_data)
          if(response?.status === 200){
            toast.success('Close Result announced')
            getdata()
            setresult_close({one:'',two:''})
          }
      }
    }else if((!(new Date() > (new Date(data?.date?.split('-')[0],data?.date?.split('-')[1],data?.date?.split('-')[2]?.slice(0,2),data?.closeBiddingTime?.split(':')[0],data?.closeBiddingTime?.split(':')[1],'00'))))){
      toast.error("Close Bidding Time Not Completed")
    }else if(data?.open_result !== '' ){
      toast.error("Result already announced rollback first to re assign result!")
    }
    }

  }

  async function rollbackfunc(v){
    //  console.log("data",data)
    // console.log("roll back initiated",type,v)
    if(type == 1){
      let send_data = {...data}
      send_data['open_result'] = ''
      send_data['type'] = 'open'
      const response = await UpdateGameTodayRollBackService(data?._id,send_data)
      if(response?.status === 200){
        toast.success("Open Roll back Successfull")
        getdata()
        setmodal(!modal)
      }
        
    }else if(type == 2){
        let send_data = {...data}
        send_data['closed_result'] = ''
        send_data['type'] = 'close'
        const response = await UpdateGameTodayRollBackService(data?._id,send_data)
        if(response?.status === 200){
          toast.success("Close Roll back Successfull")
          getdata()
        setmodal(!modal)

        }
          
    }
    
  }

  function formatTime(timeString) {
    const [hourString, minute] = timeString?.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  }

  async function closegame(){
    const response = await CloseGamePlayService(data?._id)
    if(response?.status === 200){
      setmodal1(!modal1)
      getdata()
      toast.success("Game Closed Successful *")
    }
  }

  async function rollback(v){
    if(v == 1){
      if(data?.open_result === ''){
        toast.error("Open result not announced yet to rollback")
      }else{
        settype(v);
        setmodal(!modal)
      }
    }else if(v == 2){
      if(data?.closed_result === ''){
        toast.error("Closed result not announced yet to rollback")
      }else{
        settype(v);
        setmodal(!modal)
      }
    }
    
  }

  async function completegame(){
    
    const response = await CompleteTodayGameService(data?._id,{completed:!data.game_completed})
    if(response?.status === 200){
      setmodal2(!modal2)
      getdata()
      toast.success("Game Completed Successful *")
    }
  }


  return (
    <div className='max-h-[95vh] overflow-y-scroll no-scrollbar w-[calc(100%-200px)] p-5'>
          
      <Modal width={300} open={modal} footer={false} closable={false}>
      <div>
        <h6 className='text-[13px] font-[700]'>{type == 1 ? 'Open' : 'Close'} Roll Back</h6>
        <h6 className='text-[11px] p-1 bg-slate-100'>Are you sure want to make roll back for the selected game be sure once rollback will not be updated further</h6>
        <h6 className='text-[11px] pb-2 pt-1'>Game Name : <span className='font-[700]'>{data?.game?.name}</span> </h6>
        <div className='flex items-center border-t py-1 border-b' >
          <div className='w-[50%] border-r'>
            <h6 className='text-[10px] text-center'>Open Bid Time</h6>
            <h6 className='text-center font-[700] text-[12px]'>{formatTime(data?.openBiddingTime + ":00")}</h6>
          </div>
          <div className='w-[50%]'>
            <h6 className='text-[10px] text-center'>Close Bid Time</h6>
            <h6 className='text-center font-[700] text-[12px]'>{formatTime(data?.closeBiddingTime + ":00")}</h6>
          </div>
        </div>

        <div className='flex items-center justify-end border-t pt-2 mt-2'>
          <ButtonOutlinedAutoWidth onClick={()=>setmodal(!modal)} btnName="Cancel" style={'mr-2'}/>
          <ButtonFilledAutoWidth btnName="Save" onClick={rollbackfunc} />
        </div>
      </div>
    </Modal>

          <Modal width={300} open={modal1} footer={false} closable={false}>
          <div>
            <h6 className='text-[13px] font-[700]'>Cancel Game</h6>
            <h6 className='text-[11px] p-1 bg-slate-100'>Are you sure want to make close the game and revert back the bidding amount to them.</h6>
            <h6 className='text-[11px] pb-2 pt-1'>Game Name : <span className='font-[700]'>{data?.game?.name}</span> </h6>
            <div className='flex items-center border-t py-1 border-b' >
              <div className='w-[50%] border-r'>
                <h6 className='text-[10px] text-center'>Open Bid Time</h6>
                <h6 className='text-center font-[700] text-[12px]'>{formatTime(data?.openBiddingTime + ":00")}</h6>
              </div>
              <div className='w-[50%]'>
                <h6 className='text-[10px] text-center'>Close Bid Time</h6>
                <h6 className='text-center font-[700] text-[12px]'>{formatTime(data?.closeBiddingTime + ":00")}</h6>
              </div>
            </div>

            <div className='flex items-center justify-end border-t pt-2 mt-2'>
              <ButtonOutlinedAutoWidth onClick={()=>setmodal1(!modal1)} btnName="Cancel" style={'mr-2'}/>
              <ButtonFilledAutoWidth btnName="Save" onClick={closegame} />
            </div>
          </div>
    </Modal> 

    <Modal width={300} open={modal2} footer={false} closable={false}>
      <div>
        <h6 className='text-[13px] font-[700]'>Game Completed</h6>
        <h6 className='text-[11px] p-1 bg-slate-100'>Are you sure want to update the game completed status to be completed!.</h6>
        <h6 className='text-[11px] pb-2 pt-1'>Game Name : <span className='font-[700]'>{data?.game?.name}</span> Which was created for <h6 className='font-[700] text-[10px]'>{moment(data?.date)?.format('LL')}</h6>  </h6>
     

        <div className='flex items-center justify-end border-t pt-2 mt-2'>
          <ButtonOutlinedAutoWidth onClick={()=>setmodal2(!modal2)} btnName="Cancel" style={'mr-2'}/>
          <ButtonFilledAutoWidth btnName="Save" onClick={completegame} />
        </div>
      </div>
    </Modal>

          <h6 className='border-l  text-[11px]  px-2 py-1 font-[600] bg-slate-100 border-t border-r'>Game Name : {data?.game?.name} / <span>Date : {moment(data?.date)?.format('ll')}</span></h6>
          <div className='border-l border-t border-r top-0 z-50 flex  bg-white py-[-5px] border-slate-200 relative'>
            <h6 className='top-0 z-50  text-[11px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Open Bid Time</h6>
            <h6 className='top-0 z-50  text-[11px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Open Result</h6>
            <h6 className='top-0 z-50  text-[11px] min-w-[150px] max-w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Action</h6>
            <h6 className='top-0 z-50  text-[11px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Close Bid Time</h6>
            <h6 className='top-0 z-50  text-[11px] w-[150px] border-r px-2 py-1 font-[600] text-slate-600'>Close Result</h6>
            <h6 className='top-0 z-50  text-[11px] min-w-[150px] max-w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Action</h6>
            <h6 className='top-0 z-50  text-[11px] min-w-[210px] max-w-[210px] border-r px-2 py-1 font-[600] text-slate-600'>Result</h6>
            <h6 className='top-0 z-50  text-[11px] min-w-[100px] max-w-[100px] px-2 py-1 font-[600] text-slate-600'>Status / Action</h6>
       </div>

<div className='z-50 border flex  bg-white  border-b border-slate-200 relative'>
           
            <h6 className='z-50  text-[11px]  w-[100px]  px-2 py-1 font-[600] border-r'>{formatTime(data?.openBiddingTime + ":00")}</h6>
            <h6 className='z-50  text-[11px]  w-[150px]  px-2 py-1 font-[600] border-r'>
                    {data?.open_result !== '' ? 
                    <h6 className='flex'>
                      <p className={`w-[60px] h-[20px]  font-[400] flex items-center justify-center ${result_open?.one === '' && 'pt-1 text-gray-200'}  border`}> {!result_open?.one ? '***' : result_open?.one}</p>
                      {/* <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${result_open?.two === '' && 'pt-1 text-gray-200'}   border-l border-t border-b`}> {!result_open?.two ? '*' : result_open?.two} </p>
                      <p className={`w-[20px] h-[20px]  font-[400] flex items-center  ${result_open?.three === '' && 'pt-1 text-gray-200'} justify-center border-l border-t border-b border-r`}>{!result_open?.three ? '*' : result_open?.three} </p> */}
                      <p className='text-gray-200 mx-1'>-</p>
                      <p className={`w-[20px] h-[20px]  font-[400] flex items-center ${result_open?.four === '' && 'pt-1 text-gray-200'} justify-center border`}>{!result_open?.two ? '*' : result_open?.two} </p>
                    </h6> 
                    : 
                    <h6 className='flex'>
                      <input value={result_open?.one} name="one" onChange={handlechange} maxLength={3} className='focus:outline-none text-center border w-[60px] text-[9px] center h-[20px]' />
                      {/* <input value={result_open?.two} name="two" onChange={handlechange} maxLength={1} className='focus:outline-none text-center border-r border-t border-b w-[20px] text-[9px] center h-[20px]' />
                      <input value={result_open?.three} name="three" onChange={handlechange} maxLength={1} className='focus:outline-none text-center border-r border-t border-b w-[20px] text-[9px] center h-[20px]' /> */}
                      <p className='text-gray-200 mx-1'>-</p>
                      <input value={result_open?.four} name="two" onChange={handlechange} maxLength={1} className='focus:outline-none text-center border w-[20px] text-[9px] center h-[20px] mr-2' />
                    </h6>
                    }
          </h6>
            <h6 className='z-50  text-[11px]  min-w-[150px] max-w-[150px] flex px-2 py-1 font-[600] border-r'>
              <p  onClick={()=>submitform(1)} className={`text-[10px]  z-0 cursor-pointer mr-2  bg-slate-600 font-[500] h-fit p-[2px] px-[10px] w-fit text-white ${(data?.open_result !== '' || (new Date().getTime() < new Date(`${data?.date?.slice(0,10)}T${data?.openBiddingTime}:00`))) && 'opacity-30' } rounded`}>Save</p>
              <p onClick={()=>rollback(1)} className={`text-[10px] h-fit  z-0 cursor-pointer mr-2  bg-slate-200 font-[500] p-[2px] px-[10px] w-max text-black border ${data?.open_result === '' && 'opacity-30' }  rounded`}>Roll Back</p></h6>
            <h6 className='z-50  text-[11px]  w-[100px]  px-2 py-1 font-[600] border-r'>{formatTime(data?.closeBiddingTime + ":00")}</h6>
           
            <h6 className='z-50  text-[11px]  w-[150px] border-r px-2 py-1 font-[600]'>
              {data?.closed_result !== '' ? 
              <h6 className='flex '>
                 <p className={`w-[20px] h-[20px]  font-[400] font-[400] flex items-center justify-center ${result_close?.one === '' && 'pt-1 text-gray-200'}  border`}> {!result_close?.one ? '*' : result_close?.one} </p>
                 <p className='text-gray-200 mx-1'>-</p>
                 <p className={`w-[60px] h-[20px]  font-[400] font-[400] flex items-center justify-center ${result_close?.two === '' && 'pt-1 text-gray-200'}  border`}> {!result_close?.two ? '*' : result_close?.two} </p>
                 {/* <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${result_close?.two === '' && 'pt-1 text-gray-200'} border-l border-t border-b`}> {!result_close?.two ? '*' : result_close?.two} </p>
                 <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${result_close?.three === '' && 'pt-1 text-gray-200'}  border-l border-t border-b`}> {!result_close?.three ? '*' : result_close?.three} </p>
                 <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${result_close?.four === '' && 'pt-1 text-gray-200'}  border-l border-t border-b border-r`}> {!result_close?.four ? '*' : result_close?.four} </p> */}
              </h6> 
              : 
              <h6 className='flex'>
                <input value={result_close?.one} name="one" onChange={handlechange1} maxLength={1} className='focus:outline-none text-center border w-[20px] text-[9px] center h-[20px]' />
                <p className='text-gray-200 mx-1'>-</p>
                <input value={result_close?.two} name="two" onChange={handlechange1} maxLength={3} className='focus:outline-none text-center border-r border-l border-t border-b w-[60px] text-[9px] center h-[20px]' />
                {/* <input value={result_close?.three} name="three" onChange={handlechange1} maxLength={1} className='focus:outline-none text-center border-r border-t border-b w-[20px] text-[9px] center h-[20px]' />
                <input value={result_close?.four} name="four" onChange={handlechange1} maxLength={1} className='focus:outline-none text-center border-t border-b border-r w-[20px] text-[9px] center h-[20px] mr-2' /> */}
              </h6>
              }
            </h6>

            <h6 className='z-50  text-[11px]  min-w-[150px] max-w-[150px] flex px-2 py-1 font-[600] border-r'>
              <p onClick={()=>submitform(2)} className={`text-[10px]  z-0 cursor-pointer mr-2  bg-slate-600 font-[500] p-[2px] px-[10px] w-fit text-white h-fit ${(data?.closed_result !== '' || data?.open_result === '' || (new Date().getTime() < new Date(`${data?.date?.slice(0,10)}T${data?.closeBiddingTime}:00`))) && 'opacity-30' }  rounded`}>Save</p>
              <p onClick={()=>rollback(2)} className={`text-[10px]  z-0 cursor-pointer h-fit mr-2  bg-slate-200 font-[500] p-[2px] px-[10px] w-max text-black border ${data?.closed_result === '' && 'opacity-30' }  rounded`}>Roll Back</p></h6>
            
            <h6 className='z-50  text-[11px] min-w-[210px] border-r max-w-[170px] flex items-center px-2 py-1  font-[600]'>
              <h6 className='flex items-center mr-1'>
                <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${data?.open_result === '' && 'pt-1 text-gray-200'} border-l border-t border-b`}>{(data?.open_result !== '' && data?.open_result?.split('')[0] !== null && data?.open_result?.split('')[0] !== undefined && data?.open_result?.split('')[0] !== '') ? data?.open_result?.split('')[0] : '*' }</p>
                <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${data?.open_result === '' && 'pt-1 text-gray-200'}  border-l border-t border-b`}> {(data?.open_result !== '' && data?.open_result?.split('')[1] !== null && data?.open_result?.split('')[1] !== undefined && data?.open_result?.split('')[1] !== '')  ? data?.open_result?.split('')[1] : '*' } </p>
                <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${data?.open_result === '' && 'pt-1 text-gray-200'}  border-l border-t border-b border-r mr-2`}> {(data?.open_result !== '' && data?.open_result?.split('')[2] !== null && data?.open_result?.split('')[2] !== undefined && data?.open_result?.split('')[2] !== '')  ? data?.open_result?.split('')[2] : '*' } </p>
                <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${data?.open_result === '' && 'pt-1 text-gray-200'} border`}> {(data?.open_result !== '' && data?.open_result?.split('')[3] !== null && data?.open_result?.split('')[3] !== undefined && data?.open_result?.split('')[3] !== '')  ? data?.open_result?.split('')[3] : '*' } </p>
              </h6> 
              
              <span className='text-slate-300'> - </span>
                
              <h6 className='flex ml-1'>
              <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${data?.closed_result === '' && 'pt-1 text-gray-200'} border mr-2`}>{(data?.closed_result !== '' && data?.closed_result?.split('')[0] !== null && data?.closed_result?.split('')[0] !== undefined && data?.closed_result?.split('')[0] !== '') ? data?.closed_result?.split('')[0] : '*' }</p>
                <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${data?.closed_result === '' && 'pt-1 text-gray-200'}  border-l border-t border-b`}> {(data?.closed_result !== '' && data?.closed_result?.split('')[1] !== null && data?.closed_result?.split('')[1] !== undefined && data?.closed_result?.split('')[1] !== '')  ? data?.closed_result?.split('')[1] : '*' } </p>
                <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${data?.closed_result === '' && 'pt-1 text-gray-200'}  border-l border-t border-b `}> {(data?.closed_result !== '' && data?.closed_result?.split('')[2] !== null && data?.closed_result?.split('')[2] !== undefined && data?.closed_result?.split('')[2] !== '')  ? data?.closed_result?.split('')[2] : '*' } </p>
                <p className={`w-[20px] h-[20px]  font-[400] flex items-center justify-center ${data?.closed_result === '' && 'pt-1 text-gray-200'} border`}> {(data?.closed_result !== '' && data?.closed_result?.split('')[3] !== null && data?.closed_result?.split('')[3] !== undefined && data?.closed_result?.split('')[3] !== '')  ? data?.closed_result?.split('')[3] : '*' } </p>
              </h6>
            </h6>
            <h6 className='z-50 flex items-center  text-[11px] min-w-[100px] max-w-[100px] px-2 py-1 font-[600] text-slate-600'> <span className='text-[7px]  z-0  bg-slate-600 font-[800] p-[4px] uppercase text-white mr-2'>{data?.game_completed ? 'Completed' : 'Pending'}</span> <span className='mr-1'> <GrGamepad  onClick={()=>setmodal1(!modal1)} /></span><span className='ml-1'><MdOutlineRollerShadesClosed  onClick={()=>{setmodal2(true)}} /></span> <span> <RiTimeLine className='ml-2' onClick={()=>navigate('/games_play/edit',{state:data})} /></span></h6>

       </div>

       <div className='flex border-b border-t mt-5'>
          <h6 onClick={()=>{setstep(1)}} className={`${step == 1 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Open</h6>
          <h6 onClick={()=>{setstep(2)}} className={`${step == 2 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Close</h6>
          <h6 onClick={()=>{setstep(3)}} className={`${step == 3 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Jodi</h6>
          <h6 onClick={()=>{setstep(4)}} className={`${step == 4 && 'bg-slate-700 text-white'} cursor-pointer py-[3px] font-[400] text-center w-[90px] text-[12px]`}>Result</h6>
        </div>

     
       {step === 1 &&
       <>

      <div className='w-[18%] mr-2'>
       <h6 className='font-[700] text-[10px] font-sans mt-5'>Open Bidding On Number</h6>

       <div className='mt-2'>
        <div className='flex bg-slate-100 mt-1 border w-[100% items-center'>
          <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>Number</h6>
          <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>Amount</h6>
        </div>
        {result?.bidding_type_open?.sort((a,b)=>(b?.amount - a?.amount))?.map((f)=>(
        <div className='flex border-l border-r border-b w-[100% items-center'>
          <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>{f?._id}</h6>
          <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>{Priceconstants(f?.amount)}</h6>
        </div>))}
       </div>
       </div>

       <h6 className='font-[700] text-[12px] font-sans mt-5'>Open</h6>
       <div className='w-[100%] flex flex-row flex-wrap  mt-2'>
       {combination?.map((v)=>(
        <>
         {/* ${result?.bidding_type_open?.find((f)=>f?._id == v) !== undefined && 'bg-green-300' } */}
       {v?.value?.map((v)=>( <div className={`w-[4.3%] border`}>
           <h6 className='text-center p-1 text-[11px] font-[500] border-b'>{v}</h6>
           {/* <h6 className='text-[11px] py-1 text-center'>{result?.bidding_type_open?.find((f)=>f?._id.length == 1 ? `${f?._id}` == v : v) !== undefined ? Priceconstants(result?.bidding_type_open?.find((f)=>f?._id.length == 1 ? `00${f?._id}` == v : v)?.amount) : '-' }</h6> */}
           <h6 className={`text-[11px] py-1 text-center  ${result?.bidding_type_open?.find((f)=>f?._id == v) !== undefined && 'text-green-600 font-[800]' } `}>{result?.bidding_type_open?.find((f)=>f?._id == v)?.amount !== undefined ? Priceconstants(result?.bidding_type_open?.find((f)=>f?._id == v)?.amount): <h6 className='opacity-0'>''</h6>}</h6>

        </div>))}
        </>
       ))}
       </div> 
       </>}

       {step === 2 &&
       <>
       <div className='w-[18%]'>
       <h6 className='font-[700] text-[10px] font-sans mt-5'>Close Bidding On Number</h6>

      <div className='mt-2'>
      <div className='flex bg-slate-100 mt-1 border w-[100% items-center'>
        <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>Number</h6>
        <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>Amount</h6>
      </div>
      {result?.bidding_type_close?.sort((a,b)=>(b?.amount - a?.amount))?.map((f)=>(
      <div className='flex border-l border-r border-b w-[100% items-center'>
        <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>{f?._id}</h6>
        <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>{Priceconstants(f?.amount)}</h6>
      </div>))}
      </div>
      </div>


       <h6 className='font-[700] text-[12px] font-sans mt-10'>Close</h6>
       <div className='w-[100%] flex flex-row flex-wrap  mt-2'>
       {combination?.map((v)=>(
        <>
        {/* ${result?.bidding_type_close?.find((f)=>f?._id == v) !== undefined && 'bg-green-300' } */}
       {v?.value?.map((v)=>( <div className={`w-[4.3%] border `}>
           <h6 className='text-center p-1 text-[11px] font-[500] border-b border-slate-100'>{v}</h6>
           {/* <h6 className='text-[9px] py-1 text-center'>{result?.bidding_type_close?.find((f)=>f?._id.length == 1 ? `${f?._id}` == v : v) !== undefined ? Priceconstants(result?.bidding_type_close?.find((f)=>f?._id.length == 1 ? `${f?._id}` == v : v)?.amount) : '-' }</h6> */}
           <h6 className={`text-[11px] py-1 text-center  ${result?.bidding_type_close?.find((f)=>f?._id == v)?.amount !== undefined && 'text-green-600 font-[800]' } `}>{result?.bidding_type_close?.find((f)=>f?._id == v)?.amount !== undefined ? Priceconstants(result?.bidding_type_close?.find((f)=>f?._id == v)?.amount): <h6 className='opacity-0'>''</h6>}</h6>

        </div>))}
        </>
       ))}
       </div> 
       </>}

       {step === 3 &&
       <>
       <div className='flex'>

       <div className='w-[18%] mr-2'>
       <h6 className='font-[700] text-[10px] font-sans mt-5'>Jodi Bidding On Number</h6>
       
       <div className='mt-2 '>
        <div className='flex bg-slate-100 mt-1 border w-[100%] items-center'>
          <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>Number</h6>
          <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>Amount</h6>
        </div>
        {result?.bidding_type_jodi?.sort((a,b)=>(b?.amount - a?.amount))?.map((f)=>(
        <div className='flex border-l border-r border-b w-[100%] items-center'>
          <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>{f?._id}</h6>
          <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>{Priceconstants(f?.amount)}</h6>
        </div>))}
       </div>
       </div> 

       <div className='w-[18%] mr-2'>
       <h6 className='font-[700] text-[10px] font-sans mt-5'>Half Sangam Bidding On Number</h6>

       <div className='mt-2'>
        <div className='flex bg-slate-100 mt-1 border w-[100% items-center'>
          <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>Number</h6>
          <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>Amount</h6>
        </div>
        {result?.bidding_type_half_sangam_amount?.sort((a,b)=>(b?.amount - a?.amount))?.map((f)=>(
        <div className='flex border-l border-r border-b w-[100% items-center'>
          <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>{f?._id}</h6>
          <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>{Priceconstants(f?.amount)}</h6>
        </div>))}
       </div>
       </div>
      
       <div className='w-[18%] mr-2'>
       <h6 className='font-[700] text-[10px] font-sans mt-5'>Full Sangam Bidding On Number</h6>

        <div className='mt-2'>
        <div className='flex bg-slate-100 mt-1 border w-[100%] items-center'>
          <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>Number</h6>
          <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>Amount</h6>
        </div>
        {result?.bidding_type_full_sangam_amount?.sort((a,b)=>(b?.amount - a?.amount))?.map((f)=>(
        <div className='flex border-l border-r border-b w-[100%] items-center'>
          <h6 className='w-[35%] text-center text-[11px] font-[600] border-r p-1'>{f?._id}</h6>
          <h6 className='w-[65%] text-center text-[11px] font-[600] p-1'>{Priceconstants(f?.amount)}</h6>
        </div>))}
        </div>
       </div>
       </div>
       <h6 className='font-[700] text-[12px] font-sans mt-5'>Jodi Table</h6>
       {console.log("kp anna",result?.bidding_type_jodi)}

       <div className='grid w-[70%] border mt-2 grid-cols-10'>
       {/* ${result?.bidding_type_jodi?.find((f)=>f?._id.length == 1 ? `0${f?._id}` == v?.label : v?.label) !== undefined && 'bg-green-300' } */}
       {jodiValuesList?.map((v)=>(
        <div className={`border `}>
           <h6 className='text-center p-1 text-[11px] font-[500] border-b'>{v?.label}</h6>
           <h6 className={`text-[11px] py-1 text-center ${result?.bidding_type_jodi?.find((f)=>f?._id == v?.label) !== undefined ? 'text-green-800 font-[800]' : 'opacity-0'}`}>{result?.bidding_type_jodi?.find((f)=>f?._id == v?.label) !== undefined ? Priceconstants(result?.bidding_type_jodi?.find((f)=>f?._id == v?.label)?.amount) : '-' }</h6>
        </div>  
       ))}
       </div>
       </>}

       {step === 4 &&
       <>
       <div className=''>

           <div className='pt-2 flex'>
            <div className='border border-slate-200 mr-2'>
              <h6 className='text-[10px] font-[700] border-b border-slate-100 bg-slate-100 px-2 py-1.5'>Total Bidding Count</h6>
              <h6 className='font-[700] text-[13px] text-center px-2 py-1'>{result?.bidding_count}</h6>
            </div>

            <div className='border border-slate-200 mr-2'>
              <h6 className='text-[10px] font-[700] border-b border-slate-100 bg-slate-100 px-2 py-1.5'>Total Bidding Amount</h6>
              <h6 className='font-[700] text-[13px] text-center px-2 py-1'>{Priceconstants(result?.bidding_amount[0]?.amount !== undefined ? result?.bidding_amount[0]?.amount : 0 )}</h6>
            </div>

            <div className='border border-slate-200 mr-2'>
              <h6 className='text-[10px] font-[700] border-b border-slate-100 bg-slate-100 px-2 py-1.5'>Total Bidding Winner Count</h6>
              <h6 className='font-[700] text-[13px] text-center px-2 py-1'>{result?.bidding_winner_count}</h6>
            </div>

            <div className='border border-slate-200 mr-2'>
              <h6 className='text-[10px] font-[700] border-b border-slate-100 bg-slate-100 px-2 py-1.5'>Total Winning Amount</h6>
              <h6 className='font-[700] text-[13px] text-center px-2 py-1'>{Priceconstants(result?.bidding_winner_amount[0]?.amount !== undefined ? result?.bidding_winner_amount[0]?.amount : 0 )}</h6>
            </div>

          

           </div> 

           <div className='mt-2 w-[40%]'>
              <h6 className='text-[10px] font-[700]'>Total Winning List</h6>
              <div className='border mt-2'>
              <div className='bg-slate-100 flex p-1'>
                <h6 className='text-[11px] font-[700] border-r w-[10%]'>SL NO</h6>
                <h6 className='text-[11px] font-[700] border-r w-[20%]'>Name</h6>
                <h6 className='text-[11px] font-[700] border-r w-[25%]'>Bidding Type</h6>
                <h6 className='text-[11px] font-[700] border-r w-[20%]'>Amount</h6>
                <h6 className='text-[11px] font-[700] w-[20%]'>Winning Amount </h6>
              </div>
              {result?.bidding_winner_list?.map((d,i)=>(
              <div className='flex border-b'>
                <h6 className='text-[11px] p-1 border-r w-[10%]'>{i+1}</h6>
                <h6 className='text-[11px] p-1 border-r w-[20%]'>{d?.user?.name}</h6>
                <h6 className='text-[11px] p-1 border-r w-[25%]'>{d?.biddingType}-{d?.gameType}-{d?.number}</h6>
                <h6 className='text-[11px] p-1 border-r w-[20%]'>{Priceconstants(d?.amount)}</h6>
                <h6 className='text-[11px] p-1 w-[20%]'>{Priceconstants(d?.winning_amount)} </h6>
              </div>))}
              </div>
            </div>
       </div>
       </>}



    </div>
  )
}

export default GamePlayAllocateResult