import React,{useEffect, useState} from 'react'
import { TextInput } from '../../components/input'
import GoBack from '../../components/back/GoBack';
import { useNavigate, useLocation } from 'react-router-dom'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import { Select } from 'antd';
import { CreateBlogCategoryService, GetAllBlogCategoryService, UpdateBlogCategoryService } from '../../services/BlogCategoryServices';
import { GetAllBlogBasedCategoryService } from '../../services/BlogServices';
import { CreateBlogHomeService, UpdateBlogHomeService } from '../../services/BlogHomeServices';

function BlogHomeCE() {
   
   const [data,setdata] = useState({category:'',blogs:[],title:'',tag:'',color:'',sort:'',design:''});  
   const [loader,setloader] = useState(false);  
   const [error,seterror] = useState({category:'',blogs:[],title:'',tag:'',color:'',sort:'',design:''}); 
   const [category,setcategory] = useState([])
   const [blogs,setblogs] = useState([])

   const design = [
    {label:'Row',value:'Row'},
    {label:'Grid',value:'Grid'},
    {label:'Column',value:'Column'},
   ]


   const {state,pathname} = useLocation();
  
   const path = pathname.split('/')[pathname.split('/').length - 1]
   const navigate = useNavigate();



  // useEffect(())
  
  // console.log("state",state)
 

  useEffect(()=>{
       if(path === 'edit'){
        const blogsData = []
        getBlogsCategory(state?.category?._id)
        state?.blogs?.forEach((d)=>{
          blogsData.push({label:d?.title,value:d?._id})
        })
        let sendData = {
          category:state?.category?._id,
          blogs:blogsData,
          title:state?.title,
          tag:state?.tag,
          color:state?.color,
          sort:state?.sort,
          design:state?.design
        }

        setdata({...data,...sendData})
       }
  },[state])

  useEffect(()=>{
    getData()
  },[])

  async function getBlogsCategory(v){
    const response = await GetAllBlogBasedCategoryService(v,1)
    let arr = []
    let d = response?.data?.data?.datas
    d?.forEach((d1)=>{
      arr.push({label:d1?.title,value:d1?._id})
    })
    setblogs(arr)
  }

  async function getData(){
    const response = await GetAllBlogCategoryService(1)
    let arr = []
    let d = response?.data?.data?.datas
    d?.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setcategory(arr)
  }


  async function submitform(){
    if(!data?.category){
        seterror({...error,category:'This Field is required*'})
    }else if(data?.blogs?.length === 0){
      seterror({...error,blogs:'This Field is required*'})
    }else  if(!data?.design){
      seterror({...error,design:'This Field is required*'})
    }else  if(!data?.title){
      seterror({...error,title:'This Field is required*'})
    }else  if(!data?.tag){
      seterror({...error,tag:'This Field is required*'})
    }else  if(!data?.color){
      seterror({...error,color:'This Field is required*'})
    }else  if(!data?.sort){
      seterror({...error,sort:'This Field is required*'})
    }else{
       if(path === 'create'){
           setloader(true)
           const response  = await CreateBlogHomeService(data)
           if(response?.status === 201){
            setloader(false)
            setdata({category:'',blogs:[],title:'',tag:'',color:'',sort:'',design:''})
            seterror({category:'',blogs:[],title:'',tag:'',color:'',sort:'',design:''})
            toast.success("Blogs Home Created")
           }
       }else{
        setloader(true)
        const response  = await UpdateBlogHomeService(state._id,data)
        if(response?.status === 200){
            setloader(false)
            setdata({category:'',blogs:[],title:'',tag:'',color:'',sort:'',design:''})
            seterror({category:'',blogs:[],title:'',tag:'',color:'',sort:'',design:''})
            toast.success("Blogs Home Updated")
            navigate(-1)
           }
       }
    }

  }


  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
    }

//   console.log("error",error)

  return (
    <div className='w-[300px] max-h-[90vh] overflow-y-scroll no-scrollbar px-5 mt-5'>

        <GoBack />

          <h6 className='font-bold text-[14px]'>Add / Update Blog Home</h6>
         <h6 className='text-[10px] mt-2 bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Blog Home</b> for your game user to access this blogs.</h6>
     

              <h6 className='text-[11px] font-[600] mb-1 mt-1' >Category</h6>
              <Select value={category?.find((f)=>f?.value === data?.category)} onChange={(e)=>{setdata({...data,category:e});getBlogsCategory(e)}} className='border h-[30px] w-full border-l-4 border-l-slate-600' bordered={false} options={category} />

              <h6 className='text-[11px] font-[600] mb-1 mt-1' >Blogs</h6>
              <Select value={data?.blogs} mode='multiple' onChange={(e)=>{setdata({...data,blogs:e});}} className='border min-h-[30px] w-full border-l-4 border-l-slate-600' bordered={false} options={blogs} />


              <h6 className='text-[11px] font-[600] mb-1 mt-1' >Design</h6>
              <Select value={design?.find((f)=>f?.value === data?.design)} onChange={(e)=>setdata({...data,design:e})} className='border h-[30px] w-full border-l-4 border-l-slate-600' bordered={false} options={design} />

       

              <TextInput
                    label={'Name'}  
                    variant="standard"
                    mandatory={true}
                    name="title"
                    type="text"
                    error={error.title}
                    value={data.title}
                    handlechange={handlechange}
                    placeholder="Enter  name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />



              <TextInput
                    label={'Tag'}  
                    variant="standard"
                    mandatory={true}
                    name="tag"
                    type="text"
                    error={error.tag}
                    value={data.tag}
                    handlechange={handlechange}
                    placeholder="Enter  name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />      


              <TextInput
                    label={'Color'}  
                    variant="standard"
                    mandatory={true}
                    name="color"
                    type="color"
                    error={error.color}
                    value={data.color}
                    handlechange={handlechange}
                    placeholder="Enter  name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />   

<TextInput
                    label={'Sort'}  
                    variant="standard"
                    mandatory={true}
                    name="sort"
                    maxLength={1}
                    type="number"
                    error={error.sort}
                    value={data.sort}
                    handlechange={handlechange}
                    placeholder="Enter  name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}

                />




        {!loader ? 
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} onClick={submitform} />  
        </div>
        :
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"Save"} />  
        </div>}

    </div>
  )
}

export default BlogHomeCE